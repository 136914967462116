import React from "react";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import siteMap from "assets/img/RWSRPD/site-map.png";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function OverviewSection() {
    const classes = useStyles();
    return (
        <div className={classes.section}>
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12}>
                    <div className={classes.container}>
                        <GridItem xs={12} sm={12} md={6}>
                            <h2 className={classes.title}>Overview</h2>
                            <h3 className={classes.description}>
                                IGNP, Stage-I infrastructure viz., Indira Gandhi feeder (IGF), Indira Gandhi main canal
                                (IGMC) and its distribution network was developed and the scheme was opened for
                                irrigation
                                and other use in October 1961; since then the system has deteriorated due to ageing,
                                opening
                                up
                                of masonry joints in burnt clay brick lining, excessive seepage and other reasons. It is
                                proposed
                                to rehabilitate and re-line the system of canals under IGF, IGNP under Stage-I and its
                                entire
                                distribution network under an ambitious programme of Government of Rajasthan (GOR)
                                called
                                Rajasthan Water Sector Restructuring Project for the Desert Area (RWSRPD) at a cost of
                                Rs.
                                3291.63 crores. The project is projected for funding from New Development Bank (NDB),
                                with
                                specific loan terms and agreements, scheduled progressing and timely completion of all
                                project
                                activities as planned tranche-wise.
                            </h3>
                        </GridItem>
                            <img className={classes.image} src={siteMap}/>
                    </div>
                </GridItem>
            </GridContainer>
        </div>
    );
}
