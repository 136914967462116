import MinorController from '../controllers/minor-controller';

class MinorRepository {
    getCount(data) {
        const inspectionFormController = new MinorController();
        return inspectionFormController.getCount({
            data,
        })
    }
    getMinor(id, data) {
        let inspectionFormController = new MinorController();
        return inspectionFormController.getMinor(id, {
            data: data,
        });
    }
    getMilestoneComponent(id, data) {
        let inspectionFormController = new MinorController();
        return inspectionFormController.getMilestoneComponent(id, {
            data: data,
        });
    }
    getMinors(data) {
        let inspectionFormController = new MinorController();
        return inspectionFormController.getMinors({
            data: data,
        });
    }
    submitMinorLength(data) {
        let inspectionFormController = new MinorController();
        return inspectionFormController.postMinorLength({
            data: data,
        });
    }

    submitComponentMilestone(data) {
        let inspectionFormController = new MinorController();
        return inspectionFormController.postComponentMilestone({
            data: data,
        });
    }

    updateMinor(id, data) {
        let inspectionFormController = new MinorController();
        return inspectionFormController.updateMinor(id, {
            data: data,
        });
    }

}

export default MinorRepository;
