import CustomAPI from "../lib/custom-api";

class WorkController {
    constructor(){
        this.customApi = new CustomAPI();
    }
    getWork(workId, parameters) {
        parameters.endPoint = 'api/works/'+workId;
        return this.customApi.get(parameters);
    }
    getWorks(parameters){
        parameters.endPoint = 'api/works';
        return this.customApi.get(parameters);
    }
}

export default WorkController;
