import React, {useEffect, useState} from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-react/views/profilePage.js";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import {Link} from "react-router-dom";
import WorkRepository from "../../repository/work-repository";
const useStyles = makeStyles(styles);

const fetchProject = (id, callback) => {
    let params = {includePacks: true};
    let workRepository = new WorkRepository();
    workRepository.getWork(id, params)
        .then(response => response.data)
        .then(data => {
            callback(data)
        })
        .catch(err => console.log(err));
}

export default function PackagesPage(props) {
    const classes = useStyles();
    const projectId = props.match.params.projectId;
    const [project, setProject] = useState([]);

    useEffect(() => {
        function handleData(data) {
            setProject(data);
        }

        fetchProject(projectId, handleData)
    }, []);


    const {...rest} = props;
    return (
        <div>
            <Header
                color="transparent"
                brand="RWSRPD"
                rightLinks={<HeaderLinks/>}
                fixed
                changeColorOnScroll={{
                    height: 200,
                    color: "white"
                }}
                {...rest}
            />
            <Parallax small filter image={require("assets/img/RWSRPD/Landing_Page.png")}/>
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div>
                    <div className={classes.container}>
                        <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={12}>
                                <div className={classes.profile}>
                                    <div className={classes.name}>
                                        <h3 className={classes.title}>{project.name || "Project"}</h3>
                                    </div>
                                </div>
                            </GridItem>
                        </GridContainer>
                        {
                            project && project.packs && project.packs.length ?
                                <GridContainer justify={"center"}>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <TableContainer component={Paper}>
                                            <Table style={{fontSize: "18px"}} className={classes.table} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>S No.</TableCell>
                                                        <TableCell>Package No.</TableCell>
                                                        <TableCell>Project Name</TableCell>
                                                        <TableCell>Status</TableCell>
                                                        <TableCell>Remarks</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {project.packs.map((row, index) => (
                                                        <TableRow hover key={row.name}>
                                                            <TableCell>{(index + 1) + "."}</TableCell>
                                                            <TableCell>{(row.packageId)}</TableCell>
                                                            <TableCell component="th" scope="row">
                                                                <Link to={"/package/" + row.id} className={classes.link}>
                                                                    {row.name}
                                                                </Link>
                                                            </TableCell>
                                                            <TableCell>{(row.status)}</TableCell>
                                                            <TableCell>{(row.remarks)}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </GridItem>
                                </GridContainer> : null
                        }
                        <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
                            </GridItem>
                        </GridContainer>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}
