import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Auth0Provider } from "@auth0/auth0-react";
import config from "./auth_config.json";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

// pick a date util library
import MomentUtils from '@date-io/moment';
import './assets/global.css';
// import {createBrowserHistory} from "history";

// const onRedirectCallback = (appState) => {
//     let history = createBrowserHistory();
//     history.push(
//         appState && appState.returnTo
//             ? appState.returnTo
//             : window.location.pathname
//     );
// };

ReactDOM.render(
    <Auth0Provider
        domain={process.env.REACT_APP_AUTH_DOMAIN}
        clientId={process.env.REACT_APP_AUTH_CLIENT_ID}
        audience={process.env.REACT_APP_AUTH_AUDIENCE}
        redirectUri={process.env.REACT_APP_AUTH_REDIRECT_URI}
        scope={"read:users_app_metadata"}
    >
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <App />
        </MuiPickersUtilsProvider>
    </Auth0Provider>,
    document.getElementById("root")
);
