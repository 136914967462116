import React, {useState, useEffect} from 'react'
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import makeStyles from "@material-ui/core/styles/makeStyles";
import yellow from "@material-ui/core/colors/yellow";
import {SAMPLE_STATUS_KEY_VALUE} from "../../../../models/sample";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import MinorRepository from "../../../../repository/minor-repository";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import Package from "./Package";
import TableContainer from "@material-ui/core/TableContainer";
import _ from 'lodash';
import Grid from "@material-ui/core/Grid";
import {jsPDF} from "jspdf";
import html2canvas from "html2canvas";
import {CloudDownload} from "@material-ui/icons";
import GridContainer from "../../../../components/Grid/GridContainer";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import InspectionForm from "../Inspection/InspectionForm";
import GridItem from "../../../../components/Grid/GridItem";
import moment from "moment";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Controls from "../../../Components/controls/Controls";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import debounce from "@material-ui/core/utils/debounce";
import AddProgressLengthForm from "./AddProgressLengthForm";
import AddProgressMilestoneForm from "./AddProgressMilestoneForm";
import {hasRule, RULES_CONSTANTS} from "../../../../models/user";
import Chip from "@material-ui/core/Chip";

const styles = (theme) => ({
    paper: {
        // maxWidth: 1200,
        margin: 'auto',
        overflow: 'hidden',
    },
    searchBar: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    searchInput: {
        fontSize: theme.typography.fontSize,
    },
    block: {
        display: 'block',
    },
    addUser: {
        marginRight: theme.spacing(1),
    },
    contentWrapperPrimary: {
        margin: '20px 16px',
    },
    contentWrapper: {
        margin: '20px 16px',
        width: "100%",
        display: "flex",
    },
    title: {
        padding: '20px',
    },
    search: {
        borderRight: "1px solid lightgray",
    },
    heading: {
        fontWeight: "bold",
        fontSize: "16px"
    },
    detail: {
        fontSize: "16px"
    },
    status: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    box: {
        borderBottom: "1px solid black",
        padding: 20
    },
    
    smallFontHeading: {
        fontWeight: "bold",
        fontSize: "16px"
    },
    smallFont: {
        fontSize: "16px"
    },
    tabular: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
    }
});

const getTimeOptions = (data) => {
    return {
        chart: {
            type: 'spline'
        },
        title: {
            text: 'Month-wise comparision'
        },
        xAxis: {
            type: 'datetime',
            title: {
                text: 'Date'
            }
        },
        yAxis: {
            title: {
                text: 'Monthly Physical Progress'
            },
            min: 0
        },
        tooltip: {
            headerFormat: '<b>{series.name}</b><br>',
            pointFormat: '{point.x:%b %y}: {point.y:.2f}'
        },

        plotOptions: {
            series: {
                marker: {
                    enabled: true
                }
            }
        },

        colors: ['#6CF', '#39F', '#06C', '#036', '#000'],

        series: data,

        responsive: {
            rules: [{
                condition: {
                    maxWidth: 500
                },
                chartOptions: {
                    plotOptions: {
                        series: {
                            marker: {
                                radius: 2.5
                            }
                        }
                    }
                }
            }]
        }
    }
}

const getOptions = (xAxis = [], yAxis = []) => {
    return {
        chart: {
            type: 'bar'
        },
        title: {
            text: 'Component-wise Physical Progress'
        },
        xAxis: {
            categories: xAxis,
            title: {
                text: 'Components'
            }
        },
        yAxis: {
            min: 0,
            title: {
                text: 'Progress',
            },
        },
        tooltip: {
            headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
            pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
            footerFormat: '</table>',
            shared: true,
            useHTML: true
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            }
        },
        credits: {
            enabled: false
        },
        series: yAxis,
        // series: [{
        //     name: 'Tokyo',
        //     data: [49.9, 71.5, 106.4]
        //
        // }, {
        //     name: 'New York',
        //     data: [83.6, 78.8, 98.5]
        //
        // }, {
        //     name: 'London',
        //     data: [48.9, 38.8, 39.3,]
        //
        // }]
    }
}


const STATUSES = {
    0: {"value": "0", "id": "0", "name": "Not Tendered"},
    1: {"value": "1", "id": "1", "name": "Awarded not started"},
    2: {"value": "2", "id": "2", "name": "In Progress"},
    3: {"value": "3", "id": "3", "name": "Completed"},
}

const YEARS = Array.from({length: 10}, (v, k) => k + 2015).map((key) => {
    return {
        "value": key,
        "id": key,
        "name": key,
        "type": key,
    }
})

const MONTHS = moment.months().map((key, index) => {
    return {
        "value": index,
        "id": index,
        "name": key,
        "type": key,
    }
});

const useStyles = makeStyles((theme) => ({
    paper: {
        [theme.breakpoints.between("sm", "md")]: {
            marginTop: theme.spacing(3),
        },
        "@media (min-width: 1280px)": {
            width: 1000,
            top: `50%`,
            left: `50%`,
            transform: `translate(-50%, -50%)`,
            position: 'absolute',
        },

        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        maxHeight: "90vh",
        overflow: "scroll",
    },
    radio: {
        fontSize: "14px"
    },
}));

const fetchMinor = (id, params, callback) => {
    let minorRepository = new MinorRepository();
    minorRepository.getMilestoneComponent(id, params)
        .then(response => response.data)
        .then(data => {
            callback(data)
        })
        .catch(err => {
                callback([]);
            }
        )
}
// const BarGraph = ({xAxis, yAxis, title}) => {
//     return <HighchartsReact
//         highcharts={Highcharts}
//         options={getOptions(xAxis, yAxis, title)}
//     />
// }

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const getMilestoneForThisMonth = (component, month="this") => {
    if(component.milestone_details) {
        let start_date = new moment(component.stipulated_start_date);
        let end_date = new moment(component.stipulated_end_date);
        let today = new moment();
        if(month==="last") {
            today = today.subtract(1, 'months');
        }

        let curMilestoneIndex = component.milestone_details.findIndex((milestone) => {
            let num_days = today.diff(start_date, 'days');
            return num_days < milestone.days
        })
        if(curMilestoneIndex === -1) {
            //Time Over
            return 0;
        } else {
            let curMilestone = component.milestone_details[curMilestoneIndex];
            let lastMilestone = curMilestoneIndex > 0 ? component.milestone_details[curMilestoneIndex - 1] : {
                days: 0,
                milestone: 0,
            }

            let milestonePeriod = curMilestone.days - lastMilestone.days;
            let milestoneInPeriod = parseFloat(curMilestone.milestone) - parseFloat(lastMilestone.milestone);

            let per_month = (milestoneInPeriod/milestonePeriod) * 30;
            return per_month;
        }
    } else {
        return parseFloat(component.total_qty)
    }
}


function PackageMilestoneProgress(props) {
    const {classes, userRules, heading, ...rest} = props;
    const [modalStyle] = React.useState(getModalStyle);
    const [components, setComponents] = useState(null)
    const [pack, setPack] = useState(null)
    const [loading, setLoading] = useState(false)
    const packId = props.match.params.packId;
    const [openAlert, setOpenAlert] = useState(false);
    const [toastMessage, setToast] = useState({});
    const [processedData, setProcessedData] = useState(null);
    const [groupedData, setGroupedData] = useState(null);
    const modalClasses = useStyles();
    const contractors = pack ? pack.contractors : [{}];
    const contractor = contractors[0];
    const [month, setMonth] = useState(moment().month());
    const [year, setYear] = useState(moment().year());
    const [options, setOptions] = useState(null);
    const [timeOptions, setTimeOptions] = useState(null);
    const [addProgressModalOpen, setAddProgressModalOpen] = React.useState(false);
    const CAN_ADD_PROGRESS = pack && parseInt(pack.status)===2;
    const handleAddReportModalClose = (fetch) => {
        setAddProgressModalOpen(false);
        if (fetch && typeof fetch === "boolean") {
            fetchAllComponents();
        }
    };

    const date = new moment().month(month).year(year);
    const lastMonth = new moment(date);
    lastMonth.subtract(1, "months")


    const handleAlertClose = () => {
        setOpenAlert(false);
    }

    const handleMonth = debounce(function (value) {
        setMonth(value);
    }, 500)

    const handleYear = debounce(function (value) {
        setYear(value);
    }, 500)

    const getPDF = () => {
        const input = document.getElementById('divToPrint');
        const dimensions = input.getBoundingClientRect()
        var HTML_Width = dimensions.width;
        var HTML_Height = dimensions.height;
        var top_left_margin = 15;
        var PDF_Width = HTML_Width + (top_left_margin * 2);
        var PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
        var canvas_image_width = HTML_Width;
        var canvas_image_height = HTML_Height;

        var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;


        html2canvas(input, {allowTaint: true}).then(function (canvas) {
            canvas.getContext('2d');
            var imgData = canvas.toDataURL("image/jpeg", 1.0);
            var pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);

            pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);


            for (var i = 1; i <= totalPDFPages; i++) {
                pdf.addPage([PDF_Width, PDF_Height]);
                pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height * i) + (top_left_margin * 4), canvas_image_width, canvas_image_height);
            }

            pdf.save(`Milestone_progress${pack ? "-" + pack.packageId : ""}-${new Date().toDateString()}.pdf`);
        });
    }

    const getPercentage = (achieved, total) => {
        let floatAchieved = parseFloat(achieved) || 0;
        let floatTotal = parseFloat(total) || 1;
        try {
            return (floatAchieved * 100 / floatTotal).toFixed(2);
        } catch (e) {
            return 0;
        }

    }

    const fetchAllComponents = () => {
        let id = parseInt(packId)

        function handleComponent({components, pack}) {
            if (components && pack) {
                setComponents(components)
                setPack(pack)
                // setGroupedData(_.groupBy(canals, "branch_system"))
            }
            setLoading(false);
        }

        setLoading(true);
        fetchMinor(id, {}, handleComponent)
    }

    const renderData = (data, precision = 3) => {
        if (!data) return "-"
        else return parseFloat(data).toFixed(precision);
    }


    useEffect(() => {
        let id = parseInt(packId)
        if (!isNaN(id) && !loading && !components) {
            fetchAllComponents()
        }
    }, [])

    useEffect(() => {
        if (components) {
            let total_data = components.map((comp) => {
                // let this_month = comp.milestones && comp.milestones.length ? comp.milestones.reduce((previousValue, canal) => previousValue + ( parseFloat(canal.progress_rd)), 0) : 0
                let total_progress = comp.milestones && comp.milestones.length ? comp.milestones.reduce((previousValue, canal) => previousValue + parseFloat(canal.progress_rd), 0) : 0;

                let total_percentage = getPercentage(total_progress, comp.total_qty)
                let milestone_this_month = "-"
                let milestone_percentage = "-"

                return {
                    total_progress,
                    total_percentage,
                    milestone_this_month,
                    milestone_percentage
                }
            })

            let this_month = components.map((component) => {
                let total_progress = component.milestones.reduce((previousValue, canal) => {
                    let achieved_by = new moment(canal.achieved_by)
                    let today = new moment();
                    today.month(month)
                    today.year(year)
                    return achieved_by.isSame(today, 'month') ? previousValue + parseFloat(canal.progress_rd) : previousValue;
                }, 0)

                let total_percentage = getPercentage(total_progress, component.total_qty)
                let milestone_this_month = getMilestoneForThisMonth(component);
                let milestone_percentage = getPercentage(milestone_this_month, component.total_qty)
                return {
                    total_progress,
                    total_percentage,
                    milestone_this_month,
                    milestone_percentage,
                }

            })

            let less_than_this_month = components.map((component) => {
                let total_progress = component.milestones.reduce((previousValue, canal) => {
                    let achieved_by = new moment(canal.achieved_by)
                    let today = new moment();
                    today.month(month)
                    today.year(year)
                    return achieved_by.isBefore(today, 'month') ? previousValue + parseFloat(canal.progress_rd) : previousValue;
                }, 0)
                let total_percentage = getPercentage(total_progress, component.total_qty)
                let milestone_this_month = getMilestoneForThisMonth(component, "last");
                let milestone_percentage = getPercentage(milestone_this_month, component.total_qty)
                return {
                    total_progress,
                    total_percentage,
                    milestone_this_month,
                    milestone_percentage,
                }
            })

            // setGroupedData(_.groupBy(canals, "branch_system"))
            setProcessedData({
                total_data,
                this_month,
                less_than_this_month,
            })
        }
    }, [components, month, year])

    useEffect(() => {
        if (processedData && lastMonth && date) {

            let curMonth = date.format("MMMM YYYY");
            let lastMonthDate = lastMonth.format("MMMM YYYY")
            let xAxis = components.map(division => division.name);
            let yAxis = [{
                name: "Total Quantity as per Agreement",
                data: components.map(comp => comp.total_qty)
            }, {
                name: "Total Quantity Achieved till " + curMonth,
                data: processedData && processedData.total_data ? processedData.total_data.map(data => data.total_progress) : []
            }, {
                name: "Total Quantity Achieved till " + lastMonthDate,
                data: processedData && processedData.less_than_this_month ? processedData.less_than_this_month.map(data => data.total_progress) : []
            }, {
                name: "Quantity Achieved in the month of " + curMonth,
                data: processedData && processedData.this_month ? processedData.this_month.map(data => data.total_progress) : []
            }];
            let options = getOptions(xAxis, yAxis, {});
            setOptions(options);

            // setTimeOptions(getTimeOptions(
            //     components.map((comp) => {
            //         return {
            //             name: comp.name,
            //             data: comp.milestones.map((milestone) => {
            //                 return [ (new moment(milestone.achieved_by)).valueOf(), milestone.progress_rd]
            //             })
            //         }
            //     })
            // ))
        }
    }, [processedData])


    return (
        <Paper className={classes.paper}>
            <div className={classes.contentWrapperPrimary}>
                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingBottom: "20px",
                }}>
                    <div style={{width: "30%", alignItems: "center", display: "flex"}}>
                        <Button
                            style={{marginLeft: 20}}
                            href={"/dashboard/progress/physical/package-wise/" + packId}
                            variant={"outlined"}
                            color={"primary"}
                        >
                            Show Length Wise Data
                        </Button>
                    </div>
                    <div>
                        <Typography variant={"h5"} color="textPrimary" align="center" style={{marginRight: "20px"}}>
                            {heading}
                        </Typography>
                    </div>
                    <div style={{width: "30%", display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
                        {
                            CAN_ADD_PROGRESS && hasRule(userRules, RULES_CONSTANTS.CAN_ADD_PHYSICAL_PROGRESS) ?
                            <Button
                                style={{marginRight: 20}}
                                variant={"outlined"}
                                onClick={() => {
                                    setAddProgressModalOpen(true)
                                }}
                                color={"primary"}
                            >
                                Add Progress
                            </Button> : null
                        }
                        <Button
                            variant={"outlined"}
                            style={{marginRight: 20}}
                            onClick={getPDF}
                            color={"primary"}
                        >
                            <CloudDownload style={{marginRight: "5px"}}/> Download Report
                        </Button>
                    </div>
                </div>
                <GridContainer id={"divToPrint"}>
                    <GridContainer>
                        <GridItem xs={12}>
                            <GridContainer className={classes.box}>
                                <GridItem xs={3} className={classes.heading}>
                                    Name of Work:
                                </GridItem>
                                <GridItem xs={6} className={classes.detail}>
                                    {pack ? pack.name : "-"}
                                </GridItem>
                                <GridItem xs={3} className={classes.status}>
                                    <Chip label={pack ? STATUSES[parseInt(pack.status)].name : "-"} variant={"outlined"} color={"secondary"}/>
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                        <GridItem xs={12}>
                            <GridContainer className={classes.box}>
                                <GridItem xs={3} className={classes.heading}>
                                    Name of Contractor:
                                </GridItem>
                                <GridItem xs={3} className={classes.detail}>
                                    {contractor && contractor.name || "-"}
                                </GridItem>
                                <GridItem xs={3} className={classes.heading}>
                                    Agreement No.:
                                </GridItem>
                                <GridItem xs={3} className={classes.detail}>
                                    {contractor && contractor.agreement_no || "-"}
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                        <GridItem xs={12}>
                            <GridContainer className={classes.box}>
                                <GridItem xs={3} className={classes.heading}>
                                    Contract Amount:
                                </GridItem>
                                <GridItem xs={3} className={classes.detail}>
                                    {contractor && contractor.contract_amount || "-"} Cr.
                                </GridItem>
                                <GridItem xs={3} className={classes.heading}>
                                    Estimated Cost:
                                </GridItem>
                                <GridItem xs={3} className={classes.detail}>
                                    {contractor && contractor.estimated_amount || "-"} Cr.
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                        <GridItem xs={12}>
                            <GridContainer className={classes.box}>
                                <GridItem xs={3} className={classes.heading}>
                                    Date of Start:
                                </GridItem>
                                <GridItem xs={3} className={classes.detail}>
                                    {components && components.length ? components[0].textual_start_date : "-"}
                                </GridItem>
                                <GridItem xs={3} className={classes.heading}>
                                    Due Date of Completion:
                                </GridItem>
                                <GridItem xs={3} className={classes.detail}>
                                    {components && components.length ? components[0].textual_end_date : "-"}
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                    </GridContainer>
                    {
                        pack ?
                            <>
                                <div className={classes.contentWrapper}>
                                    <AppBar className={classes.searchBar} position="static" color="default"
                                            elevation={0}>
                                        <Toolbar>
                                            <Grid container spacing={2} alignItems="center">
                                                <Grid item xs>
                                                    <Controls.Select
                                                        includeNone={false}
                                                        name="month"
                                                        label="Month"
                                                        value={month}
                                                        style={{width: "100%"}}
                                                        onChange={(e) => {
                                                            handleMonth(e.target.value)
                                                        }}
                                                        options={MONTHS}
                                                    />
                                                </Grid>
                                                <Grid item xs>
                                                    <Controls.Select
                                                        includeNone={false}
                                                        name="year"
                                                        label="Year"
                                                        value={year}
                                                        style={{width: "100%"}}
                                                        onChange={(e) => {
                                                            handleYear(parseInt(e.target.value))
                                                        }}
                                                        options={YEARS}
                                                    />
                                                </Grid>
                                                {/*<Grid item xs>*/}
                                                {/*    <Controls.Select*/}
                                                {/*        name="status"*/}
                                                {/*        label="Status"*/}
                                                {/*        value={status}*/}
                                                {/*        style={{width: "100%"}}*/}
                                                {/*        onChange={(e) => {*/}
                                                {/*            handleStatus(e.target.value)*/}
                                                {/*        }}*/}
                                                {/*        options={Object.values(STATUSES)}*/}
                                                {/*    />*/}
                                                {/*</Grid>*/}
                                            </Grid>
                                        </Toolbar>
                                    </AppBar>
                                </div>
                                <TableContainer component={Paper}>
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={timeOptions}
                                    />
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={options}
                                    />
                                    <Table style={{fontSize: "12px"}} className={classes.table}
                                           aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Bill of Quantity</TableCell>
                                                <TableCell>Till Last Month - {lastMonth.format("MMMM YYYY")}</TableCell>
                                                <TableCell>Current Month - {date.format("MMMM YYYY")}</TableCell>
                                                <TableCell>Cumulative Progress</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                components && components.length ? components.map((component, index) => {
                                                    return <TableRow key={"row+_" + index}>
                                                        <TableCell>
                                                            <div style={{
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                paddingBottom: 10,
                                                                textAlign: "center",
                                                            }}>
                                                                <span>{component.name}</span>
                                                            </div>
                                                            <div/>
                                                            <div className={classes.tabular}>
                                                                <span className={classes.smallFontHeading}>Unit:</span>
                                                                <span
                                                                    className={classes.smallFont}>{component.unit}</span>
                                                            </div>
                                                            <div className={classes.tabular}>
                                                            <span
                                                                className={classes.smallFontHeading}>Total Quantity:</span>
                                                                <span
                                                                    className={classes.smallFont}>{component.total_qty}</span>
                                                            </div>
                                                        </TableCell>
                                                        <TableCell>
                                                            <div className={classes.tabular}>
                                                                <span className={classes.smallFontHeading}>Achieved Quantity:</span>
                                                                <span
                                                                    className={classes.smallFont}>{processedData && processedData.less_than_this_month && processedData.less_than_this_month.length ? processedData.less_than_this_month[index]['total_progress'] : "-"}</span>
                                                            </div>
                                                            <div className={classes.tabular}>
                                                                <span className={classes.smallFontHeading}>% Achieved of Total Quantity:</span>
                                                                <span
                                                                    className={classes.smallFont}>{processedData && processedData.less_than_this_month && processedData.less_than_this_month.length ? (processedData.less_than_this_month[index]['total_percentage'] + "%") : "-"}</span>
                                                            </div>
                                                            <div className={classes.tabular}>
                                                                <span className={classes.smallFontHeading}>Planned Quantity (as per milestones set for the project):</span>
                                                                <span
                                                                    className={classes.smallFont}>{processedData && processedData.less_than_this_month && processedData.less_than_this_month.length ? (processedData.less_than_this_month[index]['milestone_this_month']) : "-"}</span>
                                                            </div>
                                                            <div className={classes.tabular}>
                                                                <span className={classes.smallFontHeading}>Planned Quantity(% of Total Quantity):</span>
                                                                <span
                                                                    className={classes.smallFont}>{processedData && processedData.less_than_this_month && processedData.less_than_this_month.length ? (processedData.less_than_this_month[index]['milestone_percentage']  + "%") : "-"}</span>
                                                            </div>
                                                        </TableCell>
                                                        <TableCell>
                                                            <div className={classes.tabular}>
                                                                <span className={classes.smallFontHeading}>Achieved Quantity:</span>
                                                                <span
                                                                    className={classes.smallFont}>{processedData && processedData.this_month && processedData.this_month.length ? processedData.this_month[index]['total_progress'] : "-"}</span>
                                                            </div>
                                                            <div className={classes.tabular}>
                                                                <span className={classes.smallFontHeading}>% Achieved of Total Quantity:</span>
                                                                <span
                                                                    className={classes.smallFont}>{processedData && processedData.this_month && processedData.this_month.length ? (processedData.this_month[index]['total_percentage'] + "%") : "-"}</span>
                                                            </div>
                                                            <div className={classes.tabular}>
                                                                <span className={classes.smallFontHeading}>Planned Quantity (as per milestones set for the project):</span>
                                                                <span
                                                                    className={classes.smallFont}>{processedData && processedData.this_month && processedData.this_month.length ? (processedData.this_month[index]['milestone_this_month']) : "-"}</span>
                                                            </div>
                                                            <div className={classes.tabular}>
                                                                <span className={classes.smallFontHeading}>Planned Quantity(% of Total Quantity):</span>
                                                                <span
                                                                    className={classes.smallFont}>{processedData && processedData.this_month && processedData.this_month.length ? (processedData.this_month[index]['milestone_percentage']  + "%") : "-"}</span>
                                                            </div>
                                                        </TableCell>
                                                        <TableCell>
                                                            <div className={classes.tabular}>
                                                                <span className={classes.smallFontHeading}>Achieved Quantity:</span>
                                                                <span
                                                                    className={classes.smallFont}>{processedData && processedData.total_data && processedData.total_data.length ? processedData.total_data[index]['total_progress'] : "-"}</span>
                                                            </div>
                                                            <div className={classes.tabular}>
                                                                <span className={classes.smallFontHeading}>% Achieved of Total Quantity:</span>
                                                                <span
                                                                    className={classes.smallFont}>{processedData && processedData.total_data && processedData.total_data.length ? (processedData.total_data[index]['total_percentage'] + "%") : "-"}</span>
                                                            </div>
                                                            <div className={classes.tabular}>
                                                                <span className={classes.smallFontHeading}>Planned Quantity (as per milestones set for the project):</span>
                                                                <span
                                                                    // className={classes.smallFont}>{processedData && processedData.total_data && processedData.total_data.length ? (processedData.total_data[index]['milestone_this_month']) : "-"}</span>
                                                                    className={classes.smallFont}>{"-"}</span>
                                                            </div>
                                                            <div className={classes.tabular}>
                                                                <span className={classes.smallFontHeading}>Planned Quantity(% of Total Quantity):</span>
                                                                <span
                                                                    // className={classes.smallFont}>{processedData && processedData.total_data && processedData.total_data.length ? (processedData.total_data[index]['milestone_percentage']  + "%") : "-"}</span>
                                                                    className={classes.smallFont}>{"-"}</span>
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                }) : null``
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </>
                            : null
                    }
                    {/*<Grid container alignItems="center">*/}
                    {
                        // groupedData && Object.keys(groupedData).length ? Object.keys(groupedData).map((key) => {
                        //     return <BarGraph
                        //         xAxis={groupedData[key].map((minor) => (minor.name + " (" + STATUSES[parseInt(minor.status)].name) + ")")}
                        //         yAxis={[
                        //             {
                        //                 name: "Progress",
                        //                 data: groupedData[key].map((minor) => {
                        //                     return minor.physicals && minor.physicals.length ? parseFloat(minor.physicals[0].progress_rd) : 0
                        //                 })
                        //             },
                        //             {
                        //                 name: "Target",
                        //                 data: groupedData[key].map((minor) => parseFloat(minor.target_rd))
                        //             }
                        //         ]}
                        //         title={key}
                        //     />
                        //     {/*</Grid>*/
                        //     }
                        //     // </Grid>
                        // }) : null
                    }
                </GridContainer>
            </div>
            {/*</Grid>*/}

            <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleAlertClose}>
                <Alert onClose={handleAlertClose} severity={toastMessage.type}>
                    {toastMessage.msg}
                </Alert>
            </Snackbar>

            <Modal
                open={addProgressModalOpen}
                onClose={handleAddReportModalClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div style={modalStyle} className={modalClasses.paper}>
                    <AddProgressMilestoneForm
                        pack={pack}
                        components={components}
                        setOpenAlert={setOpenAlert}
                        setToast={setToast}
                        handleClose={handleAddReportModalClose}/>
                </div>
            </Modal>

        </Paper>
    )
}

PackageMilestoneProgress.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PackageMilestoneProgress);
