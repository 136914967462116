export const ROLES_CONSTANTS = {
    ADMIN: "ADMIN",
    EMC_ENGINEER: "EMC_ENGINEER",
    THIRD_PARTY_ENGINEER: "THIRD_PARTY_ENGINEER",
    DIVISION_ENGINEER: "DIVISION_ENGINEER",
}

export const INSPECTION_TYPE = {
    EMC: "EMC",
    TPQC: "TPQC",
}

export const RULES_CONSTANTS = {
    CAN_CREATE_INSPECTION_REPORTS_EMC : "can_create_inspection_reports_emc",
    CAN_CREATE_INSPECTION_REPORTS_TPQC : "can_create_inspection_reports_tpqc",
    CAN_READ_INSPECTION_REPORTS : "can_read_inspection_reports",
    CAN_CREATE_COMPLIANCE_REPORTS : "can_create_compliance_reports",
    CAN_ACCESS_LIMITED_DIVISIONS: "can_access_limited_divisions",
    CAN_ACCESS_TESTING_MODULE: "can_access_testing_module",
    CAN_COLLECT_TESTING_SAMPLES: "can_collect_testing_samples",
    CAN_ACCEPT_TESTING_SAMPLES: "can_accept_testing_samples",
    CAN_SUBMIT_TEST_REPORTS: "can_submit_test_reports",
    CAN_ACCEPT_TEST_REPORTS: "can_accept_test_reports",
    CAN_ACCESS_COMPLAINT_DASHBOARD: "can_access_complaint_dashboard",
    CAN_READ_PHYSICAL_PROGRESS: "can_read_physical_progress",
    CAN_READ_FINANCE_PROGRESS: "can_read_physical_progress",
    CAN_ACCESS_LIMITED_PHYSICAL_PROGRESS: "can_access_limited_physical_progress",
    CAN_ADD_PHYSICAL_PROGRESS: "can_add_physical_progress",
    CAN_ACTION_MISCELLANEOUS_REPORTS : "can_action_miscellaneous_reports"
}

const DIVISION_CONSTANTS = {
    RAWATSAR: {name: "Rawatsar", code: "EE/WR/DIV/RWSR", constant: "RAWATSAR", id: 1},
    CHHATARGARH: {name: "Chhatargarh", code: "EE/WR/DIV/RWSR", constant: "CHHATARGARH", id: 2},
    KHAJUWALA: {name: "Khajuwala", code: "EE/WR/DIV/RWSR", constant: "KHAJUWALA", id: 3},
    GHARSANA: {name: "Gharsana", code: "EE/WR/DIV/RWSR", constant: "GHARSANA", id: 4},
    APS_1_VIJAYNAGAR: {name: "APS-I Sri Vijaynagar", code: "EE/WR/DIV/RWSR", constant: "APS_1_VIJAYNAGAR", id: 5},
    SOG_1_SURATGARH: {name: "SOG-I Suratgarh", code: "EE/WR/DIV/RWSR", constant: "SOG_1_SURATGARH", id: 6},
    SOG_2_VIJAYNAGAR: {name: "SOG-II Sri Vijaynagar", code: "EE/WR/DIV/RWSR", constant: "SOG_2_VIJAYNAGAR", id: 7},
   
}

export class User {
    constructor(user) {
        this.user = user;
    }

}

export const hasRule = (userRules, rule) => {


    return userRules && userRules.length && (userRules.indexOf(rule) !== -1)
}

export const isTypeCompatible = (type, userRules) => {
   

    switch (type) {
        case INSPECTION_TYPE.EMC:
            return hasRule(userRules, RULES_CONSTANTS.CAN_CREATE_INSPECTION_REPORTS_EMC)
        case INSPECTION_TYPE.TPQC:
            return hasRule(userRules, RULES_CONSTANTS.CAN_CREATE_INSPECTION_REPORTS_TPQC)
        default:
            return false;
    }
}
