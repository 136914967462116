import React, {useState, useEffect} from 'react'
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import makeStyles from "@material-ui/core/styles/makeStyles";
import yellow from "@material-ui/core/colors/yellow";
import {SAMPLE_STATUS_KEY_VALUE} from "../../../../models/sample";
import Button from "../../../../components/CustomButtons/Button";

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    color_green: {
        color: "green",
    },
    color_red: {
        color: "red",
    },
    color_yellow: {
        color: yellow["700"],
    },
    fontSmall: {
        fontSize: "16px"
    }
});


export default function Package(props) {
    const {
        pack,
        divisions,
        setSelectedVisit,
        STATUSES,
        redirectTo,
    } = props;
    const classes = useRowStyles();
    const {contractors = [{}]} = pack;
    const contractor = contractors[0];

    return (
        <React.Fragment key={pack.id + "_visit"}>
            <TableRow className={classes.root} hover>
                <TableCell className={classes.fontSmall} width={"30%"}>{pack.name}</TableCell>
                <TableCell className={classes.fontSmall} width={"20%"}>{contractor && contractor.name || "-"}</TableCell>
                {/*<TableCell width={"10%"}>{visit.created_by}</TableCell>*/}
                <TableCell className={classes.fontSmall} width={"15%"}>
                    <strong>{pack.packageId}</strong>
                </TableCell>
                <TableCell className={classes.fontSmall} width={"25%"}>
                    <strong>{contractor && contractor.agreement_no || "-"}</strong>
                </TableCell>
                {/*<TableCell width={"10%"}>{"Tranche " + canal.tranche}</TableCell>*/}
                <TableCell width={"10%"}>
                    <Button
                        size={"sm"}
                        color={"success"}
                        href={redirectTo + pack.id}
                    >
                        Show Details
                    </Button>
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}
