import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";

import cm_pic from "assets/img/RWSRPD/people_images/cm-raj.png";
import gov_pic from "assets/img/RWSRPD/people_images/governor-raj.png";
import wr_raj from "assets/img/RWSRPD/people_images/wr-raj.png";
import wrd_raj from "assets/img/RWSRPD/people_images/wrd-raj.png";
import acs_raj from "assets/img/RWSRPD/people_images/acs-raj.png";

const useStyles = makeStyles(styles);

export default function TeamSection() {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  return (
    <div className={classes.section}>
      <h2 className={classes.title}>  Under the guidance of</h2>
      <div>
        <GridContainer>
         {/*  <GridItem xs={12} sm={12} md={6}>
            <Card plain className={classes.marginLeft}>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={cm_pic} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                Sh. Ashok Gehlot
                <br />
                <small className={classes.smallTitle}>Hon'ble Chief Minister, Rajasthan</small>
              </h4>
            </Card>
          </GridItem> */}
          <GridItem xs={12} sm={12} md={6}>
            <Card plain className={classes.marginLeft}>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={gov_pic} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                Sh. Kalraj Mishra
                <br />
                <small className={classes.smallTitle}>Hon'ble Governor, Rajasthan</small>
              </h4>
            </Card>
          </GridItem>
          
          {/* <GridItem xs={12} sm={12} md={6}>
            <Card plain className={classes.marginLeft}>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={wrd_raj} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
               Sh. Mahendrajeet Singh Malaviya
                <br />
                <small className={classes.smallTitle}>Hon’ble Minister Water Resources, Rajasthan</small>
              </h4>
            </Card>
          </GridItem> */}

          <GridItem xs={12} sm={12} md={6}>
            <Card plain className={classes.marginLeft}>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={acs_raj} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
              Dr. Abhay Kumar,IAS
                <br />
           {/*      <small className={classes.smallTitle}>Hon’ble State Minister Water Resources, Rajasthan</small> */}
                <small className={classes.smallTitle}>Hon’ble Additional Chief Secretary, Government of Rajasthan</small>
              </h4>
            </Card>
          </GridItem>
        
          
        </GridContainer>
       
      </div>
  {/*     <GridContainer style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
  <GridItem xs={12} sm={12} md={6}>
    <Card plain className={classes.marginLeft}>
      <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
        <img src={acs_raj} alt="..." className={imageClasses} />
      </GridItem>
      <h4 className={classes.cardTitle}>
        Dr. Subodh Agarwal, IAS
        <br />
        <small className={classes.smallTitle}>Hon’ble Additional Chief Secretary, Government of Rajasthan</small>
      </h4>
    </Card>
  </GridItem>
</GridContainer> */}

    </div>
  );
}
