import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";

const styles = (theme) => ({
    paper: {
        margin: 'auto',
        overflow: 'hidden',
    },
    searchBar: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    searchInput: {
        fontSize: theme.typography.fontSize,
    },
    block: {
        display: 'block',
    },
    addUser: {
        marginRight: theme.spacing(1),
    },
    contentWrapper: {
        margin: '20px 16px',
    },
    title: {
        padding: '20px',
    }
});

function Content(props) {
    const { classes, pack } = props;

    return (
        <Paper className={classes.paper}>
            <div className={classes.contentWrapper}>
                <Typography variant={"h4"} color="textPrimary" align="center" className={classes.title}>
                    {pack.name}
                </Typography>
                <Typography variant={"h5"} color="textPrimary" align="center">
                    Project Overview
                </Typography>
                <GridContainer justify={"center"}>
                    <GridItem xs={12} sm={12} md={12}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="simple table">
                                <TableBody>
                                    <TableRow>
                                        <TableCell variant={"head"}>Package Number</TableCell>
                                        <TableCell>{pack.packageId}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell variant={"head"}>Status</TableCell>
                                        <TableCell>{pack.remarks}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell variant={"head"}>Estimate (in Cr.)</TableCell>
                                        <TableCell>{pack.estimate}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell variant={"head"}>Total Amount (in Cr.)</TableCell>
                                        <TableCell>{pack.total_amount}</TableCell>
                                    </TableRow>

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </GridItem>
                </GridContainer>
            </div>
        </Paper>
    );
}

Content.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Content);