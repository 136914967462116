import {CSVDownload} from "react-csv";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import Controls from "../../../Components/controls/Controls";
import {KeyboardDatePicker} from "@material-ui/pickers";
import {hasRule, isTypeCompatible, RULES_CONSTANTS} from "../../../../models/user";
import Button from "../../../../components/CustomButtons/Button";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import RefreshIcon from "@material-ui/icons/Refresh";
import InfoOutlinedIcon from '@material-ui/icons/Info';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import GridContainer from "../../../../components/Grid/GridContainer";
import GridItem from "../../../../components/Grid/GridItem";
import Tabs from "@material-ui/core/Tabs";
import {COMPLIANCE_STATUS, COMPLIANCE_STATUS_KEY_VALUE} from "../../../../models/complianceReport";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import InspectionReport from "./InspectionReport";
import TablePagination from "@material-ui/core/TablePagination";
import Modal from "@material-ui/core/Modal";
import InspectionForm from "./InspectionForm";
import RejectModal from "../Modals/RejectModal";
import ComplianceForm from "../Division/ComplianceForm";
import ComplianceHistory from "../Division/ComplianceHistory";
import Carousel, {Modal as ImageModal, ModalGateway} from "react-images";
import React, {useEffect, useState} from "react";
import debounce from "@material-ui/core/utils/debounce";
import WorkRepository from "../../../../repository/work-repository";
import DivisionRepository from "../../../../repository/division-repository";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import InspectionReportRepository from "../../../../repository/inspection-report-repository";

const styles = (theme) => ({
    paper: {
        // maxWidth: 1200,
        margin: 'auto',
        overflow: 'hidden',
    },
    legend: {
        padding: "10px",
        margin: "10px",
    },
    flex: {
        display: "flex"
    },
    searchBar: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    searchInput: {
        fontSize: theme.typography.fontSize,
    },
    block: {
        display: 'block',
    },
    info: {
        display: 'block',
        color: "white"
    },
    addUser: {
        marginRight: theme.spacing(1),
    },
    contentWrapper: {
        margin: '20px 16px',
    },
    title: {
        padding: '20px',
    },
    search: {
        borderRight: "1px solid lightgray",
    }
});

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 1000,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

function a11yProps(index) {
    return {
        id: `wrapped-tab-${index}`,
        'aria-controls': `wrapped-tabpanel-${index}`,
    };
}

const fetchInspectionReports = (params, callback) => {
    let inspectionReportRepository = new InspectionReportRepository();
    inspectionReportRepository.getInspectionReports(params)
        .then(response => response.data)
        .then(data => {

            callback(data)
        })
        .catch(err => {
                console.log(err);
                callback([]);
            }
        )
}


const fetchProjects = (callback) => {
    let workRepository = new WorkRepository();
    workRepository.getWorks()
        .then(response => response.data)
        .then(data => {
            console.warn("dtatata",data)
            callback(data)
        })
        .catch(err => console.log(err));
}

const fetchDivisions = (callback) => {
    let divisionRepository = new DivisionRepository();
    divisionRepository.getDivisions()
        .then(response => response.data)
        .then(data => {
            callback(data)
        })
        .catch(err => console.log(err));
}

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`wrapped-tabpanel-${index}`}
            aria-labelledby={`wrapped-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};


function InspectionReports(props) {
    const modalClasses = useStyles();
    const {classes, inspectionType, userRules, setToast, setOpenAlert, reportType} = props;
    const [modalStyle] = React.useState(getModalStyle);
    const [addReportModalOpen, setAddReportModalOpen] = React.useState(false);
    const [addCompReportModalOpen, setAddCompReportModalOpen] = React.useState(null);
    const [historyModal, setHistoryModalOpen] = React.useState(null);
    const [infoModal, setInfoModalOpen] = React.useState(false);
    const [showImages, setShowImages] = React.useState(null);
    const [downloadData, setDownloadData] = React.useState(null);
    const [rejectReportModal, setRejectReportModal] = React.useState(null);


    const handleRejectReportModalOpen = (compliance) => {
        setRejectReportModal(compliance);
    };

    const handleRejectReportModalClose = (fetch) => {
        setRejectReportModal(null);
        if (fetch && typeof fetch === "boolean") {
            fetchReports();
        }
    };

    const handleAddReportModalOpen = () => {
        setAddReportModalOpen(true);
    };

    const handleAddReportModalClose = (fetch) => {
        setAddReportModalOpen(false);
        if (fetch && typeof fetch === "boolean") {
            fetchReports();
        }
    };

    const handleAddCompReportModalOpen = (compliance) => {
        setAddCompReportModalOpen(compliance);
    };

    const handleAddCompReportModalClose = (fetch) => {
        setAddCompReportModalOpen(null);
        if (fetch && typeof fetch === "boolean") {
            fetchReports();
        }
    };

    const handleHistoryModalOpen = (compliance) => {
        setHistoryModalOpen(compliance);
    };

    const handleHistoryModalClose = (fetch) => {
        setHistoryModalOpen(null);
    };

    const handleInfoModalOpen = () => {
        setInfoModalOpen(true);
    };

    const handleInfoModalClose = (fetch) => {
        setInfoModalOpen(null);
    };

    const handleShowImagesModalOpen = (images) => {
        setShowImages(images);
    };

    const handleShowImagesModalClose = () => {
        setShowImages(null);
    };

    const [works, setWorks] = useState([]);
    const [divisions, setDivisions] = useState([]);
    const [inspectionReports, setInspectionReports] = useState([]);
    const [status, setStatus] = useState(COMPLIANCE_STATUS[0].value);
    const [packageNumber, setPackageNumber] = useState(null);
    const [reportId, setReportId] = useState(null);
    const [inspectionDate, setInspectionDate] = useState(null);
    const [division, setDivision] = useState(null);
    const [priority, setPriority] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(3);
    const [loading, setLoading] = useState(false);

    const getInspectionReportParams = (download = false) => {
        let params = {
            status: status,
            limit: rowsPerPage,
            offset: (page * rowsPerPage),
            type: inspectionType,
        }
        if (packageNumber) {
            params['packageNumber'] = packageNumber
        }

        if (download) {
            params['download'] = download
        }

        if (priority) {
            params['color'] = priority
        }

        if (reportId) {
            params['reportId'] = reportId
        }
        if (division) {
            params['division'] = division
        }
        if (inspectionDate && inspectionDate.isValid()) {
            params['inspectionDate'] = inspectionDate.format("DD/MM/YYYY")
        }
        return params;
    }

    const fetchReports = () => {
        function handleInspectionReports(data) {
            setInspectionReports(data);
            setLoading(false);
        }

        setLoading(true);
        fetchInspectionReports(getInspectionReportParams(false), handleInspectionReports)
    }

    useEffect(() => {
        function handleWorks(data) {
            setWorks(data);
        }

        function handleDivisions(data) {
            setDivisions(data);
        }

        fetchProjects(handleWorks)
        fetchDivisions(handleDivisions)
    }, []);

    useEffect(() => {
        if (downloadData && downloadData.length) {
            try {
                setTimeout( () => {
                    setDownloadData(null)
                }, 1000)
            } catch (e) {
                console.log(e)
            }
        }
    }, [downloadData]);

    useEffect(fetchReports, [status, packageNumber, reportId, inspectionDate, priority, division, page, rowsPerPage])

    const handleTabChange = (event, newValue) => {
        setStatus(newValue);
        setPage(0);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearchInputPackageNumber = debounce(function (value) {
        setPackageNumber(value);
        setPage(0);
    }, 500)

    const handleSearchInputReportId = debounce(function (value) {
        setReportId(value);
        setPage(0);
    }, 500)

    const handleSearchInspectionDate = function (value) {
        setInspectionDate(value);
        setPage(0);
    }

    const handleSearchPriority = function (value) {
        setPriority(value);
        setPage(0);
    }

    const handleSearchDivision = function (value) {
        setDivision(value);
        setPage(0);
    }

    return <>
        <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
            <Toolbar>
                <Grid container spacing={2} alignItems="center">
                    <Grid item>
                        <SearchIcon className={classes.block} color="inherit"/>
                    </Grid>
                    <Grid item xs className={classes.search}>
                        <TextField
                            fullWidth
                            placeholder="Package Number"
                            type={"number"}
                            onChange={(e) => {
                                const {value} = e.target;
                                handleSearchInputPackageNumber(value);
                            }}
                            InputProps={{
                                disableUnderline: true,
                                className: classes.searchInput,
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <SearchIcon className={classes.block} color="inherit"/>
                    </Grid>
                    <Grid item xs>
                        <TextField
                            fullWidth
                            placeholder="Inspection Report Id"
                            type={"number"}
                            onChange={(e) => {
                                const {value} = e.target;
                                handleSearchInputReportId(value);
                            }}
                            InputProps={{
                                disableUnderline: true,
                                className: classes.searchInput,
                            }}
                        />
                    </Grid>

                    <Grid item xs>
                        <Controls.Select
                            name="divisionIds"
                            label="Division"
                            value={division}
                            style={{width: "100%"}}
                            onChange={(e) => {
                                handleSearchDivision(e.target.value)
                            }}
                            options={divisions}
                        />
                    </Grid>
                    <Grid item xs>
                        <KeyboardDatePicker
                            variant="inline"
                            inputVariant="outlined"
                            label="Inspection Date"
                            format="MM/DD/yyyy"
                            value={inspectionDate}
                            InputAdornmentProps={{position: "start"}}
                            onChange={date =>
                                handleSearchInspectionDate(date)
                            }
                        />
                    </Grid>
                    <Grid item xs>
                        <Controls.Select
                            name="color"
                            label="Priority"
                            value={priority}
                            style={{width: "100%"}}
                            onChange={(e) => {
                                handleSearchPriority(e.target.value)
                            }}
                            options={
                                [
                                    {"value": "green", "id": "green", "name": "Green"},
                                    {"value": "yellow", "id": "yellow", "name": "Yellow"},
                                    {"value": "red", "id": "red", "name": "Red"},
                                ]}
                        />
                    </Grid>
                    <Grid item>
                        {
                            isTypeCompatible(inspectionType, userRules) ?
                                <Button variant="contained" color="twitter" className={classes.addUser}
                                        onClick={handleAddReportModalOpen}>
                                    Add Inspection Report
                                </Button> : null
                        }
                        <Tooltip title="Reload">
                            <IconButton>
                                <RefreshIcon className={classes.block} color="inherit" onClick={fetchReports}/>
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
        <AppBar position="static">
            <GridContainer>
                <GridItem xs={10} sm={10} md={10}>
                    <Tabs value={status} onChange={handleTabChange} aria-label="simple tabs example">
                        {
                            COMPLIANCE_STATUS.map(st => (
                                <Tab
                                    value={st.value}
                                    label={st.label}
                                    key={st.label}
                                    {...a11yProps(st.value)}
                                />
                            ))
                        }
                    </Tabs>
                </GridItem>
                <GridItem xs={2} sm={2} md={2} className={classes.flex}>
                    {
                        inspectionReports.rows && inspectionReports.rows.length ?
                            <Tooltip title="Download">
                                <IconButton>
                                    <CloudDownloadIcon
                                        className={classes.info}
                                        color="inherit"
                                        onClick={(event) => {
                                            fetchInspectionReports(getInspectionReportParams(true), (data) => {
                                                setDownloadData(data.rows)
                                            })
                                        }}
                                    />
                                    {
                                        downloadData && downloadData.length &&
                                        <CSVDownload
                                            data={downloadData.map((data) => {
                                                let dvnId = data["complianceReports.divisionId"]
                                                let sts = data["complianceReports.status"]
                                                let dvn = divisions.find((division) => {
                                                    return division.id === dvnId;
                                                })
                                                return {
                                                    ...data,
                                                    statusLabel: COMPLIANCE_STATUS_KEY_VALUE[sts] || "-",
                                                    division:  dvn && dvn["name"],
                                                }
                                            })}
                                            headers={[
                                                {label: "Report Id", key: "id"},
                                                {label: "Package Number", key: "pack.packageId"},
                                                {label: "Package Name", key: "pack.name"},
                                                {label: "Inspection Date", key: "inspected_on"},
                                                {label: "Site", key: "site"},
                                                {label: "Created By", key: "created_by"},
                                                {label: "Compliance Report Id", key: "complianceReports.id"},
                                                {label: "Compliance Report Status", key: "statusLabel"},
                                                {label: "Associated Division", key: "division"},
                                            ]}
                                            target={"_blank"}
                                        >
                                        </CSVDownload>
                                    }
                                </IconButton>
                            </Tooltip> : null
                    }
                    <Tooltip title="Info">
                        <IconButton>
                            <InfoOutlinedIcon className={classes.info} color="inherit" onClick={handleInfoModalOpen}/>
                        </IconButton>
                    </Tooltip>
                </GridItem>
            </GridContainer>
        </AppBar>
        <GridContainer justify={"center"}>
            <GridItem xs={12} sm={12} md={12}>
                {
                    loading ?
                        <Box
                            display="flex"
                            width={"100%"} height={100}
                            alignItems="center"
                            justifyContent="center"
                        >
                            <CircularProgress/>
                        </Box> :
                        inspectionReports.rows && inspectionReports.rows.length ?
                            <>
                                <TableContainer component={Paper}>
                                    <Table style={{fontSize: "18px"}} className={classes.table}
                                           aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell width={"5%"}/>
                                                <TableCell width={"5%"}>Report Id</TableCell>
                                                <TableCell width={"5%"}>Package No.</TableCell>
                                                <TableCell width={"25%"}>Site</TableCell>
                                                <TableCell width={"10%"}>Inspected On</TableCell>
                                                <TableCell width={"10%"}>Inspected By</TableCell>
                                                <TableCell width={"25%"}>Remarks</TableCell>
                                                <TableCell width={"15%"}>Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                inspectionReports.rows.map(
                                                    (report, index) => {
                                                        return <InspectionReport
                                                            inspectionType={inspectionType}
                                                            userRules={userRules}
                                                            setOpenAlert={setOpenAlert}
                                                            setToast={setToast}
                                                            report={report}
                                                            divisions={divisions}
                                                            handleShowImagesModalOpen={handleShowImagesModalOpen}
                                                            handleAddCompReportModalOpen={handleAddCompReportModalOpen}
                                                            handleHistoryModalOpen={handleHistoryModalOpen}
                                                            handleRejectReportModalOpen={handleRejectReportModalOpen}
                                                            fetchReports={fetchReports}
                                                            key={"report_" + index}
                                                            index={index}/>
                                                    })
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[3, 5, 10, 25]}
                                    component="div"
                                    count={inspectionReports.count || 0}
                                    rowsPerPage={rowsPerPage}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                    page={page}
                                />
                            </> :
                            <Box
                                display="flex"
                                width={"100%"} height={100}
                                alignItems="center"
                                justifyContent="center"
                            >
                                No Reports
                            </Box>
                }
            </GridItem>
        </GridContainer>
        {
            isTypeCompatible(inspectionType, userRules) ?
                <>
                    <Modal
                        open={addReportModalOpen}
                        onClose={handleAddReportModalClose}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                    >
                        <div style={modalStyle} className={modalClasses.paper}>
                            <InspectionForm
                                works={works}
                                divisions={divisions}
                                inspectionType={inspectionType}
                                setOpenAlert={setOpenAlert}
                                setToast={setToast}
                                handleClose={handleAddReportModalClose}/>
                        </div>
                    </Modal>
                    <RejectModal
                        open={!!rejectReportModal}
                        setOpenAlert={setOpenAlert}
                        setToast={setToast}
                        onClose={handleRejectReportModalClose}
                        compliance={rejectReportModal && rejectReportModal.compliance}
                        type={rejectReportModal && rejectReportModal.type}
                    />
                </> : null
        }
        {
            hasRule(userRules, RULES_CONSTANTS.CAN_CREATE_COMPLIANCE_REPORTS) ?
                <Modal
                    open={!!addCompReportModalOpen}
                    onClose={handleAddCompReportModalClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <div style={modalStyle} className={modalClasses.paper}>
                        <ComplianceForm
                            compliance={addCompReportModalOpen}
                            works={works}
                            divisions={divisions}
                            setOpenAlert={setOpenAlert}
                            setToast={setToast}
                            handleClose={handleAddCompReportModalClose}/>
                    </div>
                </Modal> : null
        }
        <Modal
            open={!!historyModal}
            onClose={handleHistoryModalClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div style={modalStyle} className={modalClasses.paper}>
                <ComplianceHistory
                    compliance={historyModal}
                    handleClose={handleHistoryModalClose}/>
            </div>
        </Modal>
        <Modal
            open={!!infoModal}
            onClose={handleInfoModalClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div style={modalStyle} className={modalClasses.paper}>
                <GridItem xs={12} sm={12} md={12}>
                    <h2>Information Key</h2>
                </GridItem>
                <Paper className={classes.legend}>
                    <ul style={{fontSize: "18px"}}>
                        {
                            COMPLIANCE_STATUS.map(
                                status =>
                                    <li key={status.label}>
                                        <strong>{status.label}:</strong> {status.description}
                                    </li>
                            )
                        }
                    </ul>
                </Paper>
            </div>
        </Modal>
        <ModalGateway>
            {!!(showImages && showImages.length) ? (
                <ImageModal onClose={handleShowImagesModalClose}>
                    <Carousel views={showImages.filter((img) => !!img).map((img) => {
                        return {source: img}
                    })}/>
                </ImageModal>
            ) : null}
        </ModalGateway>
    </>
}

export default withStyles(styles)(InspectionReports)
