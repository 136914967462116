import React, {useState, useEffect} from 'react'
import {Grid,} from '@material-ui/core';
import Controls from "../../../Components/controls/Controls";
import {useForm, Form} from '../../../Components/useForm';
import {DropzoneDialog} from "material-ui-dropzone";
import ComplianceReportRepository from "../../../../repository/compliance-report-repository";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CustomLinearProgress from "../../../../components/CustomLinearProgress/CustomLinearProgress";
// import * as employeeService from "../../../services/employeeService";

const useRowStyles = makeStyles({
    root: {
        width: "100%"
    },
});

const initialFValues = {
    complianceFile: [],
    remarks: "",
}

export default function ComplianceForm(props) {

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        handleMultipleFilesUpload,
        resetForm
    } = useForm(initialFValues, false, null);

    const classes = useRowStyles();
    const {compliance, handleClose, setOpenAlert, setToast} = props
    const [loading, setLoading] = useState(false);

    const handleSubmit = e => {
        e.preventDefault();
        setLoading(true)
        // employeeService.insertEmployee(values
        // )
        const formData = new FormData();
        Object.keys(values).forEach(key => {
            if (Array.isArray(values[key])) {
                let array = values[key];
                array.forEach(obj => {
                    formData.append(key, obj);
                })
            } else {
                formData.append(key, values[key])
            }
        });

        formData.append('complianceId', compliance.id);
        formData.append('inspectionReportId', compliance.inspectionReportId);
        formData.append('reqType', 0);
        let complianceReportRepository = new ComplianceReportRepository();
        complianceReportRepository.submitComplianceReport(compliance.id, formData).then(data => {
            setLoading(false)
            setOpenAlert(true);
            setToast({type: "success", msg : "Compliance Report Submitted"});
            handleClose(true);
        }).catch(e => {
            setLoading(false)
            handleClose(true);
            setOpenAlert(true);
            setToast({type: "error", msg : e.message});
        })
    }

    return (
        <Form onSubmit={handleSubmit}>
            <h2 id="modal-title">Submit Compliance Report</h2>
            {
                loading && <CustomLinearProgress/>
            }
            <Grid container alignItems={"center"} classes={classes.root}>
                <Controls.Input
                    label="Remarks"
                    name="remarks"
                    value={values.remarks}
                    onChange={handleInputChange}
                    error={errors.remarks}
                    disabled={loading}
                />
                <Grid container alignItems={"center"}>
                    <Controls.UploadFile
                        name="complianceFile"
                        label="Add Compliance Report"
                        title={"Upload Compliance Report, (1 file, PDF, Max Size: 8mB)"}
                        value={values.complianceFile}
                        onChange={handleMultipleFilesUpload}
                        dialogProps={{
                            acceptedFiles: ['.pdf'],
                            showPreviews: true,
                            maxFileSize: (8 * 10124* 1024),
                            filesLimit: 1,
                        }}
                        disabled={loading}
                    />
                </Grid>
                <div>
                    <Controls.Button
                        disabled={!(values.complianceFile.length || values.remarks) || loading}
                        type="submit"
                        text="Submit"/>
                </div>
            </Grid>
        </Form>
    )
}
