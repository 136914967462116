import React, {useState, useEffect} from 'react'
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import makeStyles from "@material-ui/core/styles/makeStyles";
import yellow from "@material-ui/core/colors/yellow";
import {SAMPLE_STATUS_KEY_VALUE} from "../../../../models/sample";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import MinorRepository from "../../../../repository/minor-repository";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import bullet from "highcharts/modules/bullet.js";
bullet(Highcharts);

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    color_green: {
        color: "green",
    },
    color_red: {
        color: "red",
    },
    color_yellow: {
        color: yellow["700"],
    },
    fontSmall: {
        fontSize: "14px"
    }
});

const styles = (theme) => ({
    paper: {
        // maxWidth: 1200,
        margin: 'auto',
        overflow: 'hidden',
    },
    searchBar: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    searchInput: {
        fontSize: theme.typography.fontSize,
    },
    block: {
        display: 'block',
    },
    addUser: {
        marginRight: theme.spacing(1),
    },
    contentWrapper: {
        margin: '20px 16px',
    },
    title: {
        padding: '20px',
    },
    search: {
        borderRight: "1px solid lightgray",
    }
});

const options = {
    chart: {
        inverted: true,
        marginLeft: 135,
        type: 'bullet',
        marginTop: 40
    },
    title: {
        text: '2017 YTD'
    },
    legend: {
        enabled: false
    },
    xAxis: {
        categories: ['<span class="hc-cat-title">Progress</span><br/>1.000']
    },
    yAxis: {
        plotBands: [{
            from: 0,
            to: 150,
            color: '#666'
        }],
        title: null,
        gridLineWidth: 0
    },
    plotOptions: {
        series: {
            pointPadding: 0.25,
            borderWidth: 0,
            color: '#000',
            targetOptions: {
                width: '200%'
            }
        }
    },
    credits: {
        enabled: false
    },
    exporting: {
        enabled: false
    },
    series: [{
        data: [{
            y: .300,
            target: 1.000
        }]
    }],
    tooltip: {
        pointFormat: '<b>{point.y}</b> (with target at {point.target})'
    },
    targetOptions: { // Options related with look and position of targets
        width: '140%',        // The width of the target
        height: 3,            // The height of the target
        borderWidth: 0,       // The border width of the target
        borderColor: 'black', // The border color of the target
        color: 'black'        // The color of the target
    }
}


const useStyles = makeStyles((theme) => ({
    paper: {
        [theme.breakpoints.between("sm", "md")]: {
            marginTop: theme.spacing(3),
        },
        "@media (min-width: 1280px)": {
            width: 1000,
            top: `50%`,
            left: `50%`,
            transform: `translate(-50%, -50%)`,
            position: 'absolute',
        },

        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        maxHeight: "90vh",
        overflow: "scroll",
    },
    radio: {
        fontSize: "14px"
    }
}));

const fetchMinor = (id, params, callback) => {
    let minorRepository = new MinorRepository();
    minorRepository.getMinor(id, params)
        .then(response => response.data)
        .then(data => {
            callback(data)
        })
        .catch(err => {
                callback([]);
            }
        )
}


function MinorDetails(props) {
    const {classes, userRules, heading, ...rest} = props;
    const [minor, setminor] = useState(null)
    const [progress, setprogress] = useState(null)
    const [loading, setLoading] = useState(false)
    const minorId = props.match.params.minorId;
    const [openAlert, setOpenAlert] = useState(false);
    const [toastMessage, setToast] = useState({});
    const modalClasses = useStyles();


    const handleAlertClose = () => {
        setOpenAlert(false);
    }

    const fetchSubminor = () => {
        let id = parseInt(minorId)

        function handleMinor({canal, progress}) {
            setminor(canal)
            setprogress(progress)
            setLoading(false);
        }

        setLoading(true);
        fetchMinor(id, {}, handleMinor)
    }


    useEffect(() => {
        let id = parseInt(minorId)
        if (!isNaN(id) && !loading && !minor) {
            fetchSubminor()
        }
    }, [])

    return (
        <Paper className={classes.paper}>
            <div className={classes.contentWrapper}>
                {
                    minor ?
                        <Typography variant={"h5"} color="textPrimary" align="center" className={classes.title}>
                            {minor.name}
                        </Typography> : null
                }
            </div>
            <div>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
            </div>
            <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleAlertClose}>
                <Alert onClose={handleAlertClose} severity={toastMessage.type}>
                    {toastMessage.msg}
                </Alert>
            </Snackbar>
        </Paper>
    )
}

MinorDetails.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MinorDetails);
