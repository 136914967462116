import React, {useState, useEffect} from 'react'
import {Grid,} from '@material-ui/core';
import Controls from "../../../Components/controls/Controls";
import {useForm, Form} from '../../../Components/useForm';
import {DropzoneDialog} from "material-ui-dropzone";
import InspectionReportRepository from "../../../../repository/inspection-report-repository";
import DivisionRepository from "../../../../repository/division-repository";
import PackRepository from "../../../../repository/pack-repository";
import CustomLinearProgress from "../../../../components/CustomLinearProgress/CustomLinearProgress";
import {INSPECTION_TYPE} from "../../../../models/user";
import MinorRepository from "../../../../repository/minor-repository";
import InputAdornment from "@material-ui/core/InputAdornment";
import moment from "moment";
// import * as employeeService from "../../../services/employeeService";


const initialFValues = {
    inspectionOn: new Date(),
    componentId: "",
    progress_rd: "",
    progress_kms: "",
    remarks: "",
}

export default function AddProgressMilestoneForm(props) {

    const {handleClose, components, pack, setOpenAlert, setToast} = props;

    const [loading, setLoading] = useState(false);

    const validate = (fieldValues = values) => {
        let temp = {...errors}
        if ('inspectionOn' in fieldValues)
            temp.inspectionOn = fieldValues.inspectionOn ? "" : "This field is required."
        if ('inspectionFile' in fieldValues)
            temp.inspectionFile = fieldValues.inspectionFile ? "" : "This field is required."
        setErrors({
            ...temp
        })

        if (fieldValues == values)
            return Object.values(temp).every(x => x == "")
    }

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    } = useForm(initialFValues, true, validate);

    const handleSubmit = e => {
        e.preventDefault()
        // employeeService.insertEmployee(values
        // )
        if (validate()) {
            setLoading(true);
            let formData = new FormData();
            formData.append("componentId", values.componentId);
            formData.append("progress_rd", values.progress_rd);
            formData.append("remarks", values.remarks);
            formData.append("inspectionOn", values.inspectionOn);

            let minorRepository = new MinorRepository();
            minorRepository.submitComponentMilestone(formData).then(data => {
                setLoading(false);
                handleClose(true);
                setOpenAlert(true);
                setToast({type: "success", msg: "Progress Added!"});
            }).catch(e => {
                console.log("Error");
                setLoading(false);
                // handleClose(true);
                setOpenAlert(true)
                setToast({type: "error", msg: e.message});
            })
        }
    }

    const reset = () => {
        resetForm()
        setValues(initialFValues)
    }

    const selectedCanal = values.componentId ? components.find((canal) => canal.id === values.componentId) : null;
    const selectedCanalDate = selectedCanal?.milestones[0]?.achieved_by;
    const parsedDate = selectedCanalDate ? new moment(selectedCanalDate).format("MMM YYYY") : null

    return (
        <Form onSubmit={handleSubmit}>
            <h2 id="modal-title">Add{loading ? "ing" : ""} Milestone-wise Progress</h2>
            {
                loading && <CustomLinearProgress/>
            }
            <Grid container alignItems={"center"}>
                <Grid item style={{width: "100%"}}>
                    <Controls.Select
                        name="componentId"
                        label="Component"
                        value={values.componentId}
                        onChange={handleInputChange}
                        options={components}
                        error={errors.componentId}
                        disabled={loading}
                    />
                    {
                        parsedDate ? <div style={{
                            padding: "5px 5px 5px 20px",
                            display: "flex",
                            color: "red"
                        }}> Last progress at: {parsedDate}</div> : null
                    }
                    <Controls.DatePicker
                        name="inspectionOn"
                        label="Progress Date"
                        value={values.inspectionOn}
                        onChange={handleInputChange}
                        disabled={loading}
                        maxDate={new Date()}
                    />
                    <Controls.Input
                        name="progress_rd"
                        label={"Progress this month"}
                        value={values.progress_rd}
                        onChange={handleInputChange}
                        type={"number"}
                        disabled={loading}
                        InputProps={{endAdornment: <InputAdornment position="end">{selectedCanal?.unit || ""}</InputAdornment>}}
                        error={errors.progress_rd}
                    />
                    <Controls.Input
                        label="Remarks"
                        name="remarks"
                        value={values.remarks}
                        onChange={handleInputChange}
                        disabled={loading}
                        error={errors.remarks}
                    />
                    <div>
                        <Controls.Button
                            disabled={!(values.componentId && values.progress_rd) || loading}
                            type="submit"
                            text="Submit"/>
                        <Controls.Button
                            text="Reset"
                            color="default"
                            disabled={loading}
                            onClick={reset}/>
                    </div>
                </Grid>
            </Grid>
        </Form>
    )
}
