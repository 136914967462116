import CollectionVisitController from '../controllers/collection-visit-controller.js';

class CollectionVisitRepository {
    getCollectionVisit(id, data) {
        let inspectionFormController = new CollectionVisitController();
        return inspectionFormController.getCollectionVisit(id, {
            data: data,
        });
    }
    getCollectionVisits(data) {
        let inspectionFormController = new CollectionVisitController();
        return inspectionFormController.getCollectionVisits({
            data: data,
        });
    }
    submitCollectionVisit(data) {
        let inspectionFormController = new CollectionVisitController();
        return inspectionFormController.postCollectionVisit({
            data: data,
        });
    }

    updateCollectionVisit(id, data) {
        let inspectionFormController = new CollectionVisitController();
        return inspectionFormController.updateCollectionVisit(id, {
            data: data,
        });
    }

}

export default CollectionVisitRepository;
