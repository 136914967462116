import ComplianceHistoryController from "../controllers/compliance-history-controller";

class ComplianceHistoryRepository {
    getHistories(data) {
        const complianceHistoryController = new ComplianceHistoryController();
        return complianceHistoryController.getHistories({
            data: data,
        });
    }

}

export default ComplianceHistoryRepository;
