import { title } from "assets/jss/material-kit-react.js";

const productStyle = {
  container: {
    display: 'flex',
    justifyContent: "space-between",
    padding: '15px',
    flexWrap: "wrap",
    paddingRight: "40px",
  },
  section: {
    padding: "70px 0",
    textAlign: "center"
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
  description: {
    color: "#999",
    "@media (max-width: 576px)": {
      fontSize: "0.875rem"
    },
  },
  width: {
    width: "50%",
    "@media (max-width: 576px)": {
      width: "80%"
    },
  },
  image: {
    "@media (min-width: 200px)": {
      width: "100%"
    },
    "@media (min-width: 700px)": {
      width: "33%"
    },
  }
};

export default productStyle;
