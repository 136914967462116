import CustomAPI from "../lib/custom-api";

class InspectionReportController {
    constructor(){
        this.customApi = new CustomAPI();
    }
    getInspectionReports(parameters) {
        parameters.endPoint = 'api/inspectionReport';
        return this.customApi.get(parameters);
    }
    postInspectionForm(parameters) {
        parameters.endPoint = 'api/inspectionReport';
        parameters.headers = {'content-type': 'multipart/form-data'}
             for (const value of parameters.data.values()) {
              }
        return this.customApi.post(parameters);
    }
}

export default InspectionReportController;
