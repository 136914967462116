import React, {useState, useEffect} from 'react'
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import makeStyles from "@material-ui/core/styles/makeStyles";
import yellow from "@material-ui/core/colors/yellow";
import {SAMPLE_STATUS_KEY_VALUE} from "../../../../models/sample";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import TestSubmissionReportRepository from "../../../../repository/test-submission-report-repository";
import GridContainer from "../../../../components/Grid/GridContainer";
import GridItem from "../../../../components/Grid/GridItem";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import CollectionVisitWithReports from "./CollectioVisitWithReports";
import TablePagination from "@material-ui/core/TablePagination";
import CollectedSamples from "./CollectedSamples";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Controls from "../../../Components/controls/Controls";
import {Grid} from "@material-ui/core";
import Button from "../../../../components/CustomButtons/Button";
import {Form} from "../../../Components/useForm";
import {hasRule, RULES_CONSTANTS} from "../../../../models/user";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Modal from "@material-ui/core/Modal";
import ComplianceHistory from "../Division/ComplianceHistory";
import SubmissionReportHistory from "./SubmissionReportHistory";
import modalStyle from "../../../../assets/jss/material-kit-react/modalStyle";
import {CloudDownload} from "@material-ui/icons";

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    color_green: {
        color: "green",
    },
    color_red: {
        color: "red",
    },
    color_yellow: {
        color: yellow["700"],
    },
    fontSmall: {
        fontSize: "14px"
    }
});

const styles = (theme) => ({
    paper: {
        // maxWidth: 1200,
        margin: 'auto',
        overflow: 'hidden',
    },
    searchBar: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    searchInput: {
        fontSize: theme.typography.fontSize,
    },
    block: {
        display: 'block',
    },
    addUser: {
        marginRight: theme.spacing(1),
    },
    contentWrapper: {
        margin: '20px 16px',
    },
    title: {
        padding: '20px',
    },
    search: {
        borderRight: "1px solid lightgray",
    }
});

const useStyles = makeStyles((theme) => ({
    paper: {
        [theme.breakpoints.between("sm", "md")]: {
            marginTop: theme.spacing(3),
        },
        "@media (min-width: 1280px)": {
            width: 1000,
            top: `50%`,
            left: `50%`,
            transform: `translate(-50%, -50%)`,
            position: 'absolute',
        },

        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        maxHeight: "90vh",
        overflow: "scroll",
    },
    radio: {
        fontSize: "14px"
    }
}));

const fetchReport = (id, params, callback) => {
    let testSubmissionReportRepository = new TestSubmissionReportRepository();
    testSubmissionReportRepository.getReport(id, params)
        .then(response => response.data)
        .then(data => {
            callback(data)
        })
        .catch(err => {
                callback([]);
            }
        )
}


function SubmissionReport(props) {
    const {classes, userRules, heading, ...rest} = props;
    const [report, setReport] = useState(null)
    const [loading, setLoading] = useState(false)
    const reportId = props.match.params.reportId;
    const [openAlert, setOpenAlert] = useState(false);
    const [toastMessage, setToast] = useState({});
    const [title, setTitle] = useState("");
    const [open, setOpen] = useState(false)
    const testReports = report && report.testReports ? report.testReports : [];
    const [values, setValues] = useState({})
    const [testFile, setTestFile] = useState("")
    const [historyModal, setHistoryModalOpen] = React.useState(null);
    const [sampleModal, setSampleModalOpen] = React.useState(false);
    const CAN_UPDATE = report && report.status === 0 && hasRule(userRules, RULES_CONSTANTS.CAN_SUBMIT_TEST_REPORTS);
    const CAN_ACCEPT_OR_REJECT = report && report.status === 1 && hasRule(userRules, RULES_CONSTANTS.CAN_ACCEPT_TEST_REPORTS);
    const modalClasses = useStyles();


    const handleAlertClose = () => {
        setOpenAlert(false);
    }

    const fetchSubReport = () => {
        let id = parseInt(reportId)

        function handleReport(data) {
            setReport(data)
            setLoading(false);
        }

        setLoading(true);
        fetchReport(id, {}, handleReport)
    }

    const setStatus = (id, value, label) => {
        setValues({...values, [id]: {...values[id], [label]: value}})
    }
    const handleFileUpload = (name,files) => {
        setTestFile(files)
    }

    const updateStatus = ({report, reqData}) => {
        let testSubmissionReportRepository = new TestSubmissionReportRepository();

        let data = {id: report.id, ...reqData};

        const formData = new FormData();
        Object.keys(data).forEach(key => {
            if (Array.isArray(data[key])) {
                let array = data[key];
                array.forEach(obj => {
                    formData.append(key, obj);
                })
            } else if(key === "testReports") {
                formData.append(key, JSON.stringify(data[key]))
            }else {
                formData.append(key, data[key])
            }
        });

        testSubmissionReportRepository.updateTestSubmissionReport(report.id, formData).then(data => {
            fetchSubReport()
            setLoading(false)
            setOpenAlert(true)
            setToast({type: "success", msg: "Collection Visit Updated!"});
            setTitle("")
        }).catch(e => {
            setOpenAlert(true)
            setLoading(false)
            setToast({type: "error", msg: e.message});
        })
    }

    const handleSubmit = e => {
        e.preventDefault()
        setLoading(true)
        updateStatus({reqData: {reqType: 1, visitStatus: 1, testReports: values, testReportFile: testFile}, report})
    }

    useEffect(() => {
        let id = parseInt(reportId)
        if (!isNaN(id) && !loading && !report) {
            fetchSubReport()
        }
    }, [])

    useEffect(() => {
        if (testReports && testReports.length) {
            setValues(testReports.reduce((acc, cur) => ({
                ...acc,
                [cur.id]: {remark: cur.calculated_metrics || "", status: cur.status || null}
            }), {}))
        }
    }, [testReports])

    return (
        <Paper className={classes.paper}>
            <div className={classes.contentWrapper}>
                <Typography variant={"h5"} color="textPrimary" align="center" className={classes.title}>
                    {heading}
                </Typography>
                <Grid container>
                    <Grid item xl={4} lg={4} md={4} sm={4} style={{textAlign: "center"}}>
                        <Button
                            size={"sm"}
                            color={"rose"}
                            onClick={e => {
                                e.stopPropagation();
                                setSampleModalOpen(true)
                            }}
                        >
                            Show Samples Associated
                        </Button>
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} sm={4} style={{textAlign: "center"}}>
                        <Button
                            size={"sm"}
                            color={"primary"}
                            onClick={e => {
                                e.stopPropagation();
                                setHistoryModalOpen(true)
                            }}
                        >
                            Show Submission History
                        </Button>
                    </Grid>
                    {
                        report && report.compliance_report && report.compliance_report.length ?
                        <Grid item xl={4} lg={4} md={4} sm={4} style={{textAlign: "center"}}>
                            <Button
                                size={"sm"}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    window.location.href = report.compliance_report[0].url;
                                }}
                                color={"warning"}
                            >
                                <CloudDownload/> Download Test Report
                            </Button>
                        </Grid> : null
                    }
                </Grid>
            </div>
            <Form onSubmit={handleSubmit}>
                {
                    CAN_UPDATE ?
                        <Grid container alignItems={"center"}>
                            <Controls.UploadFile
                                name="testReportFile"
                                label="Add Test Report"
                                title={"Upload Test Report, (1 file, PDF, Max Size: 8mB)"}
                                value={testFile}
                                onChange={handleFileUpload}
                                dialogProps={{
                                    acceptedFiles: ['.pdf'],
                                    showPreviews: true,
                                    maxFileSize: (8 * 10124* 1024),
                                    filesLimit: 1,
                                }}
                                disabled={loading}
                            />
                        </Grid>: null
                }
                <GridContainer>
                    <GridItem>
                        {
                            loading ?
                                <Box
                                    display="flex"
                                    width={"100%"} height={100}
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <CircularProgress/>
                                </Box> :
                                testReports && testReports.length ?
                                    <>
                                        <TableContainer component={Paper}>
                                            <Table style={{fontSize: "18px"}} className={classes.table}
                                                   aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell width={"5%"}>Test Id</TableCell>
                                                        <TableCell width={"25%"}>Test Name</TableCell>
                                                        <TableCell width={"25%"}>Calculated Metrics</TableCell>
                                                        <TableCell width={"25%"}>Standard Range</TableCell>
                                                        <TableCell width={"20%"}>Status</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        testReports.map((testReport, index) => {
                                                            let test = testReport.test || {};
                                                            const isRejected = testReport && testReport.status === 5;
                                                            let standardRange = []
                                                            try {
                                                                standardRange = test.range ? JSON.parse(test.range) : []
                                                            } catch (e) {
                                                                console.log(e)
                                                            }
                                                            return (
                                                                <TableRow className={classes.root} key={"row_" + index}>
                                                                    <TableCell width={"5%"}>{testReport.id}</TableCell>
                                                                    <TableCell
                                                                        width={"25%"}>{test.name || "-"}</TableCell>
                                                                    <TableCell width={"25%"}>
                                                                        {
                                                                            CAN_UPDATE && !isRejected ?
                                                                                <Controls.Input
                                                                                    required
                                                                                    style={{width: "80%"}}
                                                                                    value={values && values[testReport.id] ? values[testReport.id].remark : ""}
                                                                                    disabled={loading}
                                                                                    onChange={(event) => {
                                                                                        setStatus(testReport.id, event.target.value, "remark")
                                                                                    }}
                                                                                    variant={"outlined"}
                                                                                    id="standard-basic"
                                                                                    label="Enter Test Result here"/> :
                                                                                <span>
                                                                                    {testReport.calculated_metrics}
                                                                                </span>
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell width={"25%"}>
                                                                        {/*<ul style={{padding: 0}}>{divReqd.map((div,i) => <li key={div.name + "" + i}>{div.name}</li>)}</ul>*/}
                                                                        <ul style={{padding: 0}}>
                                                                            {
                                                                                standardRange.length ? standardRange.map(({label, value}, i) => {
                                                                                    return (
                                                                                        <li key={index + "_li_" + i}>{label + " : " + value}</li>
                                                                                    )
                                                                                }) : <span>
                                                                                    -
                                                                                </span>
                                                                            }
                                                                        </ul>
                                                                    </TableCell>
                                                                    <TableCell width={"20%"}>
                                                                        {
                                                                            isRejected ?
                                                                                <span>Rejected Sample </span> :
                                                                                CAN_UPDATE ? <Controls.Select
                                                                                    name="status"
                                                                                    label="Test Result"
                                                                                    value={values && values[testReport.id] ? values[testReport.id].status : ""}
                                                                                    onChange={(event) => {
                                                                                        setStatus(testReport.id, event.target.value, "status")
                                                                                    }}
                                                                                    style={{width: "80%"}}
                                                                                    includeNone
                                                                                    disabled={loading}
                                                                                    options={
                                                                                        [
                                                                                            {
                                                                                                "id": 1,
                                                                                                "name": "Passed"
                                                                                            },
                                                                                            {
                                                                                                "id": 2,
                                                                                                "name": "Failed"
                                                                                            },
                                                                                            {
                                                                                                "id": 3,
                                                                                                "name": "Much more strength"
                                                                                            },
                                                                                        ]}
                                                                                /> : <span>
                                                                                {
                                                                                    !testReport.status ?
                                                                                        "-" :
                                                                                        testReport.status === 1 ?
                                                                                            "Passed" :  testReport.status === 3 ? "Much more strength" : "Failed"
                                                                                }
                                                                            </span>
                                                                        }

                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        })
                                                    }

                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </> :
                                    <Box
                                        display="flex"
                                        width={"100%"} height={100}
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        No Tests
                                    </Box>
                        }
                    </GridItem>
                    <GridItem>
                        {
                            CAN_UPDATE ?
                                <Controls.Button
                                    disabled={
                                        !((testReports.length && Object.keys(values).filter((key => {
                                            return (!!values[key].status && !!values[key].remark)
                                        }))).length === testReports.filter((report) => report.status!==5).length) || loading
                                    }
                                    type="submit"
                                    text="Submit Test Reports to Team Leader"/> : null
                        }
                        {
                            CAN_ACCEPT_OR_REJECT ? <>
                                <Button
                                    size={"sm"}
                                    color={"success"}
                                    onClick={e => {
                                        e.stopPropagation();
                                        // setStatus(1)
                                        updateStatus({reqData: {reqType: 2, visitStatus: 2}, report})
                                    }}
                                >
                                    Accept
                                </Button>
                                <Button
                                    size={"sm"}
                                    color={"danger"}
                                    onClick={e => {
                                        e.stopPropagation();
                                        setOpen(true)
                                    }}
                                >
                                    Reject
                                </Button>
                            </> : null
                        }
                    </GridItem>
                </GridContainer>
            </Form>
            <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleAlertClose}>
                <Alert onClose={handleAlertClose} severity={toastMessage.type}>
                    {toastMessage.msg}
                </Alert>
            </Snackbar>
            <Dialog open={open} onClose={() => {
                setOpen(false)
            }} aria-labelledby="form-dialog-title">
                <DialogTitle
                    id="form-dialog-title">Reject Submission Report</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Kindly Enter the Remarks for Rejecting the Submission Report
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="remarks"
                        label="Remarks"
                        type="text"
                        fullWidth
                        onChange={(e) => {
                            setTitle(e.target.value)
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setOpen(false)
                    }} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => {
                        if (title) {
                            setOpen(false)
                            updateStatus({reqData: {reqType: 3, visitStatus: 2, remark: title}, report})
                        } else {
                            alert("Please enter remarks")
                        }
                    }} color="primary">
                        Reject Submission Report
                    </Button>
                </DialogActions>
            </Dialog>
            <Modal
                open={!!historyModal}
                onClose={() => {
                    setHistoryModalOpen(false)
                }}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div style={modalStyle} className={modalClasses.paper}>
                    <SubmissionReportHistory
                        submissionReportId={reportId}
                        handleClose={() => {
                            setHistoryModalOpen(false)
                        }}/>
                </div>
            </Modal>
            <CollectedSamples
                userRules={userRules}
                visit={{id: report && report.collectionVisitId}}
                open={!!sampleModal}
                onClose={() => {
                    setSampleModalOpen(false)
                }}
                updateStatus={updateStatus}
            />
        </Paper>
    )
}

SubmissionReport.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SubmissionReport);
