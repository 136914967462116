import React, {useState, useEffect} from 'react'
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import makeStyles from "@material-ui/core/styles/makeStyles";
import yellow from "@material-ui/core/colors/yellow";
import {SAMPLE_STATUS_KEY_VALUE} from "../../../../models/sample";

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    color_green: {
        color: "green",
    },
    color_red: {
        color: "red",
    },
    color_yellow: {
        color: yellow["700"],
    },
    fontSmall: {
        fontSize: "14px"
    }
});


export default function CollectionVisit(props) {
    const {
        visit,
        divisions,
        setSelectedVisit,
    } = props;
    const classes = useRowStyles();
    const [open, setOpen] = React.useState(false);
    const pack = visit.pack;
    const inspectionDate = (new Date(visit.inspected_on)).toDateString();
    const samples = visit.collectedSamples;
    const divReqd = visit.divisionsId.map(div => {
        return divisions.find((division) => {
            return division.id === parseInt(div);
        })
    })


    return (
        <React.Fragment key={visit.id + "_visit"}>
            <TableRow className={classes.root} hover onClick={() => {
                setSelectedVisit(visit)
            }}>
                <TableCell align={"right"} width={"5%"}>
                    <strong>{visit.id}.</strong>
                </TableCell>
                <TableCell align={"right"} width={"5%"}>
                    <strong>{pack.packageId}</strong>
                </TableCell>
                <TableCell width={"10%"}>{inspectionDate}</TableCell>
                <TableCell width={"10%"}>{visit.created_by}</TableCell>
                <TableCell className={classes.fontSmall} width={"20%"}>
                    <ul style={{padding: 0}}>{divReqd.map((div,i) => {
                        if(div && div.name) {
                            return <li key={div.name + "" + i}>{div.name}</li>
                        } else {
                            return "-"
                        }
                    })}</ul>
                </TableCell>
                <TableCell className={classes.fontSmall} width={"20%"}>{visit.site}</TableCell>
                <TableCell className={classes.fontSmall} width={"15%"}>{visit.remarks}</TableCell>
                <TableCell width={"15%"}>{SAMPLE_STATUS_KEY_VALUE[visit.status] || "-"}</TableCell>
            </TableRow>
        </React.Fragment>
    )
}
