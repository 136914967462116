/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import {List, ListItem} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";

import styles from "assets/jss/material-kit-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function Footer(props) {
    const classes = useStyles();
    const {whiteFont} = props;
    const footerClasses = classNames({
        [classes.footer]: true,
        [classes.footerWhiteFont]: whiteFont
    });
    const aClasses = classNames({
        [classes.a]: true,
        [classes.footerWhiteFont]: whiteFont
    });
    return (
        <footer className={footerClasses}>
            <div className={classes.container}>
                <div>
                    <List className={classes.list}>
                        <ListItem className={classes.inlineBlock}>
                            <div className={classes.block}>
                                Contact us -
                            </div>
                            <div>
                                Project Director (PMU) , RWSRPD
                            </div>
                            <div>
                                Water Resources (North),
                            </div>
                            <div>
                                Hanumangarh, Rajasthan-335512
                            </div>
                            <div>
                                Contact: 01552-260607
                            </div>
                            <div>
                                E-mail – cewrdhmh@gmail.com, pmuhmh@gmail.com
                            </div>
                        </ListItem>
                    </List>
                </div>
              <div>
              <List className={classes.list}>
                <ListItem className={classes.inlineBlock}>
                  <div className={classes.block}>
                    DEVELOPED & MANAGED BY -

                  </div>
                  <div>
                  
                     URS Scott Wilson India Private Limited (An AECOM Company)
                  </div>
                  <div>
                    Hanumangarh, Rajasthan-335512
                  </div>
                  <div>
                  E-mail – emchanumangarh@aecom.com
                  </div>
                    <div className={classes.right}>
                        &copy; {1900 + new Date().getYear()}
                    </div>
                </ListItem>
              </List>
              </div>
            </div>
        </footer>
    );
}

Footer.propTypes = {
    whiteFont: PropTypes.bool
};
