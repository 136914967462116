import CustomAPI from "../lib/custom-api";

class SampleController {
    constructor(){
        this.customApi = new CustomAPI();
    }
    getSamples(parameters) {
        parameters.endPoint = 'api/samples';
        return this.customApi.get(parameters);
    }
}

export default SampleController;
