import React, { Component } from 'react'
import {DropzoneDialog} from 'material-ui-dropzone'
import Button from "../../../components/CustomButtons/Button";

export default class UploadFile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
            open: false,
        };
    }

    handleClose() {
        this.setState({
            open: false
        });
    }

    handleSave(files) {
        //Saving files to state for further use and closing Modal.
        const {name, onChange} = this.props;
        this.setState({
            files: files,
            open: false
        }, () => {
            onChange(name, files)
        });
    }

    handleOpen() {
        this.setState({
            open: true,
        });
    }

    render() {
        const {label, title, disabled=false} = this.props;
        return (
            <div>
                <Button
                    round
                    color={this.state.files.length ? "success" : "rose"}
                    disabled={disabled}
                    onClick={this.handleOpen.bind(this)}
                >
                    {label}
                </Button>
                <DropzoneDialog
                    open={this.state.open}
                    dialogTitle={title}
                    onSave={this.handleSave.bind(this)}
                    onClose={this.handleClose.bind(this)}
                    initialFiles={this.state.files}
                    clearOnUnmount={false}
                    {...this.props.dialogProps}
                />
            </div>
        );
    }
}