/*eslint-disable*/
import React, {useEffect} from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import {Link} from "react-router-dom";

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import {Apps, CloudDownload} from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import {useAuth0} from "@auth0/auth0-react";
import {unAuthenticate} from "../../lib/utils";

import RuleRepository from "../../repository/rule-repository";
import Events from "../../lib/events";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
    const classes = useStyles();
    const {user, isAuthenticated, loginWithRedirect, logout, getAccessTokenSilently} = useAuth0();

    const fireUserLogInChecked = () => {
        // fire event so that all listeners can update the user data
        let event = new Event(Events.events.USERDATAUPDATED);
        window.dispatchEvent(event);
    };

    const fetchRules = (callback) => {
        let ruleRepository = new RuleRepository();
        ruleRepository.getRules({})
            .then(response => response.data)
            .then(data => {
                callback(data)
            })
            .catch(err => {
                unAuthenticate();
                logout();
            });
    }
    useEffect( () => {
        const handleRules = (rules) => {
            window.staticParams = {};
            window.staticParams.rules = rules;
            fireUserLogInChecked();
        }

        if(isAuthenticated) {
            fetchRules(handleRules);
        } else {
        }
    }, [isAuthenticated]);

    const dropDownList = [
        <Link to="/" className={classes.dropdownLink}>
            Overview
        </Link>,
        <Link to="/" className={classes.dropdownLink}>
            Project at a glance
        </Link>,
        <Link to="/" className={classes.dropdownLink}>
            Tenders
        </Link>,
        <Link to="/" className={classes.dropdownLink}>
            Complaint
        </Link>,
        <Link to="/contact" className={classes.dropdownLink}>
            Contact Us
        </Link>
    ];

    let dashboardLink = <Link to="/dashboard" className={classes.dropdownLink}>
        Dashboard
    </Link>

    if (isAuthenticated) {
        dropDownList.unshift(dashboardLink);
    }

    return (
        <List className={classes.list}>
            {
                isAuthenticated ?
                    <>
                        <ListItem className={classes.listItem}>
                            <Button
                                href={"/dashboard"}
                                className={classes.registerNavLink}
                                color="rose"
                                round
                            >
                                Go to Dashboard
                            </Button>
                        </ListItem>
                    <ListItem className={classes.listItem}>
                        <Button
                            href="#pablo"
                            className={classes.registerNavLink}
                            onClick={e => {
                                e.preventDefault();
                                unAuthenticate();
                                logout();
                            }}
                            color="primary"
                            round
                        >
                            Logout
                        </Button>
                    </ListItem>
                    </>
                    :
                    <ListItem className={classes.listItem}>
                        <Button
                            href="#pablo"
                            className={classes.registerNavLink}
                            onClick={e => {
                                e.preventDefault();
                                loginWithRedirect().then((data) => {
                                });
                            }}
                            color="primary"
                            round
                        >
                            Login
                        </Button>
                    </ListItem>
            }
        </List>
    );
}
