import React, {useState, useEffect} from 'react'
import {Grid,} from '@material-ui/core';
import Controls from "../../../Components/controls/Controls";
import {useForm, Form} from '../../../Components/useForm';
import DivisionRepository from "../../../../repository/division-repository";
import PackRepository from "../../../../repository/pack-repository";
import CustomLinearProgress from "../../../../components/CustomLinearProgress/CustomLinearProgress";
import SampleRepository from "../../../../repository/sample-repository";
import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import clsx from "clsx";
import CollectionVisitRepository from "../../../../repository/collection-visit-repository";
// import * as employeeService from "../../../services/employeeService";


const initialFValues = {
    inspectionOn: new Date(),
    site: "",
    remarks: "",
    divisionsId: [],
    packageId: "",
}

const styles = (theme) => ({
    sample: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 5,
        margin: 5,
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: theme.palette.grey[100],
            "& $deleteIcon": {
                visibility: "visible"
            }
        }
    },
    sampleTitle: {
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        fontWeight: "bold",
        width: "100%",
    },
    deleteIcon: {
        visibility: "hidden"
    },
    sampleContainer: {
        display: "flex",
        flexWrap: "wrap",
    }
});

const fetchPackages = (requestArgs, callback) => {
    let packRepository = new PackRepository();
    packRepository.getPacks(requestArgs)
        .then(response => response.data)
        .then(data => {
            callback(data)
        })
        .catch(err => console.log(err));
}

const fetchDivisions = (requestArgs, callback) => {
    let divisionRepository = new DivisionRepository();
    divisionRepository.getDivisions(requestArgs)
        .then(response => response.data)
        .then(data => {
            callback(data)
        })
        .catch(err => console.log(err));
}

const fetchSamples = (requestArgs, callback) => {
    let sampleRepository = new SampleRepository();
    sampleRepository.getSamples(requestArgs)
        .then(response => response.data)
        .then(data => {
            callback(data)
        })
        .catch(err => console.log(err));
}


function SampleCollectionForm(props) {

    const {handleClose, fetchVisits, classes, setOpenAlert, setToast} = props;

    const [packages, setPackages] = useState([]);
    const [samples, setSamples] = useState([]);
    const [allowedDivisions, setAllowedDivisions] = useState([]);
    const [collectedSamples, setCollectedSamples] = useState([]);
    const [selectedSample, setSelectedSample] = useState(null);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);

    const validate = (fieldValues = values) => {
        let temp = {...errors}
        if ('inspectionOn' in fieldValues)
            temp.inspectionOn = fieldValues.inspectionOn ? "" : "This field is required."
        if ('inspectionFile' in fieldValues)
            temp.inspectionFile = fieldValues.inspectionFile ? "" : "This field is required."
        setErrors({
            ...temp
        })

        if (fieldValues == values)
            return Object.values(temp).every(x => x == "")
    }

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        handleMultipleFilesUpload,
        resetForm
    } = useForm(initialFValues, true, validate);

    useEffect(() => {
        function handleData(data) {
            setPackages(data);
        }

        let params = {}
        fetchPackages(params, handleData)

    }, [])

    useEffect(() => {
        function handleData(data) {
            setSamples(data);
        }

        let params = {}
        fetchSamples(params, handleData)

    }, [])

    useEffect(() => {
        function handleData(data) {
            setAllowedDivisions(data);
        }

        if (values.packageId) {
            let params = {
                packId: values.packageId.id,
            }
            fetchDivisions(params, handleData)
        }
        setValues({
            ...values,
            divisionsId: [],
        })

    }, [values.packageId])

    const handleSubmit = e => {
        e.preventDefault()
        // employeeService.insertEmployee(values
        // )
        if (validate()) {
            setLoading(true);
            let formData = new FormData();
            Object.keys(values).forEach(key => {
                if (Array.isArray(values[key])) {
                    let array = values[key];
                    array.forEach(obj => {
                        if (key === 'divisionsId') {
                            formData.append(key, obj.id)
                        } else {
                            formData.append(key, obj);
                        }
                    })
                } else {
                    if (key === 'packageId') {
                        formData.append(key, values[key].id)
                    } else {
                        formData.append(key, values[key])
                    }
                }
            });

            formData.append('allowedDivisions', JSON.stringify(allowedDivisions));
            // console.log(formData)
            formData.append('collectedSamples', JSON.stringify(collectedSamples.map(({sample, tests}) => { return {
                sample_id: sample.id,
                tests,
            }})))

            let collectionVisitRepository = new CollectionVisitRepository();
            collectionVisitRepository.submitCollectionVisit(formData).then(data => {
                fetchVisits()
                setLoading(false);
                handleClose(true);
                setOpenAlert(true);
                setToast({type: "success", msg: "Inspection Report Created"});
            }).catch(e => {
                console.log("Error");
                setLoading(false);
                // handleClose(true);
                setOpenAlert(true)
                setToast({type: "error", msg: e.message});
            })
        }
    }

    const reset = () => {
        resetForm()
        setValues(initialFValues)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <h2 id="modal-title">Add{loading ? "ing" : ""} {page === 1 ? "Collection Visit" : "Samples"}</h2>
            {
                loading && <CustomLinearProgress/>
            }
            <Grid container alignItems={"center"}>
                <Grid item style={{width: "100%"}}>
                    {
                        page === 1 ?
                            <>
                                <Controls.AutoComplete
                                    name="packageId"
                                    label="Package"
                                    value={values.packageId}
                                    options={packages}
                                    onChange={handleInputChange}
                                    disabled={loading}
                                    getOptionLabel={(option) => (option.packageId ? (option.packageId + " " + option.name) : "")}
                                />
                                <Controls.AutoComplete
                                    multiple
                                    name="divisionsId"
                                    label="Inspection Report pertains to -"
                                    value={values.divisionsId}
                                    getOptionLabel={(option) => option.name}
                                    onChange={handleInputChange}
                                    options={allowedDivisions}
                                    disabled={!(values.packageId) || loading}
                                    error={errors.divisionId}
                                />
                                <Controls.DatePicker
                                    name="inspectionOn"
                                    label="Inspection Date"
                                    value={values.inspectionOn}
                                    onChange={handleInputChange}
                                    disabled={loading}
                                    maxDate={new Date()}
                                />
                                <Controls.Input
                                    name="site"
                                    label="Site"
                                    value={values.site}
                                    onChange={handleInputChange}
                                    disabled={loading}
                                    error={errors.site}
                                />
                                <Controls.Input
                                    label="Remarks"
                                    name="remarks"
                                    value={values.remarks}
                                    onChange={handleInputChange}
                                    disabled={loading}
                                    error={errors.remarks}
                                />
                                <Controls.Button
                                    disabled={!(values.divisionsId.length && values.packageId) || loading}
                                    onClick={() => {
                                        setPage(2)
                                    }}
                                    text="Add Samples Collected"
                                />
                            </> : page === 2 ? <>
                                <div>
                                    <Controls.Button
                                        variant={"outlined"}
                                        color={"secondary"}
                                        fullWidth
                                        disabled={loading}
                                        onClick={() => {
                                            setSelectedSample({
                                                sample: null,
                                                tests: [],
                                            })
                                        }}
                                        text="Add New Sample"
                                    />
                                    {
                                        selectedSample ? <Paper>
                                            <Controls.AutoComplete
                                                name="sample"
                                                label="Sample"
                                                value={selectedSample.sample}
                                                options={samples}
                                                onChange={({target: {name, value}}) => {
                                                    setSelectedSample({
                                                        sample: value,
                                                        tests: [],
                                                    })
                                                }}
                                                disabled={loading}
                                                getOptionLabel={(option) => option.name}
                                            />
                                            <Controls.AutoComplete
                                                multiple
                                                name="tests"
                                                label="Tests for the sample"
                                                value={selectedSample.tests}
                                                getOptionLabel={(option) => option.name}
                                                onChange={({target: {name, value}}) => {
                                                    setSelectedSample({
                                                        ...selectedSample,
                                                        tests: value
                                                    })
                                                }}
                                                options={selectedSample && selectedSample.sample ? selectedSample.sample.tests : []}
                                                disabled={!(values.packageId) || loading}
                                                error={errors.divisionId}
                                            />
                                            <Controls.Button
                                                disabled={!(selectedSample && selectedSample.sample && selectedSample.tests && selectedSample.tests.length) || loading}
                                                onClick={() => {
                                                    setCollectedSamples([
                                                        ...collectedSamples,
                                                        selectedSample
                                                    ])
                                                    setSelectedSample(null)
                                                }}
                                                text="Add Sample"
                                            />
                                        </Paper> : null
                                    }
                                    <div className={classes.sampleContainer}>
                                        {
                                            collectedSamples.map(({sample, tests}, index) => {
                                                return <div
                                                    className={classes.sample}
                                                    key={sample.name + "_" + index}
                                                >
                                                    <div className={classes.sampleTitle}>
                                                        <span>Sample {(index+1)}: {sample.name}</span>
                                                        <IconButton
                                                            className={classes.deleteIcon}
                                                            key="close"
                                                            aria-label="Close"
                                                            color="inherit"
                                                            onClick={() => {
                                                                setCollectedSamples(collectedSamples.filter((se, i) => i !== index))
                                                            }}
                                                        >
                                                            <Close className={classes.modalClose}/>
                                                        </IconButton>
                                                    </div>
                                                    <ul>
                                                        {tests.map((test, index) => {
                                                            return <li key={test.name + "_" + index}>
                                                                <span>Test {(index+1)}: {test.name}</span>
                                                            </li>
                                                        })}
                                                    </ul>
                                                </div>
                                            })
                                        }
                                    </div>
                                    <Controls.Button
                                        disabled={!(collectedSamples.length) || loading}
                                        type="submit"
                                        text="Submit"/>
                                    <Controls.Button
                                        disabled={loading}
                                        onClick={() => {
                                            setPage(1)
                                        }}
                                        text="Go Back"
                                    />
                                    {/*<Controls.Button*/}
                                    {/*    text="Reset"*/}
                                    {/*    color="default"*/}
                                    {/*    disabled={loading}*/}
                                    {/*    onClick={reset}/>*/}
                                </div>
                            </> : null
                    }
                </Grid>
            </Grid>
        </Form>
    )
}

SampleCollectionForm.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SampleCollectionForm);
