import DivisionController from "../controllers/division-controller";

class DivisionRepository {
    getDivisions(data) {
        let divisionController = new DivisionController();
        return divisionController.getDivisions({
            data: data,
        });
    }
}

export default DivisionRepository;
