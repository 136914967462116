import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import InspectionForm from "./InspectionForm";

const styles = (theme) => ({
    paper: {
        maxWidth: 1200,
        margin: 'auto',
        overflow: 'hidden',
    },
    searchBar: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    searchInput: {
        fontSize: theme.typography.fontSize,
    },
    block: {
        display: 'block',
    },
    addUser: {
        marginRight: theme.spacing(1),
    },
    contentWrapper: {
        margin: '20px 16px',
    },
    title: {
        padding: '20px',
    }
});

function EMCInspection(props) {
    const { classes, pack } = props;

    return (
        <Paper className={classes.paper}>
            <div className={classes.contentWrapper}>
                <Typography variant={"h4"} color="textPrimary" align="center" className={classes.title}>
                    {pack.name}
                </Typography>
                <Typography variant={"h5"} color="textPrimary" align="center">
                    Inspection Reports & Compliance - EMC
                </Typography>
                <InspectionForm/>
            </div>
        </Paper>
    );
}

EMCInspection.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EMCInspection);