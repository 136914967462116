import CustomAPI from "../lib/custom-api";

class SubmissionReportHistoryController {
    constructor(){
        this.customApi = new CustomAPI();
    }
    getHistories(parameters) {
        parameters.endPoint = 'api/testSubmissionReportHistory';
        return this.customApi.get(parameters);
    }
}

export default SubmissionReportHistoryController;
