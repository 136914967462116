import axios from "axios";

class CustomAPI {
    constructor() {
        // this.baseurl = typeof window === "undefined" ? global.PHP_SERVICE : window.FULL_BASE_URL;
        this.baseurl = process.env.REACT_APP_BASE_URL;
        // this.BACKEND_API_URL = typeof window === 'undefined' ? global.PYTHON_SERVICE : window.PYTHON_API_URL;
        // this.BACKEND_API_URL = "https://www.rwsrpd.com/";
        this.BACKEND_API_URL = process.env.REACT_APP_BACKEND_URL;
    }

    get(parameters) {
        return this._makeRequest(parameters, 'GET');
    }

    post(parameters) {
        return this._makeRequest(parameters, 'POST');
    }

    put(parameters) {
        return this._makeRequest(parameters, 'PUT');
    }

    delete(parameters) {
        return this._makeRequest(parameters, 'DELETE');
    }

    getAuthToken(parameters) {
        if(parameters.authToken){
            return parameters.authToken;
        }
        return localStorage.getItem('token');
    }

    getFullBaseURL(parameters) {
        return parameters.baseurl || this.BACKEND_API_URL;
    }

    _makeRequest(parameters, type = "GET") {
        // if(parameters.data){
        //     for (const value of parameters.data.values()) {
        //       }
        // }
       
        
        try {

            let headers = {
                'X-Web-Req': 1,
                ...parameters.headers
            };
            if (this.getAuthToken(parameters)) {
                headers['Authorization'] = "Bearer " + this.getAuthToken(parameters);
                headers['X-User-Handle'] = 'XXX';
            }

            let obj = {
                url: this.getFullBaseURL(parameters) + parameters.endPoint,
                method: type,
                headers: headers
            };
            if (type === 'GET' && parameters.data) {
                obj.params = parameters.data;
            }
            if (type !== 'GET' && parameters.data) {
                obj.data = parameters.data;
            }
            if (parameters.timeout) {
                obj.timeout = parameters.timeout;
            }
            return axios(obj);
        } catch (e) {
            try {
                console.error('Critical error in Async request: ', e)
            } catch (e) {
            }
        }
    }
}

export default CustomAPI;
