import PackController from '../controllers/pack-controller';

class PackRepository {
    getPack(PackId, data) {
        let packsApi = new PackController();
        return packsApi.getPack(PackId, {
            data: data,
        });
    }

    getPacks(data) {
        let packsApi = new PackController();
        return packsApi.getPacks({
            data: data
        })
    }
}

export default PackRepository;
