import RuleController from '../controllers/rule-controller';

class RuleRepository {
    getRules(data) {
        let packsApi = new RuleController();
        return packsApi.getRules({
            data: data
        })
    }
}

export default RuleRepository;
