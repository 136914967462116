import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import {withStyles} from '@material-ui/core/styles';
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import GridContainer from "../../../../components/Grid/GridContainer";
import GridItem from "../../../../components/Grid/GridItem";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import {hasRule, isTypeCompatible, RULES_CONSTANTS} from "../../../../models/user";
import Button from "../../../../components/CustomButtons/Button";
import Grid from "@material-ui/core/Grid";
import Modal from "@material-ui/core/Modal";
import makeStyles from "@material-ui/core/styles/makeStyles";
import SampleCollectionForm from "./SampleCollectionForm";
import CollectionVisitRepository from "../../../../repository/collection-visit-repository";
import DivisionRepository from "../../../../repository/division-repository";
import CollectionVisit from "./CollectionVisit";
import CollectedSamples from "./CollectedSamples";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import {KeyboardDatePicker} from "@material-ui/pickers";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import RefreshIcon from "@material-ui/icons/Refresh";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {SAMPLE_STATUS} from "../../../../models/sample";
import debounce from "@material-ui/core/utils/debounce";
import CollectionVisitWithReports from "./CollectioVisitWithReports";

const styles = (theme) => ({
    paper: {
        // maxWidth: 1200,
        margin: 'auto',
        overflow: 'hidden',
    },
    searchBar: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    searchInput: {
        fontSize: theme.typography.fontSize,
    },
    block: {
        display: 'block',
    },
    addUser: {
        marginRight: theme.spacing(1),
    },
    contentWrapper: {
        margin: '20px 16px',
    },
    title: {
        padding: '20px',
    },
    search: {
        borderRight: "1px solid lightgray",
    }
});

function a11yProps(index) {
    return {
        id: `wrapped-tab-${index}`,
        'aria-controls': `wrapped-tabpanel-${index}`,
    };
}

const fetchCollectionVisits = (params, callback) => {
    let collectionVisitRepository = new CollectionVisitRepository();
    collectionVisitRepository.getCollectionVisits(params)
        .then(response => response.data)
        .then(data => {
            callback(data)
        })
        .catch(err => {
                console.log(err);
                callback([]);
            }
        )
}

const fetchDivisions = (callback) => {
    let divisionRepository = new DivisionRepository();
    divisionRepository.getDivisions()
        .then(response => response.data)
        .then(data => {
            callback(data)
        })
        .catch(err => console.log(err));
}


const useStyles = makeStyles((theme) => ({
    paper: {
        [theme.breakpoints.between("sm", "md")]: {
            marginTop: theme.spacing(3),
        },
        "@media (min-width: 1280px)": {
            width: 1000,
            top: `50%`,
            left: `50%`,
            transform: `translate(-50%, -50%)`,
            position: 'absolute',
        },

        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        maxHeight: "90vh",
        overflow: "scroll",
    },
}));


function TestSubmissions(props) {

    const {classes, userRules, heading} = props;
    const [openAlert, setOpenAlert] = useState(false);
    const [toastMessage, setToast] = useState("");
    const [collectionVisits, setCollectionVisits] = useState([]);
    const [selectedVisit, setSelectedVisit] = useState(null);
    const [status, setStatus] = useState(SAMPLE_STATUS[0].value);
    const [divisions, setDivisions] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(3);
    const [loading, setLoading] = useState(false);
    const [addReportModalOpen, setAddReportModalOpen] = React.useState(false);
    const [packageNumber, setPackageNumber] = useState(null);
    const [inspectionDate, setInspectionDate] = useState(null);


    const handleAlertClose = () => {
        setOpenAlert(false);
    }

    const handleModalClose = () => {
        setSelectedVisit(null);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearchInputPackageNumber = debounce(function (value) {
        setPackageNumber(value);
        setPage(0);
    }, 500)


    const handleSearchInspectionDate = function (value) {
        setInspectionDate(value);
        setPage(0);
    }

    const handleTabChange = (event, newValue) => {
        setStatus(newValue);
        setPage(0);
    };

    const getCollectionVisitParams = () => {
        let params = {
            status: 2,
            limit: rowsPerPage,
            offset: (page * rowsPerPage),
            includeSubmissionReports: true,
        }
        if (packageNumber) {
            params['packageNumber'] = packageNumber
        }
        //
        //
        // if (priority) {
        //     params['color'] = priority
        // }
        //
        // if (reportId) {
        //     params['reportId'] = reportId
        // }
        // if (division) {
        //     params['division'] = division
        // }
        if (inspectionDate && inspectionDate.isValid()) {
            params['inspectionDate'] = inspectionDate.format("DD/MM/YYYY")
        }
        return params;
    }

    const fetchVisits = () => {
        function handleInspectionReports(data) {
            setCollectionVisits(data);
            setLoading(false);
        }

        setLoading(true);
        fetchCollectionVisits(getCollectionVisitParams(), handleInspectionReports)
    }

    const updateStatus = ({collectionVisit, reqData}) => {
        let collectionVisitRepository = new CollectionVisitRepository();

        let data = {id: collectionVisit.id, ...reqData };

        collectionVisitRepository.updateCollectionVisit(collectionVisit.id, data).then(data => {
            setSelectedVisit(null)
            fetchVisits();
            setOpenAlert(true)
            setToast({type: "success", msg: "Collection Visit Updated!"});
        }).catch(e => {
            console.log("Error");
            setOpenAlert(true)
            setToast({type: "error", msg: e.message});
        })
    }

    useEffect(() => {
        function handleDivisions(data) {
            setDivisions(data);
        }

        fetchDivisions(handleDivisions)
    }, []);

    useEffect(() => {
        fetchVisits()
    }, [status, packageNumber, inspectionDate, page, rowsPerPage]);


    return (
        <Paper className={classes.paper}>
            <div className={classes.contentWrapper}>
                <Typography variant={"h5"} color="textPrimary" align="center" className={classes.title}>
                    {heading}
                </Typography>
            </div>
            <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
                <Toolbar>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            <SearchIcon className={classes.block} color="inherit"/>
                        </Grid>
                        <Grid item xs className={classes.search}>
                            <TextField
                                fullWidth
                                placeholder="Package Number"
                                type={"number"}
                                onChange={(e) => {
                                    const {value} = e.target;
                                    handleSearchInputPackageNumber(value);
                                }}
                                InputProps={{
                                    disableUnderline: true,
                                    className: classes.searchInput,
                                }}
                            />
                        </Grid>
                        {/*<Grid item>*/}
                        {/*    <SearchIcon className={classes.block} color="inherit"/>*/}
                        {/*</Grid>*/}
                        {/*<Grid item xs>*/}
                        {/*    <TextField*/}
                        {/*        fullWidth*/}
                        {/*        placeholder="Inspection Report Id"*/}
                        {/*        type={"number"}*/}
                        {/*        onChange={(e) => {*/}
                        {/*            const {value} = e.target;*/}
                        {/*            handleSearchInputReportId(value);*/}
                        {/*        }}*/}
                        {/*        InputProps={{*/}
                        {/*            disableUnderline: true,*/}
                        {/*            className: classes.searchInput,*/}
                        {/*        }}*/}
                        {/*    />*/}
                        {/*</Grid>*/}
                        <Grid item xs>
                            <KeyboardDatePicker
                                variant="inline"
                                inputVariant="outlined"
                                label="Inspection Date"
                                format="MM/DD/yyyy"
                                value={inspectionDate}
                                InputAdornmentProps={{position: "start"}}
                                onChange={date =>
                                    handleSearchInspectionDate(date)
                                }
                            />
                        </Grid>
                        <Grid item>
                            <Tooltip title="Reload">
                                <IconButton>
                                    <RefreshIcon className={classes.block} color="inherit" onClick={fetchVisits}/>
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <GridContainer>
                <GridItem>
                    {
                        loading ?
                            <Box
                                display="flex"
                                width={"100%"} height={100}
                                alignItems="center"
                                justifyContent="center"
                            >
                                <CircularProgress/>
                            </Box> :
                            collectionVisits && collectionVisits.rows && collectionVisits.rows.length ?
                                <>
                                    <TableContainer component={Paper}>
                                        <Table style={{fontSize: "18px"}} className={classes.table}
                                               aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell width={"5%"}/>
                                                    <TableCell width={"5%"}>Report Id</TableCell>
                                                    <TableCell width={"5%"}>Package No.</TableCell>
                                                    <TableCell width={"10%"}>Inspected On</TableCell>
                                                    <TableCell width={"10%"}>Inspected By</TableCell>
                                                    <TableCell width={"20%"}>Reports pertains to</TableCell>
                                                    <TableCell width={"20%"}>Site</TableCell>
                                                    <TableCell width={"15%"}>Remarks</TableCell>
                                                    {/*<TableCell width={"10%"}>Status</TableCell>*/}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>

                                                {
                                                    collectionVisits.rows.map((visit, index) =>
                                                        <CollectionVisitWithReports
                                                            fetchVisits={fetchVisits}
                                                            visit={visit}
                                                            divisions={divisions}
                                                            userRules={userRules}
                                                            setSelectedVisit={setSelectedVisit}
                                                            key={"visit" + index}
                                                        />)
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        rowsPerPageOptions={[3, 5, 10, 25]}
                                        component="div"
                                        count={collectionVisits.count || 0}
                                        rowsPerPage={rowsPerPage}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                        page={page}
                                    />
                                    <CollectedSamples
                                        userRules={userRules}
                                        visit={selectedVisit}
                                        open={!!selectedVisit}
                                        onClose={handleModalClose}
                                        updateStatus={updateStatus}
                                    />
                                </> :
                                <Box
                                    display="flex"
                                    width={"100%"} height={100}
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    No Reports
                                </Box>
                    }
                </GridItem>
            </GridContainer>
            <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleAlertClose}>
                <Alert onClose={handleAlertClose} severity={toastMessage.type}>
                    {toastMessage.msg}
                </Alert>
            </Snackbar>
        </Paper>
    );
}

TestSubmissions.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TestSubmissions);
