import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import {withStyles} from '@material-ui/core/styles';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import _ from 'lodash';

import {Link} from "react-router-dom";
import Button from "../../../../components/CustomButtons/Button";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Grid} from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import MinorRepository from "../../../../repository/minor-repository";
import GridContainer from "../../../../components/Grid/GridContainer";
import GridItem from "../../../../components/Grid/GridItem";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import RefreshIcon from "@material-ui/icons/Refresh";
import debounce from "@material-ui/core/utils/debounce";
import Controls from "../../../Components/controls/Controls";
import moment from "moment";

const styles = (theme) => ({
    paper: {
        // maxWidth: 1200,
        margin: 'auto',
        overflow: 'hidden',
    },
    searchBar: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    searchInput: {
        fontSize: theme.typography.fontSize,
    },
    block: {
        display: 'block',
    },
    addUser: {
        marginRight: theme.spacing(1),
    },
    contentWrapper: {
        margin: '20px 16px',
    },
    title: {
        padding: '20px',
    }
});

const fetchCount = (data = {}, callback) => {
    let minorRepo = new MinorRepository();
    minorRepo.getCount(data)
        .then(response => response.data)
        .then(data => {
            callback(data)
        })
        .catch(err => console.log(err));

}

const YEARS = Array.from({length: 10}, (v, k) => k + 2015).map((key) => {
    return {
        "value": key,
        "id": key,
        "name": key,
        "type": key,
    }
})

const MONTHS = moment.months().map((key, index) => {
    return {
        "value": index,
        "id": index,
        "name": key,
        "type": key,
    }
});


const types_pf_canal = [
    "IGF & IGMN",
    "ERM Works (Distribution System & Minors)",
]


function FinanceSummary(props) {
    const {classes, pack} = props;
    const [count, setCount] = useState({});
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState(null);
    const [axis, setAxis] = useState(null);
    const [month, setMonth] = useState(moment().month());
    const [year, setYear] = useState(moment().year());

    const handleMonth = debounce(function (value) {
        setMonth(value);
    }, 500)

    const handleYear = debounce(function (value) {
        setYear(value);
    }, 500)

    const {total_count, till_jul_result, till_aug_result, target_count} = count

    const renderData = (data) => {
        if (!data) return 0;
        else return parseFloat(data).toFixed(3);
    }
    const sum = (data1, data2) => {
        const a = data1 || 0;
        const b = data2 || 0;
        return (parseFloat(a) + parseFloat(b)).toFixed(3)

    }
    const inKm = (data) => {
        return parseFloat(data * 0.3048).toFixed(3)
    }

    const getParams = () => {
        let params = {}
        if (month) {
            params['month'] = month;
        }

        if (year) {
            params['year'] = year;
        }

        return params;

    }

    useEffect(() => {

        function handleCount(data) {
            setCount(data);
            // let {xAxis, yAxis} = cookDataForGraph(data);
            // let options = getOptions(xAxis, yAxis);
            // setOptions(options);
            // setAxis({xAxis, yAxis})
            setLoading(false);
        }

        setLoading(true);
        fetchCount(getParams(), handleCount)
    }, [month, year]);

    const date = new moment().month(month).year(year);
    const lastMonth = new moment(date);
    lastMonth.subtract(1, "months")

    return (
        <Paper className={classes.paper}>
            <div className={classes.contentWrapper}>
                <Typography variant={"h5"} color="textPrimary" align="center">
                    Summary of Finance Progres
                </Typography>
            </div>
                <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
                    <Toolbar>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs>
                                <Controls.Select
                                    name="month"
                                    label="Month"
                                    value={month}
                                    style={{width: "100%"}}
                                    onChange={(e) => {
                                        handleMonth(e.target.value)
                                    }}
                                    options={MONTHS}
                                />
                            </Grid>
                            <Grid item xs>
                                <Controls.Select
                                    name="year"
                                    label="Year"
                                    value={year}
                                    style={{width: "100%"}}
                                    onChange={(e) => {
                                        handleYear(parseInt(e.target.value))
                                    }}
                                    options={YEARS}
                                />
                            </Grid>
                            {/*<Grid item xs>*/}
                            {/*    <Controls.Select*/}
                            {/*        name="status"*/}
                            {/*        label="Status"*/}
                            {/*        value={status}*/}
                            {/*        style={{width: "100%"}}*/}
                            {/*        onChange={(e) => {*/}
                            {/*            handleStatus(e.target.value)*/}
                            {/*        }}*/}
                            {/*        options={Object.values(STATUSES)}*/}
                            {/*    />*/}
                            {/*</Grid>*/}
                            <Grid item>
                                <Tooltip title="Reload">
                                    <IconButton>
                                        {/*<RefreshIcon className={classes.block} color="inherit" onClick={fetchVisits}/>*/}
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
                <GridContainer>
                    <GridItem>
                        {

                            <Box alignItems={"center"} width={"100%"}>
                                {
                                    loading ?
                                        <CircularProgress/> :
                                        count ?
                                            <>
                                                {
                                                    count && count.target_count ?
                                                        <>
                                                            <GridContainer justify={"center"} id={"divToPrint"}>
                                                                <GridItem xs={12} sm={12} md={12}>
                                                                    {/* <TableContainer component={Paper}>
                                                                        <Table stickyHeader style={{fontSize: "18px"}}
                                                                               className={classes.table}
                                                                               aria-label="simple table">
                                                                            <TableHead>
                                                                                <TableRow>
                                                                                    <TableCell>Type of Canal</TableCell>
                                                                                    <TableCell>Unit</TableCell>
                                                                                    <TableCell>Scope of Work Under
                                                                                        RWSRPD</TableCell>
                                                                                    <TableCell>Target as per Contract
                                                                                        Agreement</TableCell>
                                                                                    <TableCell>Achievement Upto {lastMonth.format("MMMM YYYY")}</TableCell>
                                                                                    <TableCell>Achievement During the
                                                                                        Reporting
                                                                                        Month {date.format("MMMM YYYY")}</TableCell>
                                                                                    <TableCell>Cumulative Achievement
                                                                                        Upto {date.format("MMMM YYYY")}</TableCell>
                                                                                </TableRow>
                                                                            </TableHead>
                                                                            <TableBody>
                                                                                {
                                                                                    types_pf_canal.map((type, index) => {
                                                                                        const till_jul = renderData(till_jul_result ? till_jul_result[type] : 0);
                                                                                        const till_aug = renderData(till_aug_result ? till_aug_result[type] : 0)
                                                                                        return <TableRow>
                                                                                            <TableCell
                                                                                                width={"20%"}>{type}</TableCell>
                                                                                            <TableCell
                                                                                                width={"5%"}>RD</TableCell>
                                                                                            <TableCell
                                                                                                width={"15%"}>{renderData(total_count && total_count.length ? total_count[index].total_target_rd : 0)}</TableCell>
                                                                                            <TableCell
                                                                                                width={"15%"}>{renderData(target_count && target_count.length ? target_count[index].total_target_rd : 0)}</TableCell>
                                                                                            <TableCell
                                                                                                width={"15%"}>{till_jul}</TableCell>
                                                                                            <TableCell width={"15%"}>{
                                                                                                renderData(till_aug - till_jul)
                                                                                            }</TableCell>
                                                                                            <TableCell
                                                                                                width={"15%"}>{till_aug}</TableCell>
                                                                                        </TableRow>
                                                                                    })
                                                                                }
                                                                            </TableBody>
                                                                        </Table>
                                                                        <Table stickyHeader style={{fontSize: "18px"}}
                                                                               className={classes.table}
                                                                               aria-label="simple table">
                                                                            <TableHead>
                                                                                <TableRow>
                                                                                    <TableCell width={"25%"}>Sub Total
                                                                                        (in
                                                                                        RD)</TableCell>
                                                                                    <TableCell
                                                                                        width={"15%"}>{total_count && total_count.length ? sum(renderData(total_count[0].total_target_rd), renderData(total_count[1].total_target_rd)) : "-"}</TableCell>
                                                                                    <TableCell
                                                                                        width={"15%"}>{target_count && target_count.length ? sum(renderData(target_count[0].total_target_rd), renderData(target_count[1].total_target_rd)) : "-"}</TableCell>
                                                                                    <TableCell
                                                                                        width={"15%"}>{till_jul_result ? sum(renderData(till_jul_result[types_pf_canal[0]]), renderData(till_jul_result[types_pf_canal[1]])) : "-"}</TableCell>
                                                                                    <TableCell
                                                                                        width={"15%"}>{renderData((till_aug_result ? sum(renderData(till_aug_result[types_pf_canal[0]]), renderData(till_aug_result[types_pf_canal[1]])) : 0) - (till_jul_result ? sum(renderData(till_jul_result[types_pf_canal[0]]), renderData(till_jul_result[types_pf_canal[1]])) : 0))}</TableCell>
                                                                                    <TableCell
                                                                                        width={"15%"}>{till_aug_result ? sum(renderData(till_aug_result[types_pf_canal[0]]), renderData(till_aug_result[types_pf_canal[1]])) : "-"}</TableCell>
                                                                                </TableRow>
                                                                                <TableRow>
                                                                                    <TableCell width={"25%"}>Sub Total
                                                                                        (in
                                                                                        KM)</TableCell>
                                                                                    <TableCell
                                                                                        width={"15%"}>{total_count && total_count.length ? inKm(sum(renderData(total_count[0].total_target_rd), renderData(total_count[1].total_target_rd))) : "-"}</TableCell>
                                                                                    <TableCell
                                                                                        width={"15%"}>{target_count && target_count.length ? inKm(sum(renderData(target_count[0].total_target_rd), renderData(target_count[1].total_target_rd))) : "-"}</TableCell>
                                                                                    <TableCell
                                                                                        width={"15%"}>{till_jul_result ? inKm(sum(renderData(till_jul_result[types_pf_canal[0]]), renderData(till_jul_result[types_pf_canal[1]]))) : "-"}</TableCell>
                                                                                    <TableCell
                                                                                        width={"15%"}>{inKm((till_aug_result ? sum(renderData(till_aug_result[types_pf_canal[0]]), renderData(till_aug_result[types_pf_canal[1]])) : 0) - (till_jul_result ? sum(renderData(till_jul_result[types_pf_canal[0]]), renderData(till_jul_result[types_pf_canal[1]])) : 0))}</TableCell>
                                                                                    <TableCell
                                                                                        width={"15%"}>{till_aug_result ? inKm(sum(renderData(till_aug_result[types_pf_canal[0]]), renderData(till_aug_result[types_pf_canal[1]]))) : "-"}</TableCell>
                                                                                </TableRow>
                                                                            </TableHead>
                                                                        </Table>
                                                                    </TableContainer> */}
                                                                </GridItem>
                                                            </GridContainer>
                                                        </> : null
                                                }
                                                <Grid style={{
                                                    width: "100%",
                                                    display: "flex",
                                                    justifyContent: "space-around",
                                                    marginTop: "20px",
                                                }}>
                                                    {/*<Button*/}
                                                    {/*    color={"info"}*/}
                                                    {/*    href={"/dashboard/inspection/tpqc/"}*/}
                                                    {/*>*/}
                                                    {/*    Show TPQC Inspection Reports*/}
                                                    {/*</Button>*/}
                                                </Grid>
                                            </> : null
                                }
                            </Box>
                        }
                        {/*<GridContainer justify={"center"}>*/}
                        {/*    <GridItem xs={12} sm={12} md={12}>*/}
                        {/*        <TableContainer component={Paper}>*/}
                        {/*            <Table className={classes.table} aria-label="simple table">*/}
                        {/*                <TableHead>*/}
                        {/*                    <TableRow>*/}
                        {/*                        <TableCell>Submitted Reports</TableCell>*/}
                        {/*                        <TableCell>Pending Reports</TableCell>*/}
                        {/*                        <TableCell>Accepted Reports</TableCell>*/}
                        {/*                    </TableRow>*/}
                        {/*                </TableHead>*/}
                        {/*                <TableBody>*/}

                        {/*                    <TableRow hover>*/}
                        {/*                        <TableCell>10</TableCell>*/}
                        {/*                        <TableCell component="th" scope="row">5</TableCell>*/}
                        {/*                        <TableCell component="th" scope="row">5</TableCell>*/}
                        {/*                    </TableRow>*/}
                        {/*                </TableBody>*/}
                        {/*            </Table>*/}
                        {/*        </TableContainer>*/}
                        {/*    </GridItem>*/}
                        {/*</GridContainer>*/}
                    </GridItem>
                </GridContainer>
        </Paper>
    );
}

FinanceSummary.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FinanceSummary);