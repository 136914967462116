import Modal from "@material-ui/core/Modal";
import React, {useEffect, useState} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {SAMPLE_STATUS_KEY_VALUE} from "../../../../models/sample";
import {Grid} from "@material-ui/core";
import Button from "../../../../components/CustomButtons/Button";
import Box from "@material-ui/core/Box";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from "@material-ui/core/TextField";
import {hasRule, RULES_CONSTANTS} from "../../../../models/user";
import CollectionVisitRepository from "../../../../repository/collection-visit-repository";

const useStyles = makeStyles((theme) => ({
    paper: {
        [theme.breakpoints.between("sm", "md")]: {
            marginTop: theme.spacing(3),
        },
        "@media (min-width: 1280px)": {
            width: 1000,
            top: `50%`,
            left: `50%`,
            transform: `translate(-50%, -50%)`,
            position: 'absolute',
        },

        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        maxHeight: "90vh",
        overflow: "scroll",
    },
    radio: {
        fontSize: "14px"
    }
}));

const fetchCollectionVisit = (id, params, callback) => {
    let collectionVisitRepository = new CollectionVisitRepository();
    collectionVisitRepository.getCollectionVisit(id, params)
        .then(response => response.data)
        .then(data => {
            callback(data)
        })
        .catch(err => {
                callback([]);
            }
        )
}



export default function CollectedSamples(props) {
    let {open, onClose, visit, userRules, updateStatus} = props;
    const modalClasses = useStyles();
    const [collecVisit, setCollecVisit] = useState({})
    const samples = collecVisit && collecVisit.collectedSamples ? collecVisit.collectedSamples : [];
    const [values, setValues] = useState(samples.reduce((acc, cur) => ({...acc, [cur.id]: null}), {}))
    let hasPermissionToUpdate = hasRule(userRules, RULES_CONSTANTS.CAN_ACCEPT_TESTING_SAMPLES)
    let hasPermissionToSubmit = hasRule(userRules, RULES_CONSTANTS.CAN_COLLECT_TESTING_SAMPLES)
    const CAN_UPDATE = hasPermissionToUpdate && collecVisit && collecVisit.status === 1;
    const CAN_SUBMIT = hasPermissionToSubmit && collecVisit && collecVisit.status === 0;


    useEffect(() => {
        if(visit && visit.id) {
            fetchCollectionVisit(visit.id, {id: visit.id}, (data) => {
                setCollecVisit(data)
            })
        } else {
            setCollecVisit(null)
        }
    }, [visit])

    const setStatus = (id, value, label) => {
        setValues({...values, [id]: {...values[id], [label]: value}})
    }

    const getCol = (sample, columnName, label) => {
        return sample[columnName] ? (
            <div>
                <span style={{fontWeight: "bold"}}>{label}</span>
                <span>{sample[columnName]}</span>
            </div>
        ) : ""
    }

    if (!collecVisit || !visit) {
        return null
    }


    return <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
    >
        <div className={modalClasses.paper}>
            <h2>Samples Collected</h2>
            <Table size="small" aria-label="compliance visit">
                <TableHead>
                    <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell>Sample Type</TableCell>
                        {/*<TableCell>Tests</TableCell>*/}
                        {/*<TableCell>Remarks</TableCell>*/}
                        {/*<TableCell>Created By</TableCell>*/}
                        <TableCell>Status</TableCell>
                        {
                            CAN_UPDATE ? <>
                                <TableCell>Update Status</TableCell>
                                <TableCell>Remarks</TableCell>
                            </> : null
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {samples.map((collectedSample, index) => {
                        let sample = collectedSample.sample
                        const tests = collectedSample && collectedSample.testReports ? collectedSample.testReports : [];
                        return (
                            <TableRow key={sample.id + "sample" + index}>
                                <TableCell
                                    style={{borderBottom: "none"}}
                                    component="th"
                                    scope="row"
                                >
                                    {collectedSample.id}
                                </TableCell>
                                <TableCell
                                    style={{borderBottom: "none"}}
                                    component="th"
                                    scope="row"
                                >
                                    {sample.name}
                                </TableCell>
                                {/*<TableCell*/}
                                {/*    style={{borderBottom: "none"}}>*/}
                                {/*    {*/}
                                {/*        compRep.compliance_visit ?*/}
                                {/*            <Button*/}
                                {/*                onClick={(e) => {*/}
                                {/*                    e.stopPropagation();*/}
                                {/*                    window.location.href = compRep.compliance_visit;*/}
                                {/*                }}*/}
                                {/*                size={"sm"}*/}
                                {/*                color={"info"}*/}
                                {/*                download*/}
                                {/*            >*/}
                                {/*                <CloudDownload/> Download*/}
                                {/*            </Button> : "-"*/}
                                {/*    }*/}
                                {/*</TableCell>*/}
                                {/*<TableCell*/}
                                {/*    style={{borderBottom: "none"}}*/}
                                {/*>*/}
                                {/*    {compRep.remarks || "-"}*/}
                                {/*</TableCell>*/}
                                {/*<TableCell*/}
                                {/*    style={{borderBottom: "none", width: "45%"}}*/}
                                {/*>*/}
                                {/*    <ul style={{padding: 0, fontSize: "14px"}}>*/}
                                {/*        {tests.map(({test}, i) => <li key={test.name + "" + i}>{test.name}</li>)}*/}
                                {/*    </ul>*/}
                                {/*</TableCell>*/}
                                <TableCell
                                    style={{borderBottom: "none", width: "35%"}}
                                >
                                    <div>{SAMPLE_STATUS_KEY_VALUE[collectedSample.status]}</div>
                                    <div style={{fontSize: "14px", display: "flex", flexDirection: "column"}}>
                                        {
                                            collecVisit.status > 0 ? getCol(collectedSample, "submitted_by", "Submitted By: ") : null
                                        }
                                        {
                                            collecVisit.status > 1 ? <>
                                                {getCol(collectedSample, "received_by", "Received By: ")}
                                                {getCol(collectedSample, "remarks", "Remarks: ")}
                                            </> : null
                                        }
                                    </div>
                                </TableCell>
                                {
                                    CAN_UPDATE ?
                                        <>
                                            <TableCell
                                                style={{borderBottom: "none", fontSize: "14px", padding: 0}}
                                            >
                                                <FormControl component="fieldset">
                                                    {/*<FormLabel component="legend">Sample Status</FormLabel>*/}
                                                    <RadioGroup
                                                        row
                                                        aria-label="gender"
                                                        name="gender1"
                                                        value={values && values[collectedSample.id] ? values[collectedSample.id].status : ""}
                                                        onChange={(event) => {
                                                            setStatus(collectedSample.id, event.target.value, "status")
                                                        }}>
                                                        <FormControlLabel className={modalClasses.radio} value="2"
                                                                          control={<Radio/>} label="Sample Accepted"/>
                                                        <FormControlLabel className={modalClasses.radio} value="3"
                                                                          control={<Radio/>} label="Sample Rejected"/>
                                                    </RadioGroup>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell
                                                style={{borderBottom: "none", width: "20%"}}
                                            >
                                                <FormControl component="fieldset">
                                                    <TextField
                                                        required={(values && values[collectedSample.id] && values[collectedSample.id].status === "3")}
                                                        value={values && values[collectedSample.id] ? values[collectedSample.id].remark : ""}
                                                        onChange={(event) => {
                                                            setStatus(collectedSample.id, event.target.value, "remark")
                                                        }}
                                                        disabled={!(values && values[collectedSample.id] && values[collectedSample.id].status === "3")}
                                                        id="standard-basic"
                                                        label="Enter Remarks here"/>
                                                </FormControl>
                                            </TableCell> </> : null
                                }
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
            {
                CAN_SUBMIT ?
                    <Button
                        size={"sm"}
                        color={"success"}
                        onClick={e => {
                            e.stopPropagation()
                            updateStatus({reqData: {reqType: 1, visitStatus: 1}, collectionVisit: visit})
                        }}
                    >
                        Submit Collected Samples
                    </Button> : "-"
            }
            {
                CAN_UPDATE ?
                    <Button
                        size={"sm"}
                        color={"success"}
                        onClick={e => {
                            e.stopPropagation()
                            updateStatus({
                                reqData: {samples: values, reqType: 2, visitStatus: 2},
                                collectionVisit: visit
                            })
                        }}
                    >
                        Accept Submitted Samples
                    </Button> : null
            }
        </div>
    </Modal>
}