import React, {useState, useEffect} from 'react'
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import makeStyles from "@material-ui/core/styles/makeStyles";
import yellow from "@material-ui/core/colors/yellow";
import {SAMPLE_STATUS_KEY_VALUE} from "../../../../models/sample";
import Button from "../../../../components/CustomButtons/Button";

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    color_green: {
        color: "green",
    },
    color_red: {
        color: "red",
    },
    color_yellow: {
        color: yellow["700"],
    },
    fontSmall: {
        fontSize: "16px"
    }
});


export default function Minor(props) {
    const {
        canal,
        divisions,
        setSelectedVisit,
        STATUSES,
    } = props;
    const classes = useRowStyles();
    const pack = canal.pack;


    return (
        <React.Fragment key={canal.id + "_visit"}>
            <TableRow className={classes.root} hover>
                <TableCell align={"right"} width={"5%"}>
                    <strong>{canal.id}.</strong>
                </TableCell>
                <TableCell align={"right"} width={"5%"}>
                    <strong>{pack.packageId}</strong>
                </TableCell>
                <TableCell className={classes.fontSmall} width={"20%"}>{canal.name}</TableCell>
                <TableCell className={classes.fontSmall} width={"15%"}>{canal.branch_system}</TableCell>
                {/*<TableCell width={"10%"}>{visit.created_by}</TableCell>*/}
                <TableCell className={classes.fontSmall} width={"15%"}>
                    <div style={{padding: 0}}>
                        <div key={"rd1"}>
                            <span>{"Target (in kms): "} </span>
                            <span> {parseFloat(canal.target_kms).toFixed(3)}</span>
                        </div>
                        <div key={"rd2"}>
                            <span>{"Target (in RD): "} </span>
                            <span> {parseFloat(canal.target_rd).toFixed(3)}</span>
                        </div>
                    </div>
                </TableCell>
                <TableCell className={classes.fontSmall} width={"15%"}>
                    <div style={{padding: 0}}>
                        <div key={"km1"}>
                            <span>{"Start Date: "} </span>
                            <span> {canal.textual_start_date}</span>
                        </div>
                        <div key={"km2"}>
                            <span>{"End Date: "} </span>
                            <span> {canal.textual_end_date}</span>
                        </div>
                    </div>
                </TableCell>
                <TableCell className={classes.fontSmall} width={"10%"}>{STATUSES[parseInt(canal.status)].name}</TableCell>
                {/*<TableCell width={"10%"}>{"Tranche " + canal.tranche}</TableCell>*/}
                <TableCell width={"10%"}>
                    <Button
                        size={"sm"}
                        color={"success"}
                        href={"/dashboard/progress/minor/" + canal.id}
                    >
                        Show Details
                    </Button>
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}
