import InspectionReportController from '../controllers/inspection-report-controller.js';

class InspectionReportRepository {
    getInspectionReports(data) {
        let inspectionFormController = new InspectionReportController();
        return inspectionFormController.getInspectionReports({
            data: data,
        });
    }
    submitInspectionForm(data) {
        for (const pair of data.entries()) {
          }
        let inspectionFormController = new InspectionReportController();
        return inspectionFormController.postInspectionForm({
            data: data,
        });
    }

}

export default InspectionReportRepository;
