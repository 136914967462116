import React, {useState, useEffect} from 'react'
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import makeStyles from "@material-ui/core/styles/makeStyles";
import yellow from "@material-ui/core/colors/yellow";
import {SAMPLE_STATUS_KEY_VALUE} from "../../../../models/sample";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import MinorRepository from "../../../../repository/minor-repository";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import Package from "./Package";
import TableContainer from "@material-ui/core/TableContainer";
import _ from 'lodash';
import Grid from "@material-ui/core/Grid";
import {jsPDF} from "jspdf";
import html2canvas from "html2canvas";
import {CloudDownload} from "@material-ui/icons";
import GridContainer from "../../../../components/Grid/GridContainer";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import InspectionForm from "../Inspection/InspectionForm";
import AddProgressLengthForm from "./AddProgressLengthForm";
import moment from "moment";
import {hasRule, RULES_CONSTANTS} from "../../../../models/user";
import Chip from "@material-ui/core/Chip";


const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    color_green: {
        color: "green",
    },
    color_red: {
        color: "red",
    },
    color_yellow: {
        color: yellow["700"],
    },
    fontSmall: {
        fontSize: "14px"
    }
});

const styles = (theme) => ({
    paper: {
        // maxWidth: 1200,
        margin: 'auto',
        overflow: 'hidden',
    },
    searchBar: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    searchInput: {
        fontSize: theme.typography.fontSize,
    },
    block: {
        display: 'block',
    },
    addUser: {
        marginRight: theme.spacing(1),
    },
    contentWrapper: {
        margin: '20px 16px',
    },
    title: {
        padding: '20px',
    },
    search: {
        borderRight: "1px solid lightgray",
    }
});

const STATUSES = {
    0: {"value": "0", "id": "0", "name": "Not Tendered"},
    1: {"value": "1", "id": "1", "name": "Awarded not started"},
    2: {"value": "2", "id": "2", "name": "In Progress"},
    3: {"value": "3", "id": "3", "name": "Completed"},
}
const getTimeOptions = (data) => {
    return {
        chart: {
            type: 'spline'
        },
        title: {
            text: 'Month-wise comparision'
        },
        xAxis: {
            type: 'datetime',
            title: {
                text: 'Date'
            }
        },
        yAxis: {
            title: {
                text: 'Monthly Physical Progress'
            },
            min: 0
        },
        tooltip: {
            headerFormat: '<b>{series.name}</b><br>',
            pointFormat: '{point.x:%b %y}: {point.y:.2f}'
        },

        plotOptions: {
            series: {
                marker: {
                    enabled: true
                }
            }
        },

        // colors: ['#6CF', '#39F', '#06C', '#036', '#000'],

        series: data,

        responsive: {
            rules: [{
                condition: {
                    maxWidth: 500
                },
                chartOptions: {
                    plotOptions: {
                        series: {
                            marker: {
                                radius: 2.5
                            }
                        }
                    }
                }
            }]
        }
    }
}


const getOptions = (xAxis = [], yAxis = [], title) => {
    const options = {
        chart: {
            type: 'bar'
        },
        title: {
            text: title,
            style: {
                fontSize: "24px"
            }
        },
        xAxis: {
            categories: xAxis,
            title: {
                text: null
            },
            labels: {
                formatter() {
                    return `<span style="font-weight: bold; color: black">${this.value}</span>`
                },
                style: {
                    fontSize: "14px"
                },
            }
        },
        yAxis: {
            min: 0,
            title: {
                text: 'Length(in RD)',
                align: 'high'
            },
            labels: {
                overflow: 'justify'
            }
        },
        tooltip: {
            valueSuffix: ' RD'
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    enabled: true
                }
            }
        },
        legend: {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'top',
            x: -40,
            y: 80,
            floating: true,
            borderWidth: 1,
            backgroundColor:
                Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
            shadow: true
        },
        credits: {
            enabled: false
        },
        series: yAxis,
    }

    return options
}


const useStyles = makeStyles((theme) => ({
    paper: {
        [theme.breakpoints.between("sm", "md")]: {
            marginTop: theme.spacing(3),
        },
        "@media (min-width: 1280px)": {
            width: 1000,
            top: `50%`,
            left: `50%`,
            transform: `translate(-50%, -50%)`,
            position: 'absolute',
        },

        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        maxHeight: "90vh",
        overflow: "scroll",
    },
    radio: {
        fontSize: "14px"
    },
}));

const fetchMinor = (id, params, callback) => {
    let minorRepository = new MinorRepository();
    minorRepository.getMinor(id, params)
        .then(response => response.data)
        .then(data => {
            callback(data)
        })
        .catch(err => {
                console.log(err);
                callback([]);
            }
        )
}
const BarGraph = ({xAxis, yAxis, title}) => {
    return <HighchartsReact
        highcharts={Highcharts}
        options={getOptions(xAxis, yAxis, title)}
    />
}

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}


function PackagePhysicalProgress(props) {
    const {classes, userRules, heading, ...rest} = props;
    const [modalStyle] = React.useState(getModalStyle);
    const [minors, setMinors] = useState(null)
    const [pack, setPack] = useState(null)
    const [loading, setLoading] = useState(false)
    const packId = props.match.params.packId;
    const [openAlert, setOpenAlert] = useState(false);
    const [toastMessage, setToast] = useState({});
    const [processedData, setProcessedData] = useState(null);
    const [groupedData, setGroupedData] = useState(null);
    const [timeOptions, setTimeOptions] = useState(null);
    const modalClasses = useStyles();
    const contractors = pack ? pack.contractors : [{}];
    const contractor = contractors[0];
    const [addProgressModalOpen, setAddProgressModalOpen] = React.useState(false);
    const CAN_ADD_PROGRESS = pack && parseInt(pack.status) === 2;

    const handleAddReportModalClose = (fetch) => {
        setAddProgressModalOpen(false);
        if (fetch && typeof fetch === "boolean") {
            fetchAllMinors();
        }
    };


    const handleAlertClose = () => {
        setOpenAlert(false);
    }

    const getPDF = () => {
        const input = document.getElementById('divToPrint');
        const dimensions = input.getBoundingClientRect()
        let HTML_Width = dimensions.width;
        let HTML_Height = dimensions.height;
        let top_left_margin = 15;
        let PDF_Width = HTML_Width + (top_left_margin * 2);
        let PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
        let canvas_image_width = HTML_Width;
        let canvas_image_height = HTML_Height;

        let totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;


        html2canvas(input, {allowTaint: true}).then(function (canvas) {
            canvas.getContext('2d');

            let imgData = canvas.toDataURL("image/jpeg", 1.0);
            let pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);

            pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);


            for (let i = 1; i <= totalPDFPages; i++) {
                pdf.addPage([PDF_Width, PDF_Height]);
                pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height * i) + (top_left_margin * 4), canvas_image_width, canvas_image_height);
            }

            pdf.save(`Physical_progress${pack ? "-" + pack.packageId : ""}-${new Date().toDateString()}.pdf`);
        });
    }


    const fetchAllMinors = () => {
        let id = parseInt(packId)

        function handleMinor({canals, pack}) {
            setMinors(canals)
            setPack(pack)
            setGroupedData(_.groupBy(canals, "branch_system"))
            setProcessedData({
                target_length_rd: canals.reduce((previousValue, canal) => previousValue + parseFloat(canal.target_rd), 0),
                target_length_kms: canals.reduce((previousValue, canal) => previousValue + parseFloat(canal.target_kms), 0),
                achieved_length_rd: canals.reduce((previousValue, canal) => previousValue + (canal.physicals && canal.physicals.length ? parseFloat(canal.physicals[0].progress_rd) : 0), 0),
                achieved_length_kms: canals.reduce((previousValue, canal) => previousValue + (canal.physicals && canal.physicals.length ? parseFloat(canal.physicals[0].progress_kms) : 0), 0),
            })
            setTimeOptions(getTimeOptions(
                canals.map((comp) => {
                    return {
                        name: comp.name,
                        data: comp.physicals.map((milestone) => {
                            return [(new moment(milestone.achieved_by)).valueOf(), milestone.progress_rd]
                        })
                    }
                })
            ))
            setLoading(false);
        }

        setLoading(true);
        fetchMinor(id, {}, handleMinor)
    }

    const renderData = (data, precision = 3) => {
        if (!data) return "-"
        else return parseFloat(data).toFixed(precision);
    }


    useEffect(() => {
        let id = parseInt(packId)
        if (!isNaN(id) && !loading && !minors) {
            fetchAllMinors()
        }
    }, [])


    return (
        <Paper className={classes.paper}>
            <div className={classes.contentWrapper}>
                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingBottom: "20px"
                }}>
                    <div style={{width: "30%", alignItems: "center", display: "flex"}}>
                        <Button
                            style={{marginLeft: 20}}
                            href={"/dashboard/progress/physical/milestone-wise/" + packId}
                            variant={"outlined"}
                            color={"primary"}
                        >
                            Show Milestone Data
                        </Button>
                    </div>
                    <div>
                        <Typography variant={"h5"} color="textPrimary" align="center" style={{marginRight: "20px"}}>
                            {heading}
                        </Typography>
                    </div>
                    <div style={{width: "30%", display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
                        {
                            CAN_ADD_PROGRESS && hasRule(userRules, RULES_CONSTANTS.CAN_ADD_PHYSICAL_PROGRESS) ?
                                <Button
                                    style={{marginRight: 20}}
                                    variant={"outlined"}
                                    onClick={() => {
                                        setAddProgressModalOpen(true)
                                    }}
                                    color={"primary"}
                                >
                                    Add Progress
                                </Button> : null
                        }
                        <Button
                            variant={"outlined"}
                            style={{marginRight: 20}}
                            onClick={getPDF}
                            color={"primary"}
                        >
                            <CloudDownload style={{marginRight: "5px"}}/> Download Report
                        </Button>
                    </div>
                </div>
                <div id={"divToPrint"}>
                    {
                        pack ?
                            <TableContainer component={Paper}>
                                <Table style={{fontSize: "14px"}} className={classes.table}
                                       aria-label="simple table">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell variant={"head"}>Name of Work</TableCell>
                                            <TableCell>{pack.name}</TableCell>
                                            <TableCell><Chip label={pack ? STATUSES[parseInt(pack.status)].name : "-"}
                                                             variant={"outlined"} color={"secondary"}/>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell variant={"head"}>Contractor's Name</TableCell>
                                            <TableCell>{contractor && contractor.name || "-"}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell variant={"head"}>Package No.</TableCell>
                                            <TableCell>{pack.packageId}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell variant={"head"}>Contract No.</TableCell>
                                            <TableCell>{contractor && contractor.agreement_no || "-"}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell variant={"head"}>Total Length Completed/ Achieved</TableCell>
                                            <TableCell>
                                                <div>{processedData && renderData(processedData.achieved_length_rd)} (In
                                                    RD)
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell variant={"head"}>Total Length/Target as per Agreement</TableCell>
                                            <TableCell>{processedData && renderData(processedData.target_length_rd)} (In
                                                RD)</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell variant={"head"}>% of Work Completed</TableCell>
                                            <TableCell>{processedData && renderData(processedData.achieved_length_rd * 100 / processedData.target_length_rd, 2)} %</TableCell>
                                        </TableRow>
                                        {/*<TableRow>*/}
                                        {/*    <TableCell>% of Work Completed</TableCell>*/}
                                        {/*    <TableCell>{"-"}</TableCell>*/}
                                        {/*</TableRow>*/}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            : null
                    }
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={timeOptions}
                    />
                    {
                        groupedData && Object.keys(groupedData).length ? Object.keys(groupedData).map((key) => {
                            // return <Grid item alignItems="center" key={key}>
                            {/*<Grid item>*/
                            }
                            {/*<Typography variant={"h5"} color="textPrimary" align="center"*/
                            }
                            {/*            className={classes.title}>{key}</Typography>*/
                            }
                            {/*{*/
                            }
                            {/*    groupedData[key].map((minor) => {*/
                            }
                            {/*        return (*/
                            }
                            {/*            <Grid item alignItems="center" key={minor.name}>*/
                            }
                            {/*                <Typography variant={"h6"} color="textPrimary" align="center" className={classes.title}>*/
                            }
                            {/*                    {minor.name}*/
                            }
                            {/*                </Typography>*/
                            }
                            {/*                <Typography variant={"body1"} color="textPrimary" align="center" className={classes.title}>*/
                            }
                            {/*                    Progress: {minor.physicals[0].progress_rd}*/
                            }
                            {/*                </Typography>*/
                            }
                            {/*                <Typography variant={"body1"} color="textPrimary" align="center" className={classes.title}>*/
                            }
                            {/*                    Target: {minor.target_rd}*/
                            }
                            {/*                </Typography>*/
                            }
                            {/*                <Typography variant={"body1"} color="textPrimary" align="center" className={classes.title}>*/
                            }
                            {/*                    {minor.status}*/
                            }
                            {/*                </Typography>*/
                            }
                            {/*            </Grid>*/
                            }
                            {/*        )*/
                            }
                            {/*    })*/
                            }
                            {/*}*/
                            }
                            return <BarGraph
                                key={"graph_" + key}
                                xAxis={groupedData[key].map((minor) => (minor.name + " (" + STATUSES[parseInt(minor.status)].name) + ")")}
                                yAxis={[
                                    {
                                        name: "Progress of Lining work",
                                        data: groupedData[key].map((minor) => {
                                            return minor.physicals && minor.physicals.length ? parseFloat(minor.physicals[0].progress_rd) : 0
                                        })
                                    },
                                    {
                                        name: "Target of Lining work",
                                        data: groupedData[key].map((minor) => parseFloat(minor.target_rd))
                                    }
                                ]}
                                title={key}
                            />
                            // </Grid>
                        }) : null
                    }
                </div>
            </div>
            {/*</Grid>*/}

            <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleAlertClose}>
                <Alert onClose={handleAlertClose} severity={toastMessage.type}>
                    {toastMessage.msg}
                </Alert>
            </Snackbar>

            <Modal
                open={addProgressModalOpen}
                onClose={handleAddReportModalClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div style={modalStyle} className={modalClasses.paper}>
                    <AddProgressLengthForm
                        packId={packId}
                        canals={minors}
                        setOpenAlert={setOpenAlert}
                        setToast={setToast}
                        handleClose={handleAddReportModalClose}/>
                </div>
            </Modal>

        </Paper>
    )
}

PackagePhysicalProgress.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PackagePhysicalProgress);
