import React, {useState} from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import ReCAPTCHA from "react-google-recaptcha";

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-react/views/landingPage.js";

import LeftLinks from "../../components/Header/LeftLinks";
import {Form, useForm} from "../Components/useForm";
import {Grid} from "@material-ui/core";
import Controls from "../Components/controls/Controls";
import InspectionReportRepository from "../../repository/inspection-report-repository";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Paper from "@material-ui/core/Paper";
import ComplaintRepository from "../../repository/complaint-repository";
import CustomLinearProgress from "../../components/CustomLinearProgress/CustomLinearProgress";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

const initialFValues = {
    inspectionFile: [],
    emailAddress: "",
    name: "",
    number: "",
    description: "",
}


export default function ComplaintsPage(props) {
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [loading, setLoading] = useState(false);
    const [captcha, setCaptcha] = useState("");
    const [openAlert, setOpenAlert] = useState(false);
    const [toastMessage, setToast] = useState("");

    const handleAlertClose = () => {
        setOpenAlert(false);
    }


    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const classes = useStyles();
    const validate = (fieldValues = values) => {
        let temp = {...errors}
        if ('inspectionOn' in fieldValues)
            temp.inspectionOn = fieldValues.inspectionOn ? "" : "This field is required."
        if ('inspectionFile' in fieldValues)
            temp.inspectionFile = fieldValues.inspectionFile ? "" : "This field is required."
        setErrors({
            ...temp
        })

        if (fieldValues == values)
            return Object.values(temp).every(x => x == "")
    }

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        handleMultipleFilesUpload,
        resetForm
    } = useForm(initialFValues, true, validate);

    const reset = () => {
        resetForm()
        setValues(initialFValues)
        setCaptcha(null)
    }

    const handleSubmit = e => {
        e.preventDefault()
        setOpenAlert(true);
        setToast({type: "error", msg: "Something went wrong please retry after some time..!"});
        // if (validate() && captcha) {
        //     setLoading(true);
        //     const formData = new FormData();
        //     Object.keys(values).forEach(key => {
        //         if (Array.isArray(values[key])) {
        //             let array = values[key];
        //             array.forEach(obj => {
        //                 if (key === 'divisionsId') {
        //                     formData.append(key, obj.id)
        //                 } else {
        //                     formData.append(key, obj);
        //                 }
        //             })
        //         } else {
        //             formData.append(key, values[key])
        //         }
        //     });

        //     formData.append('recaptcha', captcha)

        //     let complaintRepo = new ComplaintRepository();
        //     complaintRepo.submitComplaint(formData).then(data => {
        //         console.log(data);
        //         reset();
        //         setLoading(false);
        //         setOpenAlert(true);
        //         setToast({type: "success", msg: "Your complaint has been registered!"});
        //     }).catch(e => {
        //         console.log("Error");
        //         setLoading(false);
        //         setOpenAlert(true);
        //         setToast({type: "error", msg: e.message});
        //     })
        // }
    }


    function onChange(value) {
        setCaptcha(value)
    }

    const {...rest} = props;
    return (
        <div>
            <Header
                color="dark"
                brand={"Water Resource Department - Govt of Rajasthan"}
                rightLinks={<HeaderLinks/>}
                sticky
            />
            <Header
                color="dark"
                routes={dashboardRoutes}
                sticky
                top={"70px"}
                leftLinks={<LeftLinks/>}
                mobileOpen={mobileOpen}
                onDrawerToggle={handleDrawerToggle}
                {...rest}
            />
          {/*   <Parallax filter image={require("assets/img/RWSRPD/Landing_Page.png")}>
                <div className={classes.container}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <h1 className={classes.title}>
                                Rajasthan Water Sector Restructuring Project for Desert Area (RWSRPD)
                            </h1>
                            <h2 style={{textAlign: "center"}}>
                                Funded by New Development Bank
                            </h2>
                        </GridItem>

                    </GridContainer>
                </div>
            </Parallax> */}
            <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleAlertClose}>
                <Alert onClose={handleAlertClose} severity={toastMessage.type}>
                    {toastMessage.msg}
                </Alert>
            </Snackbar>
            <div className={classNames(classes.main, classes.mainRaised)} style={{margin: 0}}>
                <div className={classes.container}>
                    <GridContainer justify="center">
                        <GridItem xs={12} sm={12} md={8} className={classes.center} justify={"center"}>
                            <h2 className={classes.title} style={{color: "black"}}>Complaint Form</h2>
                        </GridItem>
                    </GridContainer>
                    <GridContainer justify="center">
                        <GridItem xs={12} sm={12} md={8} className={classes.center}>
                            <Form onSubmit={handleSubmit}>
                            {/* <Form> */}
                                {/*<h2 id="modal-title">Add{loading ? "ing" : ""} Inspection Report</h2>*/}
                                {
                                    loading && <CustomLinearProgress/>
                                }
                                <Grid container alignItems={"center"} justify={"center"}>
                                    <Grid item style={{width: "100%"}}>
                                        <Controls.Input
                                            name="name"
                                            label="Name"
                                            value={values.name}
                                            required
                                            onChange={handleInputChange}
                                            disabled={loading}
                                            error={errors.name}
                                        />
                                        <Controls.Input
                                            label="Email Address"
                                            name="emailAddress"
                                            value={values.emailAddress}
                                            type={"email"}
                                            required
                                            onChange={handleInputChange}
                                            disabled={loading}
                                            error={errors.emailAddress}
                                        />
                                        <Controls.Input
                                            label="Contact Number"
                                            name="number"
                                            value={values.number}
                                            onChange={handleInputChange}
                                            type={"tel"}
                                            disabled={loading}
                                            error={errors.number}
                                        />
                                        <Controls.Input
                                            label="Brief Description of the complain"
                                            name="description"
                                            required
                                            multiline
                                            minRows={5}
                                            maxRows={5}
                                            value={values.description}
                                            onChange={handleInputChange}
                                            disabled={loading}
                                            error={errors.description}
                                        />
                                        <Grid container>
                                            <Grid item xs={6}>
                                                <Controls.UploadFile
                                                    name="inspectionFile"
                                                    label="Add Supporting Document"
                                                    title={"Upload Supporting Document"}
                                                    value={values.inspectionFile}
                                                    disabled={loading}
                                                    onChange={handleMultipleFilesUpload}
                                                    dialogProps={{
                                                        acceptedFiles: ['.pdf'],
                                                        showPreviews: true,
                                                        maxFileSize: (2 * 1024 * 1024),
                                                        filesLimit: 1,
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container alignItems={"center"} style={{color:"grey"}}>
                                            <Grid item xs={6}>
                                                <ul>
                                                    <li>Acceptable format: .PDF</li>
                                                    <li>Size limit: File must not exceed 2 MB.</li>
                                                </ul>
                                            </Grid>
                                        </Grid>
                                        <Grid container alignItems={"center"}>
                                            <Grid item xs={6}>
                                                <ReCAPTCHA
                                                    sitekey="6LdlxiUcAAAAAGdL-seR08jt3cB6fniBW28Pne4W"
                                                    onChange={onChange}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container alignItems={"center"} justify={"space-between"} style={{paddingBottom: "20px", paddingTop: "10px"}}>
                                            <Grid item xs={6} justify={"space-between"}>
                                            <Controls.Button
                                                disabled={!(captcha || values.name || values.emailAddress|| values.description) || loading}
                                                //  disabled = {false}
                                                type="submit"
                                                text="Submit"/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Form>
                        </GridItem>
                    </GridContainer>
                </div>
            </div>
            <Footer/>
        </div>
    );
}
