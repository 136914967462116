import TestSubmissionReportController from '../controllers/test-submission-report-controller';

class TestSubmissionReportRepository {
    getReport(id, data) {
        let testSubmissionReportController = new TestSubmissionReportController();
        return testSubmissionReportController.getReport(id, {
            data: data
        })
    }

    updateTestSubmissionReport(id, data) {
        let testSubmissionReportController = new TestSubmissionReportController();
        return testSubmissionReportController.updateReport(id, {
            data: data,
        });
    }
}

export default TestSubmissionReportRepository;
