import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components

import PaperBase from "../../components/PaperBase";
import { Route, Router, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import PeopleIcon from "@material-ui/icons/People";
import DnsRoundedIcon from "@material-ui/icons/DnsRounded";
import HomeIcon from '@material-ui/icons/Home';
import PermMediaOutlinedIcon from "@material-ui/icons/PhotoSizeSelectActual";
import PublicIcon from "@material-ui/icons/Public";
import EMCInspection from "./Sections/Inspection/EMC";
import TPQCInspection from "./Sections/Inspection/TPQC";
import TestingCompliance from "./Sections/Inspection/TestingReports";
import Overview from "./Sections/Overview";
import Events from "../../lib/events";
import { hasRule, RULES_CONSTANTS } from "../../models/user";
import SampleCollection from "./Sections/Testing/SampleCollection";
import TestSubmissions from "./Sections/Testing/TestSubmissions";
import SubmissionReport from "./Sections/Testing/SubmissionReport";
import MinorProgress from "./Sections/Physical Progress/MinorProgress";
import MinorDetails from "./Sections/Physical Progress/MinorDetails";
import PackagePhysicalProgress from "./Sections/Physical Progress/PackagePhysicalProgress";
import ConsolidatedSummary from "./Sections/Physical Progress/ConsolidatedSummary";
import PackageMilestoneProgress from "./Sections/Physical Progress/PackageMilstoneProcess";
import FinanceSummary from "./Sections/Physical Progress/FinanceSummary";
import FinancePackageWise from "./Sections/Physical Progress/FinancePackageWise";
import FinancePip from "./Sections/Physical Progress/FinancePip";
import FinancePipFinal from "./Sections/Physical Progress/FinancePipFinal";
import FinanceDetailPage from "./Sections/Physical Progress/FinanceDetailPage";


export default function EMCDashboard(props) {
    const [userRules, setUserRules] = useState(undefined);
    const [categories, setCategories] = useState([]);
    const [routes, setRoutes] = useState([]);

    const setCategoriesBasedOnRules = () => {
        if (userRules && userRules.length) {
            let finalCat = []
            if (hasRule(userRules, RULES_CONSTANTS.CAN_READ_INSPECTION_REPORTS)) {
                finalCat = [...finalCat,
                {
                    id: 'Inspection (Quality)',
                    children: [
                        // {id: 'Engineering Incharge', icon: <PeopleIcon/>},
                        { id: 'EMC', icon: <DnsRoundedIcon />, click: '/inspection/emc' },
                        {
                            id: 'Third Party Quality Control',
                            icon: <PermMediaOutlinedIcon />,
                            click: '/inspection/tpqc'
                        },
                        // {id: 'Department', icon: <PublicIcon/>},
                    ],
                },
                ]
            }
            if (hasRule(userRules, RULES_CONSTANTS.CAN_ACCESS_TESTING_MODULE)) {
                let initCat = []
                if (hasRule(userRules, RULES_CONSTANTS.CAN_COLLECT_TESTING_SAMPLES)) {
                    initCat = [...initCat, {
                        id: 'Sample Collection',
                        icon: <DnsRoundedIcon />,
                        click: '/testing/samples'
                    },]
                } else if (
                    hasRule(userRules, RULES_CONSTANTS.CAN_ACCEPT_TESTING_SAMPLES) ||
                    hasRule(userRules, RULES_CONSTANTS.CAN_ACCEPT_TEST_REPORTS)
                ) {
                    initCat = [...initCat,
                    { id: 'Sample Acceptance', icon: <DnsRoundedIcon />, click: '/testing/samples' },
                    { id: 'Test Report Submission', icon: <DnsRoundedIcon />, click: '/testing/submissionReport' },
                    ]
                }
                if (hasRule(userRules, RULES_CONSTANTS.CAN_ACCESS_TESTING_MODULE)) {
                    initCat = [...initCat,
                    { id: 'Test Report Compliance', icon: <DnsRoundedIcon />, click: '/testing/compliance' },
                    ]
                }

                finalCat = [
                    ...finalCat,
                    {
                        id: 'Testing (Quality)',
                        children: initCat
                    }
                ]

            }

            if (hasRule(userRules, RULES_CONSTANTS.CAN_READ_PHYSICAL_PROGRESS)) {
                finalCat = [...finalCat,
                {
                    id: 'Physical Progress',
                    children: [
                        { id: 'Summary of Canal Length', icon: <PeopleIcon />, click: '/progress/physical/summary' },
                        { id: 'Length wise details', icon: <DnsRoundedIcon />, click: '/progress/physical/package-wise' },
                        { id: 'Milestone wise details', icon: <DnsRoundedIcon />, click: '/progress/physical/milestone-wise' }
                        // {id: 'Department', icon: <PublicIcon/>},
                    ],
                },
                ]

            }

         /*  if (hasRule(userRules, RULES_CONSTANTS.CAN_READ_FINANCE_PROGRESS)) {
                finalCat = [...finalCat,
                {
                   id: 'Finance Progress',
                   children: [ 
                     // {id: 'Original Procurement Plan', icon: <HomeIcon/>,click: '/progress/finance/pip'},
                     //{ id: 'Financial Progress Details', icon: <DnsRoundedIcon />, click: '/progress/financedetails' },
                     //  { id: 'Summary of Finance Progress', icon: <PeopleIcon />, click: '/progress/finance/summary' },
                     //  { id: 'Package Wise', icon: <DnsRoundedIcon />, click: '/progress/finance/package' }
                      // {id: 'Department', icon: <PublicIcon/>},
      ],
             }, 
                 ]

             }   */


            setCategories(finalCat)
        }
    }


    const hist = createBrowserHistory({
        forceRefresh: false
    });

    const onUserDataLoaded = () => {
        setUserRules(window.staticParams.rules);
    };

    useEffect(() => {
        if (typeof window.staticParams.userData !== "undefined") {
            onUserDataLoaded();
        } else {
            window.addEventListener(Events.events.USERDATAUPDATED, onUserDataLoaded);
        }
        return () => {
            window.removeEventListener(Events.events.USERDATAUPDATED, onUserDataLoaded);
        }
    }, []);

    useEffect(() => {
        setCategoriesBasedOnRules()
    }, [userRules]);


    return (
        <PaperBase categories={categories} URL={"/dashboard"} {...props}>
            <Router history={hist}>
                <Switch>
                    <Route
                        path="/dashboard/inspection/emc/"
                        render={
                            (routeProps) => (
                                <EMCInspection
                                    userRules={userRules}
                                    {...routeProps}
                                />
                            )
                        }
                    />
                    <Route
                        path="/dashboard/inspection/tpqc/"
                        render={
                            (routeProps) => (
                                <TPQCInspection
                                    userRules={userRules}
                                    {...routeProps}
                                />
                            )
                        }
                    />
                    <Route
                        path="/dashboard/testing/compliance/"
                        render={
                            (routeProps) => (
                                <TestingCompliance
                                    userRules={userRules}
                                    {...routeProps}
                                />
                            )
                        }
                    />
                    <Route
                        path="/dashboard/testing/samples"
                        render={(routeProps) => (
                            <SampleCollection
                                heading={"Sample Collection"}
                                userRules={userRules}
                                {...routeProps}
                            />
                        )
                        }
                    />
                    <Route
                        path="/dashboard/testing/submissionReport/:reportId"
                        render={(routeProps) => (
                            <SubmissionReport
                                heading={"Submission Report"}
                                userRules={userRules}
                                {...routeProps}
                            />
                        )
                        }
                    />
                    <Route
                        path="/dashboard/testing/submissionReport"
                        exact
                        render={(routeProps) => (
                            <TestSubmissions
                                heading={"Test Submissions"}
                                userRules={userRules}
                                {...routeProps}
                            />
                        )
                        }
                        
                    />
                    <Route
                        path="/dashboard/progress/physical/milestone-wise/:packId"
                        render={(routeProps) => (
                            <PackageMilestoneProgress
                                heading={"Physical Progress - Milestone Wise Details"}
                                userRules={userRules}
                                {...routeProps}
                            />
                        )
                        }
                    />
                    <Route
                        path="/dashboard/progress/physical/package-wise/:packId"
                        render={(routeProps) => (
                            <PackagePhysicalProgress
                                heading={"Physical Progress - Length wise Details"}
                                userRules={userRules}
                                {...routeProps}
                            />
                        )
                        }
                    />
                    <Route
                        path="/dashboard/progress/physical/package-wise"
                        exact
                        render={(routeProps) => (
                            <MinorProgress
                                heading={"Physical Progress - Length wise Details"}
                                userRules={userRules}
                                redirectTo={"/dashboard/progress/physical/package-wise/"}
                                {...routeProps}
                            />
                        )
                        }
                    />

                    <Route
                        path="/dashboard/progress/physical/milestone-wise"
                        exact
                        render={(routeProps) => (
                            <MinorProgress
                                heading={"Physical Progress - Milestone Wise Details"}
                                userRules={userRules}
                                redirectTo={"/dashboard/progress/physical/milestone-wise/"}
                                {...routeProps}
                            />
                        )
                        }
                    />

                    <Route
                        path="/dashboard/progress/physical/summary"
                        exact
                        render={(routeProps) => (
                            <ConsolidatedSummary
                                heading={"Consolidated Summary"}
                                userRules={userRules}
                                {...routeProps}
                            />
                        )
                        }
                    />
                    <Route
                        path="/dashboard/progress/finance/summary"
                        exact
                        render={(routeProps) => (
                            <FinanceSummary
                                heading={"Consolidated Summary"}
                                userRules={userRules}
                                {...routeProps}
                            />
                        )
                        }
                    />
                    <Route
                        path="/dashboard/progress/finance/package"
                        exact
                        render={(routeProps) => (
                            <FinancePackageWise
                                heading={"Consolidated Summary"}
                                userRules={userRules}
                                {...routeProps}
                            />
                        )
                        }
                    />

                    <Route
                        path="/dashboard/progress/financedetails/:packId"
                        render={(routeProps) => (
                            <FinanceDetailPage
                                heading={"Finance Progress - Finance Progress Details"}
                                userRules={userRules}
                                {...routeProps}
                            />
                        )
                        }
                    />
                    <Route
                        path="/dashboard/progress/financedetails"
                        exact
                        render={(routeProps) => (
                            <FinancePip
                                heading={"Finance Progress - Finance Progress Details"}
                                userRules={userRules}
                                redirectTo={"/dashboard/progress/financedetails/"}
                                {...routeProps}
                            />
                        )
                        }
                    />

                    {/* <Route
                        path="/dashboard/progress/financedetails"
                        exact
                        render={(routeProps) => (
                            <FinancePip
                                heading={"Consolidated Summary"}
                                userRules={userRules}
                                {...routeProps}
                            />
                        )
                        }
                    /> */}
                    <Route
                        path="/dashboard/progress/finance/pipfinal"
                        exact
                        render={(routeProps) => (
                            <FinancePipFinal
                                heading={"Consolidated Summary"}
                                userRules={userRules}
                                {...routeProps}
                            />
                        )
                        }
                    />



                    <Route
                        path="/dashboard"
                        render={(routeProps) => (
                            <Overview
                                {...routeProps}
                            />
                        )
                        }
                    />
                </Switch>
            </Router>
        </PaperBase>
    );
}
