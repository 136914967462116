import ComplianceReportController from "../controllers/compliance-report-controller";

class ComplianceReportRepository {

    getCount(data) {
        const complianceReportController = new ComplianceReportController();
        return complianceReportController.getCount({
            data,
        })
    }

    submitComplianceReport(id, data) {
        const complianceReportController = new ComplianceReportController();
        return complianceReportController.postComplianceReport(id, {
            data: data,
        });
    }

}

export default ComplianceReportRepository;
