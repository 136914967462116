import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import {withStyles} from '@material-ui/core/styles';
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import InspectionReports from "./InspectionReports";
import {INSPECTION_TYPE} from "../../../../models/user";

const styles = (theme) => ({
    paper: {
        // maxWidth: 1200,
        margin: 'auto',
        overflow: 'hidden',
    },
    searchBar: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    searchInput: {
        fontSize: theme.typography.fontSize,
    },
    block: {
        display: 'block',
    },
    addUser: {
        marginRight: theme.spacing(1),
    },
    contentWrapper: {
        margin: '20px 16px',
    },
    title: {
        padding: '20px',
    },
    search: {
        borderRight: "1px solid lightgray",
    }
});

function EMCInspection(props) {
    const {classes, userRules} = props;
    const [openAlert, setOpenAlert] = useState(false);
    const [toastMessage, setToast] = useState("");

    const handleAlertClose = () => {
        setOpenAlert(false);
    }

    return (
        <Paper className={classes.paper}>
            <div className={classes.contentWrapper}>
                <Typography variant={"h5"} color="textPrimary" align="center" className={classes.title}>
                    Inspection Reports & Compliance - Third Party Quality Control
                </Typography>
                <InspectionReports
                    userRules={userRules}
                    setToast={setToast}
                    setOpenAlert={setOpenAlert}
                    inspectionType={INSPECTION_TYPE.TPQC}
                />
            </div>
            <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleAlertClose}>
                <Alert onClose={handleAlertClose} severity={toastMessage.type}>
                    {toastMessage.msg}
                </Alert>
            </Snackbar>
        </Paper>
    );
}

EMCInspection.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EMCInspection);
