import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ComplianceReportRepository from "../../../../repository/compliance-report-repository";

export default function RejectModal(props) {
    const {open, onClose, compliance, type, setOpenAlert, setToast} = props;
    const [title, setTitle] = useState("");

    const isReject = type === 2;
    const isPartiallyReject = type === 3;

    const submitRemarks = () => {
        let complianceReportRepository = new ComplianceReportRepository();
        const formData = new FormData();
        formData.append('reqType', type);
        formData.append('complianceId', compliance.id);
        formData.append('remarks', title);

        complianceReportRepository.submitComplianceReport(compliance.id, formData).then(data => {
            setOpenAlert(true);
            setToast({
                type: "success",
                msg: "Compliance Report " + (isPartiallyReject ? "Partially Rejected" : "Rejected")
            });
            onClose(true);
        }).catch(e => {
            console.log("Error");
            setOpenAlert(true)
            setToast({type: "error", msg: e.message});
            onClose(true);
        })
    }

    return (
        <div>
            <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
                <DialogTitle
                    id="form-dialog-title">{isPartiallyReject ? "Partially Reject" : "Reject"} Compliance</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Kindly Enter the Remarks for {isPartiallyReject ? "Partially Rejecting" : "Rejecting"} the
                        Compliance.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="remarks"
                        label="Remarks"
                        type="text"
                        fullWidth
                        onChange={(e) => {
                            setTitle(e.target.value)
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={submitRemarks} color="primary">
                        {isPartiallyReject ? "Partially Reject" : "Reject"} Compliance
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
