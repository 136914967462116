import CustomAPI from "../lib/custom-api";

class ComplianceReportController {
    constructor(){
        this.customApi = new CustomAPI();
    }
    getCount(parameters) {
        parameters.endPoint = 'api/complianceReport/count';
        return this.customApi.get(parameters);
    }

    postComplianceReport(id, parameters) {
        parameters.endPoint = 'api/complianceReport/' + id;
        parameters.headers = {'content-type': 'multipart/form-data'}
        return this.customApi.put(parameters);
    }
}

export default ComplianceReportController;
