import React from 'react';
import PropTypes from 'prop-types';
import {createMuiTheme, ThemeProvider, withStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';
import Navigator from './Navigator/Navigator';
import Header from "./Header/Header";
import HeaderLinks from "./Header/HeaderLinks";
import {createBrowserHistory} from "history";


let theme = createMuiTheme({
    palette: {
        primary: {
            light: '#63ccff',
            main: '#009be5',
            dark: '#006db3',
        },
    },
    typography: {
        h5: {
            fontWeight: 500,
            fontSize: 26,
            letterSpacing: 0.5,
        },
    },
    shape: {
        borderRadius: 8,
    },
    props: {
        MuiTab: {
            disableRipple: true,
        },
    },
    mixins: {
        toolbar: {
            minHeight: 48,
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1320,
            xl: 1920,
        },
    },
});

theme = {
    ...theme,
    overrides: {
        MuiDrawer: {
            paper: {
                backgroundColor: '#18202c',
            },
        },
        MuiButton: {
            label: {
                textTransform: 'none',
            },
            contained: {
                boxShadow: 'none',
                '&:active': {
                    boxShadow: 'none',
                },
            },
        },
        MuiTabs: {
            root: {
                marginLeft: theme.spacing(1),
            },
            indicator: {
                height: 3,
                borderTopLeftRadius: 3,
                borderTopRightRadius: 3,
                backgroundColor: theme.palette.common.white,
            },
        },
        MuiTab: {
            root: {
                textTransform: 'none',
                margin: '0 16px',
                minWidth: 0,
                padding: 0,
                [theme.breakpoints.up('md')]: {
                    padding: 0,
                    minWidth: 0,
                },
            },
        },
        MuiIconButton: {
            root: {
                padding: theme.spacing(1),
            },
        },
        MuiTooltip: {
            tooltip: {
                borderRadius: 4,
            },
        },
        MuiDivider: {
            root: {
                backgroundColor: '#404854',
            },
        },
        MuiListItemText: {
            primary: {
                fontWeight: theme.typography.fontWeightMedium,
            },
        },
        MuiListItemIcon: {
            root: {
                color: 'inherit',
                marginRight: 0,
                '& svg': {
                    fontSize: 20,
                },
            },
        },
        MuiAvatar: {
            root: {
                width: 32,
                height: 32,
            },
        },
        MuiTableCell: {
            root: {
                fontSize: "1.20rem",
            }
        },
        MuiTableRow: {
            root: {
                cursor: "pointer",
            }
        }
    },
};

const drawerWidth = 256;

const styles = {
    root: {
        display: 'flex',
        minHeight: '100vh',
    },
    drawer: {
        [theme.breakpoints.up('lg')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    app: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    main: {
        flex: 1,
        padding: theme.spacing(10, 1),
        background: '#eaeff1',
    },
    footer: {
        padding: theme.spacing(2),
        background: '#eaeff1',
    },
};

function Paperbase(props) {
    const {classes, URL, categories} = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const {...rest} = props;

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const hist = createBrowserHistory({
        forceRefresh: true
    });

    const routeChange = (path) => {
        hist.push(URL + path);
    };

    return (
        <ThemeProvider theme={theme}>
            <div className={classes.root}>
                <CssBaseline/>
                <nav className={classes.drawer}>
                    <Hidden lgUp implementation="js">
                        <Navigator
                            PaperProps={{style: {width: drawerWidth}}}
                            variant="temporary"
                            routerprops={props}
                            routeChange={routeChange}
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                            categories={categories}
                        />
                    </Hidden>
                    <Hidden mdDown implementation="css">
                        <Navigator
                            PaperProps={{style: {width: drawerWidth}}}
                            routerprops={props}
                            routeChange={routeChange}
                            categories={categories}
                        />
                    </Hidden>
                </nav>
                <div className={classes.app}>
                    <Header
                        onDrawerToggle={handleDrawerToggle}
                        rightLinks={<HeaderLinks/>}
                        fixed
                        showForever
                        changeColorOnScroll={{
                            height: 200,
                            color: "white"
                        }}
                        {...rest}
                    />
                    <main className={classes.main}>
                        {props.children}
                    </main>
                    {/*<footer className={classes.footer}>*/}
                    {/*    <Copyright />*/}
                    {/*</footer>*/}
                </div>
            </div>
        </ThemeProvider>
    );
}

Paperbase.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Paperbase);