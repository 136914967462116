import CustomAPI from "../lib/custom-api";

class TestSubmissionReportController {
    constructor(){
        this.customApi = new CustomAPI();
    }
    getReport(id, parameters) {
        parameters.endPoint = 'api/testSubmissionReport/' + id;
        return this.customApi.get(parameters);
    }

    updateReport(id, parameters) {
        parameters.endPoint = 'api/testSubmissionReport/' + id;
        parameters.headers = {'content-type': 'multipart/form-data'}
        return this.customApi.put(parameters);
    }
}

export default TestSubmissionReportController;
