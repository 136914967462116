const CONSTANTS = {
    events: {
        USERDATAUPDATED: 'userDataUpdated',
    },

    cookies: {
    }
};

module.exports = CONSTANTS;
