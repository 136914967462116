import React, {useState, useEffect} from 'react'
import {Grid,} from '@material-ui/core';
import Controls from "../../../Components/controls/Controls";
import {useForm, Form} from '../../../Components/useForm';
import {DropzoneDialog} from "material-ui-dropzone";
import InspectionReportRepository from "../../../../repository/inspection-report-repository";
import DivisionRepository from "../../../../repository/division-repository";
import PackRepository from "../../../../repository/pack-repository";
import CustomLinearProgress from "../../../../components/CustomLinearProgress/CustomLinearProgress";
import {INSPECTION_TYPE} from "../../../../models/user";
// import * as employeeService from "../../../services/employeeService";


const initialFValues = {
    inspectionFile: [],
    inspectionImages: [],
    inspectionOn: new Date(),
    workId: "",
    divisionId: "",
    site: "",
    remarks: "",
    divisionsId: [],
    packageId: "",
    color: "green",
}

const fetchPackages = (requestArgs, callback) => {
    let packRepository = new PackRepository();
    packRepository.getPacks(requestArgs)
        .then(response => response.data)
        .then(data => {
            callback(data)
        })
        .catch(err => console.log(err));
}

const fetchDivisions = (requestArgs, callback) => {
    let divisionRepository = new DivisionRepository();
    divisionRepository.getDivisions(requestArgs)
        .then(response => response.data)
        .then(data => {
            callback(data)
        })
        .catch(err => console.log(err));
}


export default function InspectionForm(props) {

    const {handleClose, works, divisions, inspectionType, setOpenAlert, setToast} = props;

    const [packages, setPackages] = useState([]);
    const [allowedDivisions, setAllowedDivisions] = useState([]);
    const [loading, setLoading] = useState(false);

    const validate = (fieldValues = values) => {
        let temp = {...errors}
        if ('inspectionOn' in fieldValues)
            temp.inspectionOn = fieldValues.inspectionOn ? "" : "This field is required."
        if ('inspectionFile' in fieldValues)
            temp.inspectionFile = fieldValues.inspectionFile ? "" : "This field is required."
        setErrors({
            ...temp
        })

        if (fieldValues == values)
            return Object.values(temp).every(x => x == "")
    }

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        handleMultipleFilesUpload,
        resetForm
    } = useForm(initialFValues, true, validate);

    useEffect(() => {
        function handleData(data) {
            setPackages(data);
        }

        if (values.workId && values.divisionId) {
            let params = {
                workId: values.workId,
                divId: values.divisionId,
            }
            fetchPackages(params, handleData)
        }
        setValues({
            ...values,
            packageId: "",
        })

    }, [values.workId, values.divisionId])

    useEffect(() => {
        function handleData(data) {
            setAllowedDivisions(data);
        }

        if (values.packageId) {
            let params = {
                packId: values.packageId,
            }
            fetchDivisions(params, handleData)
        }
        setValues({
            ...values,
            divisionsId: [],
        })

    }, [values.packageId])

    const handleSubmit = e => {
        e.preventDefault()
        // employeeService.insertEmployee(values
        // )
      
        if (validate()) {
            setLoading(true);
            const formData = new FormData();
            Object.keys(values).forEach(key => {
                if (Array.isArray(values[key])) {
                    let array = values[key];
                    array.forEach(obj => {
                        if (key === 'divisionsId') {
                            formData.append(key, obj.id)
                        } else {
                            formData.append(key, obj);
                        }
                    })
                } else {

                    formData.append(key, values[key])
                }
            });
            
            formData.append('type', inspectionType);
            formData.append('allowedDivisions', JSON.stringify(allowedDivisions));
            // formData.append('type', "fdfs");

            
            let inspectionReportRepository = new InspectionReportRepository();
            inspectionReportRepository.submitInspectionForm(formData).then(data => {
                setLoading(false);
                handleClose(true);
                setOpenAlert(true);
                setToast({type: "success", msg: "Inspection Report Created"});
            }).catch(e => {
                setLoading(false);
                // handleClose(true);
                setOpenAlert(true)
                setToast({type: "error", msg: e.message});
            })
        }
    }

    const reset = () => {
        resetForm()
        setValues(initialFValues)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <h2 id="modal-title">Add{loading ? "ing" : ""} Inspection Report</h2>
            {
                loading && <CustomLinearProgress/>
            }
            <Grid container alignItems={"center"}>
                <Grid item style={{width: "100%"}}>
                    <Controls.Select
                        name="workId"
                        label="Component"
                        value={values.workId}
                        onChange={handleInputChange}
                        options={works}
                        error={errors.workId}
                        disabled={loading}
                    />
                    <Controls.Select
                        name="divisionId"
                        label="Division"
                        value={values.divisionId}
                        onChange={handleInputChange}
                        options={divisions}
                        error={errors.divisionId}
                        disabled={loading}
                    />
                    <Controls.Select
                        name="packageId"
                        label="Package"
                        disabled={!(values.divisionId && values.workId) || loading}
                        value={values.packageId}
                        onChange={handleInputChange}
                        options={packages.map(pkg => {
                            return {...pkg, name: (pkg.packageId + " " + pkg.name)}
                        })}
                        error={errors.divisionId}
                    />
                    <Controls.MultipleSelect
                        name="divisionsId"
                        label="Inspection Report pertains to -"
                        value={values.divisionsId}
                        onChange={handleInputChange}
                        options={allowedDivisions}
                        disabled={!(values.packageId) || loading}
                        error={errors.divisionId}
                    />
                    <Controls.DatePicker
                        name="inspectionOn"
                        label="Inspection Date"
                        value={values.inspectionOn}
                        onChange={handleInputChange}
                        disabled={loading}
                        maxDate={new Date()}
                    />
                    <Controls.Input
                        name="site"
                        label="Site"
                        value={values.site}
                        onChange={handleInputChange}
                        disabled={loading}
                        error={errors.site}
                    />
                    <Controls.Input
                        label="Remarks"
                        name="remarks"
                        value={values.remarks}
                        onChange={handleInputChange}
                        disabled={loading}
                        error={errors.remarks}
                    />
                    {
                        inspectionType === INSPECTION_TYPE.TPQC ?
                            (
                                <Controls.Select
                                    name="color"
                                    label="Priority"
                                    disabled={loading}
                                    value={values.color}
                                    onChange={handleInputChange}
                                    includeNone={false}
                                    options={
                                        [
                                            {"value": "green", "id": "green" , "name": "Green"},
                                            {"value": "yellow", "id": "yellow" , "name": "Yellow"},
                                            {"value": "red", "id": "red" , "name": "Red"},
                                        ]}
                                    error={errors.divisionId}
                                />
                            ) : null
                    }
                    <Grid container alignItems={"center"}>
                        <Grid item xs={6}>
                            <Controls.UploadFile
                                name="inspectionFile"
                                label="Add Inspection File"
                                title={"Upload Inspection Report, (1 file, .pdf, Max Size: 14mB)"}
                                value={values.inspectionFile}
                                disabled={loading}
                                onChange={handleMultipleFilesUpload}
                                dialogProps={{
                                    acceptedFiles: ['.pdf'],
                                    showPreviews: true,
                                    maxFileSize: 14000000,
                                    filesLimit: 1,
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controls.UploadFile
                                name="inspectionImages"
                                label="Add Inspection Image(s)"
                                title={"Upload Inspection Images, (5 files, Max Size: 5mB/image)"}
                                value={values.inspectionImages}
                                onChange={handleMultipleFilesUpload}
                                disabled={loading}
                                dialogProps={{
                                    acceptedFiles: ['image/*'],
                                    showPreviews: true,
                                    maxFileSize: 5000000,
                                    filesLimit: 5,
                                }}
                            />
                        </Grid>
                    </Grid>
                    <div>
                        <Controls.Button
                            disabled={!(values.inspectionFile.length && values.inspectionImages.length && values.divisionsId.length && values.packageId) || loading}
                            type="submit"
                            text="Submit"/>
                        <Controls.Button
                            text="Reset"
                            color="default"
                            disabled={loading}
                            onClick={reset}/>
                    </div>
                </Grid>
            </Grid>
        </Form>
    )
}
