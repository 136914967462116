import CustomAPI from "../lib/custom-api";

class DivisionController {
    constructor(){
        this.customApi = new CustomAPI();
    }
    getDivisions(parameters){
        parameters.endPoint = 'api/divisions';
        return this.customApi.get(parameters);
    }
}

export default DivisionController;
