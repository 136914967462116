import React, {useEffect, useState} from "react";
import {Grid} from "@material-ui/core";
import ComplianceHistoryRepository from "../../../../repository/compliance-history-repository";
import GridContainer from "../../../../components/Grid/GridContainer";
import GridItem from "../../../../components/Grid/GridItem";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {makeStyles} from "@material-ui/core/styles";
import styles from "../../../../assets/jss/material-kit-react/views/profilePage";
import {CloudDownload} from "@material-ui/icons";
import Button from "../../../../components/CustomButtons/Button";
const useStyles = makeStyles(styles);

const fetchHistory = (id, callback) => {
    let complianceHistoryRepository = new ComplianceHistoryRepository();
    complianceHistoryRepository.getHistories({
        complianceReportId: id,
    })
        .then(response => response.data)
        .then(data => {
            callback(data)
        })
        .catch(err => console.log(err));
}

export default function ComplianceHistory(props) {
    const {compliance} = props;
    const classes = useStyles();
    const [history, setHistory] = useState([]);

    useEffect(() => {
        function handleHistory(data) {
            setHistory(data);
        }

        fetchHistory(compliance.id, handleHistory)
    }, []);

    return <GridContainer justify={"center"}>
        <GridItem xs={12} sm={12} md={12}>
            <h4>Compliance History</h4>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>S No.</TableCell>
                            {/*<TableCell>Date</TableCell>*/}
                            <TableCell>Action</TableCell>
                            <TableCell>Action By</TableCell>
                            <TableCell>Remarks</TableCell>
                            <TableCell align={"center"}>Report</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {history.map((row, index) => (
                            <TableRow hover key={row.name}>
                                <TableCell>{(index + 1) + "."}</TableCell>
                                {/*<TableCell>{(new Date(row.createdAt)).toDateString()}</TableCell>*/}
                                <TableCell component="th" scope="row">
                                    {row.status.label}
                                </TableCell>
                                <TableCell>{(row.created_by)}</TableCell>
                                <TableCell>{(row.remarks || "-")}</TableCell>
                                <TableCell align={"center"}>{(row.report ?
                                    <Button
                                        size={"sm"}
                                        fullWidth
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            window.location.href = row.report;
                                        }}
                                        color={"info"}
                                    >
                                        <CloudDownload/> Download
                                    </Button> :
                                    "-")
                                }</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </GridItem>
    </GridContainer>
}