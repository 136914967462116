import React from 'react'
import { FormControl, InputLabel, Select as MuiSelect, MenuItem, FormHelperText } from '@material-ui/core';
import Chip from "@material-ui/core/Chip";
import Controls from "./Controls";
import {makeStyles, useTheme} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
}));

export default function MultipleSelect(props) {

    const { name, label, value,error=null, onChange, options, ...rest } = props;
    const classes = useStyles();
    const theme = useTheme();


    return (
        <FormControl variant="outlined"
                     {...(error && {error:true})}>
            <InputLabel>{label}</InputLabel>
            <MuiSelect
                label={label}
                name={name}
                value={value}
                renderValue={(selected) => (
                    <div className={classes.chips}>
                        {selected.map((value) => (
                            <Chip key={value.name} label={value.name} className={classes.chip} />
                        ))}
                    </div>
                )}
                multiple
                onChange={onChange}
                {...rest}
            >
                {
                    options.map(
                        item => (<MenuItem key={item.name} value={item}>{item.name}</MenuItem>)
                    )
                }
            </MuiSelect>
            {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    )
}
