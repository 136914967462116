import CustomAPI from "../lib/custom-api";

class MinorController {
    constructor(){
        this.customApi = new CustomAPI();
    }

    getCount(parameters) {
        parameters.endPoint = 'api/physicalSummary/count';
        return this.customApi.get(parameters);
    }

    getMinor(id, parameters) {
        parameters.endPoint = 'api/canal/'+id;
        return this.customApi.get(parameters);
    }

    getMilestoneComponent(id, parameters) {
        parameters.endPoint = 'api/milestone/'+id;
        return this.customApi.get(parameters);
    }
    getMinors(parameters) {
        parameters.endPoint = 'api/canal';
        return this.customApi.get(parameters);
    }
    postMinorLength(parameters) {
        parameters.endPoint = 'api/canal';
        parameters.headers = {'content-type': 'multipart/form-data'}
        return this.customApi.post(parameters);
    }

    postComponentMilestone(parameters) {
        parameters.endPoint = 'api/milestone';
        parameters.headers = {'content-type': 'multipart/form-data'}
        return this.customApi.post(parameters);
    }

    updateMinor(id, parameters) {
        parameters.endPoint = 'api/canal/' + id;
        return this.customApi.put(parameters);
    }
}

export default MinorController;
