import TrainingController from '../controllers/training-controller';

class TrainingRepositiry {
  
    loginTraining(data) {
        let trainingFormController = new TrainingController();
        return trainingFormController.postTrainingLogin({
            data: data,
        });
    }

    formSubmitTraining(data) {
        let trainingFormController = new TrainingController();
        return trainingFormController.postTrainingFormData({
            data: data,
        });
    }

    

}

export default TrainingRepositiry;
