import React, {useEffect, useState} from "react";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
// core components

import PackRepository from "../../repository/pack-repository";
import PaperBase from "../../components/PaperBase";
import PeopleIcon from "@material-ui/icons/People";
import DnsRoundedIcon from "@material-ui/icons/DnsRounded";
import PermMediaOutlinedIcon from "@material-ui/icons/PhotoSizeSelectActual";
import PublicIcon from "@material-ui/icons/Public";
import SettingsIcon from "@material-ui/icons/Settings";
import TimerIcon from "@material-ui/icons/Timer";
import PhonelinkSetupIcon from "@material-ui/icons/PhonelinkSetup";
import {Route, Router, Switch} from "react-router-dom";
import EMCInspection from "./Sections/Inspection/EMC";
import Content from "./Sections/Overview";
import {createBrowserHistory} from "history";

const fetchPackage = (id, callback) => {
    // let params = {includePacks: true};
    let packRepository = new PackRepository();
    packRepository.getPack(id)
        .then(response => response.data)
        .then(data => {
            callback(data)
        })
        .catch(err => console.log(err));
}

const BASE_URL = "/package";

export default function PackagePage(props) {
    let packageId = props.match.params.packageId;
    const [pack, setPackage] = useState([]);
    const URL = BASE_URL + "/" + packageId;

    useEffect(() => {
        function handleData(data) {
            setPackage(data);
        }

        fetchPackage(packageId, handleData)
    }, []);


    const categories = [
        {
            id: 'Estimate',
            children: [
            ],
        },
        {
            id: 'Bid Details',
            children: [
                { id: 'Technical Bid', icon: <PeopleIcon />},
                { id: 'Financial Bid', icon: <DnsRoundedIcon /> },
            ],
        },
        {
            id: 'Bills of Quantity',
            children: [
                { id: 'Tenders', icon: <PeopleIcon />},
                { id: 'Running bills for payment', icon: <DnsRoundedIcon /> },
            ],
        },
        {
            id: 'Hinderance',
            children: [
                { id: 'Govt. order', icon: <PeopleIcon /> },
                { id: 'Hinderance Register', icon: <DnsRoundedIcon /> },
            ],
        },
        {
            id: 'Inspection (Quality)',
            children: [
                // { id: 'Engineering Incharge', icon: <PeopleIcon />},
                { id: 'EMC', icon: <DnsRoundedIcon />, click: '/inspection/emc' },
                { id: 'Third Party', icon: <PermMediaOutlinedIcon /> },
                // { id: 'Department', icon: <PublicIcon /> },
            ],
        },
        {
            id: 'Correspondence / Audit',
            children: [
                { id: 'Tech', icon: <SettingsIcon /> },
                { id: 'Finance', icon: <TimerIcon /> },
                { id: 'AKG', icon: <PhonelinkSetupIcon /> },
                { id: 'NDP / Banking', icon: <PhonelinkSetupIcon /> },
            ],
        },
        {
            id: 'Inquiry',
            children: [
            ],
        },
        {
            id: 'Contract Administration & Management',
            children: [
            ],
        },
    ];

    const hist = createBrowserHistory({
        forceRefresh: true
    });


    return (
        <PaperBase pack={pack} packageId={packageId} categories={categories} URL={URL} {...props}>
            <Router history={hist}>
                <Switch>
                    <Route
                        path="/package/:packageId/inspection/emc/"
                        render={(routeProps) => (<EMCInspection pack={pack}{...routeProps}/>)}
                    />
                    <Route
                        path="/package/:packageId/"
                        render={(routeProps) => (
                            <Content
                                pack={pack}
                                {...routeProps}
                            />
                        )
                        }
                    />
                </Switch>
            </Router>
        </PaperBase>
    );
}
