import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-react/views/landingPage.js";

// Sections for this page
import OverviewSection from "./Sections/OverviewSection.js";
import TeamSection from "./Sections/TeamSection.js";
import logo from "../../assets/img/RWSRPD/water-resources-logo.png";
import {Link} from "react-router-dom";
import LeftLinks from "../../components/Header/LeftLinks";
import NavPills from "../../components/NavPills/NavPills";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function LandingPage(props) {
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const classes = useStyles();
    const {...rest} = props;
    return (
        <div>
            <Header
                color="dark"
                brand={"Water Resource Department - Govt of Rajasthan"}
                rightLinks={<HeaderLinks/>}
                sticky
            />
            <Header
                color="dark"
                routes={dashboardRoutes}
                sticky
                top={"70px"}
                leftLinks={<LeftLinks/>}
                mobileOpen={mobileOpen}
                onDrawerToggle={handleDrawerToggle}
                {...rest}
            />
            <Parallax filter image={require("assets/img/RWSRPD/Landing_Page.png")}>
                <div className={classes.container}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <h1 className={classes.title}>
                                Rajasthan Water Sector Restructuring Project for Desert Area (RWSRPD)
                            </h1>
                            <h2 style={{textAlign: "center"}}>
                                Funded by New Development Bank
                            </h2>
                        </GridItem>

                    </GridContainer>
                </div>
            </Parallax>
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container}>
                    <TeamSection/>
                    <OverviewSection/>
                </div>
            </div>
            <Footer/>
        </div>
    );
}
