import React, {useState, useEffect} from 'react'
import {Grid,} from '@material-ui/core';
import Controls from "../../../Components/controls/Controls";
import {useForm, Form} from '../../../Components/useForm';
import CustomLinearProgress from "../../../../components/CustomLinearProgress/CustomLinearProgress";
import MinorRepository from "../../../../repository/minor-repository";
import moment from "moment";
import InputAdornment from "@material-ui/core/InputAdornment";


const initialFValues = {
    inspectionOn: new Date(),
    canalId: "",
    progress_rd: "",
    progress_kms: "",
    remarks: "",
}

export default function AddProgressLengthForm(props) {

    const {handleClose, canals, packId, setOpenAlert, setToast} = props;

    const [loading, setLoading] = useState(false);

    const validate = (fieldValues = values) => {
        let temp = {...errors}
        if ('inspectionOn' in fieldValues)
            temp.inspectionOn = fieldValues.inspectionOn ? "" : "This field is required."
        if ('inspectionFile' in fieldValues)
            temp.inspectionFile = fieldValues.inspectionFile ? "" : "This field is required."
        setErrors({
            ...temp
        })

        if (fieldValues == values)
            return Object.values(temp).every(x => x == "")
    }

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    } = useForm(initialFValues, true, validate);

    const handleSubmit = e => {
        e.preventDefault()
        // employeeService.insertEmployee(values
        // )
        if (validate() && packId) {
            setLoading(true);
            let formData = new FormData();
            formData.append("packId", packId);
            formData.append("canalId", values.canalId);
            formData.append("progress_rd", values.progress_rd);
            formData.append("progress_kms", values.progress_kms);
            formData.append("remarks", values.remarks);
            formData.append("inspectionOn", values.inspectionOn);

            let minorRepository = new MinorRepository();
            minorRepository.submitMinorLength(formData).then(data => {
                setLoading(false);
                handleClose(true);
                setOpenAlert(true);
                setToast({type: "success", msg: "Progress Added!"});
            }).catch(e => {
                console.log("Error");
                setLoading(false);
                // handleClose(true);
                setOpenAlert(true)
                setToast({type: "error", msg: e.message});
            })
        }
    }

    const reset = () => {
        resetForm()
        setValues(initialFValues)
    }

    const selectedCanal = values.canalId ? canals.find((canal) => canal.id === values.canalId) : null;
    const selectedCanalDate = selectedCanal?.physicals[0]?.achieved_by;
    const parsedDate = selectedCanalDate ? new moment(selectedCanalDate).format("MMM YYYY") : null


    return (
        <Form onSubmit={handleSubmit}>
            <h2 id="modal-title">Add{loading ? "ing" : ""} Length-wise Progress</h2>
            {
                loading && <CustomLinearProgress/>
            }
            <Grid container alignItems={"center"}>
                <Grid item style={{width: "100%"}}>
                    <Controls.Select
                        name="canalId"
                        label="Minor"
                        value={values.canalId}
                        onChange={handleInputChange}
                        options={canals}
                        error={errors.canalId}
                        disabled={loading}
                    />
                    {
                        parsedDate ? <div style={{
                            padding: "5px 5px 5px 20px",
                            display: "flex",
                            color: "red"
                        }}> Last progress at: {parsedDate}</div> : null
                    }
                    <Controls.DatePicker
                        name="inspectionOn"
                        label="Progress Date"
                        value={values.inspectionOn}
                        onChange={handleInputChange}
                        disabled={loading}
                        maxDate={new Date()}
                    />
                    <Controls.Input
                        name="progress_rd"
                        label="Cumulative Progress(in RD)"
                        value={values.progress_rd}
                        onChange={handleInputChange}
                        type={"number"}
                        disabled={loading}
                        error={errors.progress_rd}
                        InputProps={{endAdornment: <InputAdornment position="end">RD</InputAdornment>}}
                    />
                    <Controls.Input
                        label="Cumulative Progress(in Kms)"
                        name="progress_kms"
                        value={values.progress_kms}
                        onChange={handleInputChange}
                        disabled={loading}
                        InputProps={{endAdornment: <InputAdornment position="end">Kms</InputAdornment>}}
                        type={"number"}
                        error={errors.progress_kms}
                    />
                    <Controls.Input
                        label="Remarks"
                        name="remarks"
                        value={values.remarks}
                        onChange={handleInputChange}
                        disabled={loading}
                        error={errors.remarks}
                    />
                    <div>
                        <Controls.Button
                            disabled={!(values.canalId && values.progress_rd && values.progress_kms) || loading}
                            type="submit"
                            text="Submit"/>
                        <Controls.Button
                            text="Reset"
                            color="default"
                            disabled={loading}
                            onClick={reset}/>
                    </div>
                </Grid>
            </Grid>
        </Form>
    )
}
