import React, {useState, useEffect} from 'react'
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import makeStyles from "@material-ui/core/styles/makeStyles";
import yellow from "@material-ui/core/colors/yellow";
import {REPORT_TYPE_KEY_VALUE, SAMPLE_STATUS_KEY_VALUE} from "../../../../models/sample";
import Collapse from "@material-ui/core/Collapse";
import Box from "@material-ui/core/Box";
import Button from "../../../../components/CustomButtons/Button";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    color_green: {
        color: "green",
    },
    color_red: {
        color: "red",
    },
    color_yellow: {
        color: yellow["700"],
    },
    fontSmall: {
        fontSize: "14px"
    }
});


export default function CollectionVisitWithReports(props) {
    const {
        userRules,
        inspectionType,
        visit,
        divisions,
        fetchVisits,
        setOpenAlert,
        setToast,
        setSelectedVisit,
    } = props;
    const classes = useRowStyles();
    const [open, setOpen] = React.useState(false);
    const pack = visit.pack;
    const inspectionDate = (new Date(visit.inspected_on)).toDateString();
    const samples = visit.collectedSamples;
    const divReqd = visit.divisionsId.map(div => {
        return divisions.find((division) => {
            return division.id === parseInt(div);
        })
    })


    return (
        <React.Fragment key={visit.id + "_visit"}>
            <TableRow className={classes.root} hover onClick={() => setOpen(!open)}>
                <TableCell width={"5%"}>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                </TableCell>
                <TableCell align={"right"} width={"5%"}>
                    <strong>{visit.id}.</strong>
                </TableCell>
                <TableCell align={"right"} width={"5%"}>
                    <strong>{pack.packageId}</strong>
                </TableCell>
                <TableCell width={"10%"}>{inspectionDate}</TableCell>
                <TableCell width={"10%"}>{visit.created_by}</TableCell>
                <TableCell className={classes.fontSmall} width={"20%"}>
                    <ul style={{padding: 0}}>{divReqd.map((div, i) => {
                        if (div && div.name) {
                            return <li key={div.name + "" + i}>{div.name}</li>
                        } else {
                            return "-"
                        }
                    })}</ul>
                </TableCell>
                <TableCell className={classes.fontSmall} width={"20%"}>{visit.site}</TableCell>
                <TableCell className={classes.fontSmall} width={"15%"}>{visit.remarks}</TableCell>
                {/*<TableCell width={"15%"}>{SAMPLE_STATUS_KEY_VALUE[visit.status] || "-"}</TableCell>*/}
            </TableRow>
                <TableRow>
                    <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={12}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box margin={1}>
                                <h4>Submission Reports</h4>
                                <Table size="small" aria-label="submission report">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Report Type</TableCell>
                                            <TableCell>Report Status</TableCell>
                                            <TableCell>Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            visit && visit.testSubmissionReports && visit.testSubmissionReports.length ? visit.testSubmissionReports.map((report, index) => {
                                                return (
                                                    <TableRow key={report.id + "_compliance"}>
                                                        <TableCell
                                                            style={{borderBottom: "none"}}
                                                        >
                                                            {REPORT_TYPE_KEY_VALUE[report.type]}
                                                        </TableCell>
                                                        <TableCell
                                                            style={{borderBottom: "none"}}
                                                        >
                                                            {SAMPLE_STATUS_KEY_VALUE[report.status]}
                                                        </TableCell>
                                                        <TableCell
                                                            style={{borderBottom: "none", width: "15%"}}
                                                        >
                                                            <Button
                                                                key={"sub_rep" + index}
                                                                size={"sm"}
                                                                color={"success"}
                                                                href={"/dashboard/testing/submissionReport/" + report.id}
                                                            >
                                                                Show Report
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            }) :
                                                <Box
                                                    display="flex"
                                                    width={"100%"} height={100}
                                                    alignItems="center"
                                                    justifyContent="center"
                                                >
                                                    No Reports
                                                </Box>
                                        }
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>

        </React.Fragment>
    )
}
