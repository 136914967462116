import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import './customstyle.css';
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
// Sections for this page
import { Link,useParams } from "react-router-dom";
import LeftLinks from "../../components/Header/LeftLinks";
import NavPills from "../../components/NavPills/NavPills";
import ImageList from '@mui/material/ImageList';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ImageModal from "models/imageModal";
import Modal from 'react-modal'; // Import Modal from react-modal

Modal.setAppElement('#root'); // Set the app element

const dashboardRoutes = [];
const useStyles = makeStyles(styles);

export default function GalleryPage(props) {
    let { id } = useParams();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const [showModal, setShowModal] = React.useState(false);
    const [selectedImageUrl, setSelectedImageUrl] = React.useState("");

    const classes = useStyles();
    const { ...rest } = props;
const showImageModel = (imageurl) => {
    setSelectedImageUrl(imageurl?.img)
    setShowModal(true)
}
const closeImageModal = () => {
    setSelectedImageUrl("");
    setShowModal(false);
  };
    

const itemData =
    id == 2018 ?  
    [
        {
/*             img: 'https://res.cloudinary.com/dw4utpjlk/image/upload/v1666002553/imagerwsrpd/b22018_iiymvy.jpg',
 */            img: 'https://res.cloudinary.com/dz0axplub/image/upload/v1694428177/imagesrwsrpd/PPT_-_CS_meeting_16.06.2023_1__page-0011_uwzqid.jpg',

            year: '2018',
        },
        {
            img: ' https://res.cloudinary.com/dz0axplub/image/upload/v1694428179/imagesrwsrpd/PPT_-_CS_meeting_16.06.2023_1__page-0013_dxrhkz.jpg',
           
            year: '2018',
        },
        { 
            img: 'https://res.cloudinary.com/dz0axplub/image/upload/v1694428178/imagesrwsrpd/PPT_-_CS_meeting_16.06.2023_1__page-0012_ryqbc6.jpg',
           
            year: '2018',
        },   
    ]
     : id == 2019 ? 
     [
        {
            img: 'https://res.cloudinary.com/dw4utpjlk/image/upload/v1665846601/imagerwsrpd/2019_nmmgb2.jpg',
           
            year: '2019',
        },
        
    ]
    : id == 2020 ? 
    [
       {
           img: 'https://res.cloudinary.com/dw4utpjlk/image/upload/v1665846580/imagerwsrpd/study2020_hvi2sy.jpg',
          
           year: '2020',
       },
      
        
   ]: id == 2021 ? 
   [
      {
          img: 'https://res.cloudinary.com/dw4utpjlk/image/upload/v1665833371/imagerwsrpd/emc2021_itb16r.jpg',
         
          year: '2021',
      },
      {
          img: 'https://res.cloudinary.com/dw4utpjlk/image/upload/v1665833365/imagerwsrpd/erm_wrok_2021_kvqnjm.jpg',
         
          year: '2021',
      },
      {
        img: 'https://res.cloudinary.com/dw4utpjlk/image/upload/v1665833355/imagerwsrpd/tpqc2021_btbclv.jpg',
       
        year: '2021',
    },
   
     
  ] : id == 2022 ? 
  [
     {
         img: 'https://res.cloudinary.com/dz0axplub/image/upload/v1689590101/imagesrwsrpd/store20222_sv54oj.jpg',
        
         year: '2022',
     },
     {
         img: 'https://res.cloudinary.com/dz0axplub/image/upload/v1689590101/imagesrwsrpd/cu20226_xe4ogf.jpg',
        
         year: '2022',
     },
     {
       img: 'https://res.cloudinary.com/dz0axplub/image/upload/v1689590101/imagesrwsrpd/bed20225_evpl9i.jpg',
      
       year: '2022',
   }, {
    img: 'https://res.cloudinary.com/dz0axplub/image/upload/v1689590101/imagesrwsrpd/des20224_xwvfki.jpg',
   
    year: '2022',
}, {
    img: 'https://res.cloudinary.com/dz0axplub/image/upload/v1689590101/imagesrwsrpd/dew20223_inlrn5.jpg',
   
    year: '2022',
},{
    img: 'https://res.cloudinary.com/dz0axplub/image/upload/v1689590103/imagesrwsrpd/test2022_zb98lu.jpg',
   
    year: '2022',
},
    
 ]: id == 2023 ? 
 [
    {
        img: 'https://res.cloudinary.com/dz0axplub/image/upload/v1694422760/imagesrwsrpd/age-0001_z3nwvb.jpg',
       
        year: '2023',
    },
    {
        img: 'https://res.cloudinary.com/dz0axplub/image/upload/v1694422782/imagesrwsrpd/page-0002_yuo72g.jpg',
       
        year: '2023',
    }, {
        img: 'https://res.cloudinary.com/dz0axplub/image/upload/v1694422780/imagesrwsrpd/_page-0003_ear5bh.jpg',
       
        year: '2023',
    },
    {
        img: 'https://res.cloudinary.com/dz0axplub/image/upload/v1694422782/imagesrwsrpd/p-0004_uewjwf.jpg',     
        year: '2023',
    },
    {
        img: 'https://res.cloudinary.com/dz0axplub/image/upload/v1694422786/imagesrwsrpd/page-0005_zqcwoq.jpg',      
        year: '2023',
    },
    {
        img: 'https://res.cloudinary.com/dz0axplub/image/upload/v1694422783/imagesrwsrpd/page-0006_wskluq.jpg',       
        year: '2023',
    },
    {
        img: 'https://res.cloudinary.com/dz0axplub/image/upload/v1694422785/imagesrwsrpd/page-0007_wjfaa5.jpg',
       
        year: '2023',
    },
    {
        img: 'https://res.cloudinary.com/dz0axplub/image/upload/v1694422786/imagesrwsrpd/page-0008_gwaxan.jpg',      
        year: '2023',
    },
    {
        img: 'https://res.cloudinary.com/dz0axplub/image/upload/v1694428170/imagesrwsrpd/PPT_-_CS_meeting_16.06.2023_1__page-0002_alcbs6.jpg',     
        year: '2023',
    },
    {
        img: 'https://res.cloudinary.com/dz0axplub/image/upload/v1694428168/imagesrwsrpd/PPT_-_CS_meeting_16.06.2023_1__page-0003_ndd1s5.jpg',
        year: '2023',
    },
    {
        img: 'https://res.cloudinary.com/dz0axplub/image/upload/v1694428168/imagesrwsrpd/PPT_-_CS_meeting_16.06.2023_1__page-0001_akww3s.jpg',
        year: '2023',
    },
    
] :  id == 2024 ? [
    {
        img: 'https://res.cloudinary.com/dz0axplub/image/upload/v1719832196/imagesrwsrpd/Slide2_na1pas.jpg',
       
        year: '2024',
    },
    {
        img: 'https://res.cloudinary.com/dz0axplub/image/upload/v1719832197/imagesrwsrpd/Slide3_jph1v8.jpg',
       
        year: '2024',
    },
    {
        img: 'https://res.cloudinary.com/dz0axplub/image/upload/v1719832198/imagesrwsrpd/Slide4_su5d4y.jpg',
       
        year: '2024',
    },
    {
        img: 'https://res.cloudinary.com/dz0axplub/image/upload/v1719832198/imagesrwsrpd/Slide5_jny1fv.jpg',
       
        year: '2024',
    },
    {
        img: 'https://res.cloudinary.com/dz0axplub/image/upload/v1719832199/imagesrwsrpd/Slide6_kzbsh7.jpg',
       
        year: '2024',

    },
    {
        img: 'https://res.cloudinary.com/dz0axplub/image/upload/v1719832197/imagesrwsrpd/Slide7_ouajir.jpg',
       
        year: '2024',

    },
    {
        img: 'https://res.cloudinary.com/dz0axplub/image/upload/v1719832198/imagesrwsrpd/Slide8_ume9f2.jpg',
       
        year: '2024',
        
    },
]:[]
    return (
        <>
          {showModal && (
            <ImageModal imageUrl={selectedImageUrl} onClose={closeImageModal} />
          )}
        <div>
            <Header
                color="dark"
                brand={"Water Resource Department - Govt of Rajasthan"}
                rightLinks={<HeaderLinks />}
                sticky
            />
            <Header
                color="dark"
                routes={dashboardRoutes}
                sticky
                top={"70px"}
                leftLinks={<LeftLinks />}
                mobileOpen={mobileOpen}
                onDrawerToggle={handleDrawerToggle}
                {...rest}
            /> 
            {/* <Parallax filter image={require("assets/img/RWSRPD/Landing_Page.png")}> */}
                {/* <div className={classes.container}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <h1 className={classes.title}>
                                Rajasthan Water Sector Restructuring Project for Desert Area (RWSRPD)
                            </h1>
                            <h2 style={{ textAlign: "center" }}>
                                Funded by New Development Bank
                            </h2>
                        </GridItem>

                    </GridContainer>
                </div> */}
            {/* </Parallax> */}
         
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container}>
                    <h2 className={classes.title} style={{ color: "black" }}>News Heighlights</h2>
                    {/* <GridContainer justify="center"> */}
                        
                        <Carousel
    style={{
        width: '100%',
        margin: '0',
        padding: '0',
    }}
    showThumbs={true}
    autoPlay={false} 
    onClickItem={(event)=>showImageModel(itemData[event]) }
    className="custom-carousel"
  
   
    showArrows= {true}
   
>
    {itemData.map((item, index) => (
        <div key={index} style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <img
                src={`${item.img}?w=248&fit=crop&auto=format`}
                alt={item.title}
                loading="lazy"
                style={{
                    width: '60%',
                    height: '60%',
                    maxWidth: '60%',
                }}
            />
           
        </div>
    ))}
</Carousel>

                </div>
            </div>
            <Footer />
        </div>
        </>
    );
}
