import WorkController from '../controllers/work-controller';

class WorkRepository {
    getWork(workId, data) {
        let worksApi = new WorkController();
        return worksApi.getWork(workId, {
            data: data,
        });
    }

    getWorks(data) {
        let worksApi = new WorkController();
        return worksApi.getWorks({
            data: data,
        });
    }
}

export default WorkRepository;
