import React, { useState } from "react";
import Modal from "react-modal";

const customStyles = {
    overlay: {
      zIndex: 9999, // Set a high z-index to ensure it's displayed over other elements
      background: "rgba(0, 0, 0, 0.5)", // Add a semi-transparent background overlay
    },
    content: {
      position: "absolute", // Change position to absolute
      top: "50%", // Center vertically
      left: "50%", // Center horizontally
      transform: "translate(-50%, -50%)", // Center the modal
      border: "none",
      padding: "0",
      background: "transparent",
      width: "90%",
      height : "90%"
    },
  };
  

const ImageModal = ({ imageUrl, onClose }) => {
  const [modalIsOpen, setModalIsOpen] = useState(true);

  const closeModal = () => {
    setModalIsOpen(false);
    onClose();
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      contentLabel="Image Modal"
      style={customStyles} // Apply custom styles
    >
      <div>
        <button className="close-button" onClick={closeModal}>
          Close
        </button>
        <img
          src={imageUrl}
          alt="Image"
          style={{
            width: "100%", // Set image width to 100%
            height: "100%", // Set image height to 100%
            objectFit: "contain", // Maintain aspect ratio (optional)
          }}
        />
      </div>
    </Modal>
  );
};

export default ImageModal;
