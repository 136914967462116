import CustomAPI from "../lib/custom-api";

class ComplianceHistoryController {
    constructor(){
        this.customApi = new CustomAPI();
    }
    getHistories(parameters) {
        parameters.endPoint = 'api/complianceHistory';
        return this.customApi.get(parameters);
    }
}

export default ComplianceHistoryController;
