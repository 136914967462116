import CustomAPI from "../lib/custom-api";

class PackController {
    constructor(){
        this.customApi = new CustomAPI();
    }
    getPack(packId, parameters) {
        parameters.endPoint = 'api/packs/'+packId;
        return this.customApi.get(parameters);
    }
    getPacks(parameters) {
        parameters.endPoint = 'api/packs';
        return this.customApi.get(parameters);
    }
}

export default PackController;
