import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function AutoComplete({name, label, getOptionLabel,options, onChange, ...rest}) {
    return (
            <Autocomplete
                id="free-solo-demo"
                name={name}
                options={options}
                onChange={(e, value) => {
                    onChange({target: {name, value}})
                }}
                getOptionSelected={(option, value) => option.id ? option.id === value.id : null}
                getOptionLabel={getOptionLabel}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={label}
                        variant="outlined"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {/*{loading ? <CircularProgress color="inherit" size={20} /> : null}*/}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                )}
                {...rest}
            />
    );
}