import SubmissionReportHistoryController from "../controllers/submission-report-history-controller";

class SubmissionReportHistoryRepository {
    getHistories(data) {
        const submissionReportHistoryController = new SubmissionReportHistoryController();
        return submissionReportHistoryController.getHistories({
            data: data,
        });
    }

}

export default SubmissionReportHistoryRepository;
