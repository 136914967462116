import React, {useState, useEffect} from 'react'
import {Grid,} from '@material-ui/core';
import Controls from "../../../Components/controls/Controls";
import {useForm, Form} from '../../../Components/useForm';
import {DropzoneDialog} from "material-ui-dropzone";
// import * as employeeService from "../../../services/employeeService";


const genderItems = [
    {id: 'male', title: 'Male'},
    {id: 'female', title: 'Female'},
    {id: 'other', title: 'Other'},
]

const initialFValues = {
    inspectionFile: [],
    inspectionImages: [],
    inspectionOn: new Date(),
}

export default function InspectionForm() {

    const validate = (fieldValues = values) => {
        let temp = {...errors}
        if ('inspectionOn' in fieldValues)
            temp.inspectionOn = fieldValues.inspectionOn ? "" : "This field is required."
        if ('inspectionFile' in fieldValues)
            temp.inspectionFile = fieldValues.inspectionFile ? "" : "This field is required."
        setErrors({
            ...temp
        })

        if (fieldValues == values)
            return Object.values(temp).every(x => x == "")
    }

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        handleMultipleFilesUpload,
        resetForm
    } = useForm(initialFValues, true, validate);

    const handleSubmit = e => {
        e.preventDefault()
        // employeeService.insertEmployee(values)
        if(validate()) {
            const formData = new FormData();
            Object.keys(values).forEach(key => {formData.append(key, values[key])});

            resetForm()
        }
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Grid container>
                <Grid item xs={6}>
                    <Controls.DatePicker
                        name="inspectionOn"
                        label="Inspection Date"
                        value={values.inspectionOn}
                        onChange={handleInputChange}
                        maxDate={new Date()}
                    />
                    <div>
                        <Controls.UploadFile
                            name="inspectionFile"
                            label="Add Inspection File"
                            value={values.inspectionFile}
                            title={"Upload Inspection Report, (1 file, PDF, Max Size: 8mB)"}
                            onChange={handleMultipleFilesUpload}
                            dialogProps={{
                                acceptedFiles: ['.pdf', 'image/*'],
                                showPreviews: true,
                                maxFileSize: 8000000,
                                filesLimit: 1,
                            }}
                        />
                        <Controls.UploadFile
                            name="inspectionImages"
                            label="Add Inspection Image(s)"
                            title={"Upload Inspection Images, (Max 5 files, PDF, Max Size: 5mB)"}
                            value={values.inspectionImages}
                            onChange={handleMultipleFilesUpload}
                            dialogProps={{
                                acceptedFiles: ['image/*'],
                                showPreviews: true,
                                maxFileSize: 5000000,
                                filesLimit: 5,
                            }}
                        />
                    </div>
                    <div>
                        <Controls.Button
                            disabled={!(values.inspectionFile.length && values.inspectionImages.length)}
                            type="submit"
                            text="Submit"/>
                        <Controls.Button
                            text="Reset"
                            color="default"
                            onClick={resetForm}/>
                    </div>
                </Grid>
            </Grid>
        </Form>
    )
}
