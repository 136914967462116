import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Divider from "@material-ui/core/Divider";
import Popper from "@material-ui/core/Popper";

import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/customDropdownStyle.js";

const useStyles = makeStyles(styles);

export default function CustomDropdown(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openSubMenus, setOpenSubMenus] = React.useState([]);

  const handleClick = event => {
    if (anchorEl && anchorEl.contains(event.target)) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = param => {
    setAnchorEl(null);
    if (props && props.onClick) {
      props.onClick(param);
    }
  };

  const handleCloseAway = event => {
    if (anchorEl.contains(event.target)) {
      return;
    }
    setAnchorEl(null);
  };

  const handleSubMenuToggle = index => {
    setOpenSubMenus(prevState => {
      const newOpenSubMenus = [...prevState];
      newOpenSubMenus[index] = !newOpenSubMenus[index];
      return newOpenSubMenus;
    });
  };

  const handleItemClick = item => {
    handleClose(item);
    setAnchorEl(null);
  };

  const classes = useStyles();

  const { buttonText, dropdownList, buttonProps } = props;

  return (
    <div>
      <div>
        <Button
          aria-label="Notifications"
          aria-owns={anchorEl ? "menu-list" : null}
          aria-haspopup="true"
          {...buttonProps}
          onClick={handleClick}
        >
          {buttonText}
          <b
            className={classNames(classes.caret, {
              [classes.caretActive]: Boolean(anchorEl),
            })}
          />
        </Button>
      </div>
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        transition
        disablePortal
        placement="bottom-start"
        className={classes.popperResponsive}
      >
        {() => (
          <Grow
            in={Boolean(anchorEl)}
            id="menu-list"
            style={{ transformOrigin: "0 0 0" }}
          >
            <Paper className={classes.dropdown}>
              <ClickAwayListener onClickAway={handleCloseAway}>
                <MenuList role="menu" className={classes.menuList}>
                  {dropdownList.map((item, index) => {
                    if (Array.isArray(item)) {
                      return (
                        <React.Fragment key={index}>
                          <MenuItem
                            onClick={() => handleSubMenuToggle(index)}
                            className={classes.dropdownHeader}
                          >
                            {item[0].text}
                            <b
                              className={classNames(classes.caret, {
                                [classes.caretActive]: openSubMenus[index],
                              })}
                            />
                          </MenuItem>
                          {openSubMenus[index] && (
                            <MenuList>
                              {item.slice(1).map((nestedItem, nestedIndex) => (
                                <MenuItem
                                  key={nestedIndex}
                                  onClick={() => handleItemClick(nestedItem)}
                                  className={classes.menuItem} // Add className here
                                >
                                  {nestedItem.text}
                                </MenuItem>
                              ))}
                            </MenuList>
                          )}
                        </React.Fragment>
                      );
                    } else if (item.divider) {
                      return (
                        <Divider
                          key={index}
                          onClick={() => handleClose("divider")}
                          className={classes.dropdownDividerItem}
                        />
                      );
                    } else {
                      return (
                        <MenuItem
                          key={index}
                          onClick={() => handleItemClick(item)}
                          className={classes.menuItem} // Add className here
                        >
                          {item}
                        </MenuItem>
                      );
                    }
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}

CustomDropdown.propTypes = {
  buttonText: PropTypes.node,
  dropdownList: PropTypes.array,
  buttonProps: PropTypes.object,
  onClick: PropTypes.func,
};
