import CustomAPI from "../lib/custom-api";

class CollectionVisitController {
    constructor(){
        this.customApi = new CustomAPI();
    }
    getCollectionVisit(id, parameters) {
        parameters.endPoint = 'api/collectionVisit/'+id;
        return this.customApi.get(parameters);
    }
    getCollectionVisits(parameters) {
        parameters.endPoint = 'api/collectionVisit';
        return this.customApi.get(parameters);
    }
    postCollectionVisit(parameters) {
        parameters.endPoint = 'api/collectionVisit';
        parameters.headers = {'content-type': 'multipart/form-data'}
        return this.customApi.post(parameters);
    }
    updateCollectionVisit(id, parameters) {
        parameters.endPoint = 'api/collectionVisit/' + id;
        return this.customApi.put(parameters);
    }
}

export default CollectionVisitController;
