export const SAMPLE_STATUS_KEY_VALUE = {
    "0": "Collected",
    "1": "Submitted",
    "2": "Accepted",
    "3": "Rejected",
}

export const REPORT_TYPE_KEY_VALUE = {
    "1": "Takes 2-3 days",
    "2": "Takes More than 25 days",
    "3": "Takes Around 7 days",
}

export const SAMPLE_STATUS =
    [
        {value: "0,1,2,3,4", description: "Shows All Visits", label: "All", id: 0},
        {
            value: "0",
            description: "Shows Inspection Reports whose compliances are Pending by the concerned division",
            label: "Samples Collected",
            id: 1
        },
        {
            value: "1",
            description: "Shows Inspection Reports whose compliances are Partially Rejected by EMC/TPQC",
            label: "Samples Submitted",
            id: 5
        },
        {
            value: "2",
            description: "Shows Inspection Reports whose compliances are Rejected by EMC/TPQC",
            label: "Samples Accepted",
            id: 4
        },
        {
            value: "3",
            description: "Shows Inspection Reports whose compliances are Submitted by the concerned division",
            label: "Samples Rejected",
            id: 2
        },
    ];
