import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import {withStyles} from '@material-ui/core/styles';
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import GridContainer from "../../../../components/Grid/GridContainer";
import GridItem from "../../../../components/Grid/GridItem";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import {hasRule, isTypeCompatible, RULES_CONSTANTS} from "../../../../models/user";
import Button from "../../../../components/CustomButtons/Button";
import Grid from "@material-ui/core/Grid";
import Modal from "@material-ui/core/Modal";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CollectionVisitRepository from "../../../../repository/collection-visit-repository";
import DivisionRepository from "../../../../repository/division-repository";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import {KeyboardDatePicker} from "@material-ui/pickers";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import RefreshIcon from "@material-ui/icons/Refresh";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {SAMPLE_STATUS} from "../../../../models/sample";
import debounce from "@material-ui/core/utils/debounce";
import MinorRepository from "../../../../repository/minor-repository";
import Minor from "./Minor";
import Controls from "../../../Components/controls/Controls";
import Package from "./Package";

const STATUSES = {
    0: {"value": "0", "id": "0", "name": "Not Tendered"},
    1: {"value": "1", "id": "1", "name": "Awarded not started"},
    2: {"value": "2", "id": "2", "name": "In Progress"},
    3: {"value": "3", "id": "3", "name": "Completed"},
}

const BRANCH_SYSTEMS = [
    {
        "value": "Indira Gandhi Feeder (Haryana Portion)",
        "id": "Indira Gandhi Feeder (Haryana Portion)",
        "name": "Indira Gandhi Feeder (Haryana Portion)",
        "type": "IGF & IGMN",
    },
    {
        "value": "Anoopgarh Branch System",
        "id": "Anoopgarh Branch System",
        "name": "Anoopgarh Branch System",
        "type": "ERM Works (Distribution System & Minors)",
    },
    {
        "value": "Suratgarh Branch System",
        "id": "Suratgarh Branch System",
        "name": "Suratgarh Branch System",
        "type": "ERM Works (Distribution System & Minors)",
    },
    {
        "value": "Pugal Branch System",
        "id": "Pugal Branch System",
        "name": "Pugal Branch System",
        "type": "ERM Works (Distribution System & Minors)",
    },
    {
        "value": "Direct Distributories of IGMN",
        "id": "Direct Distributories of IGMN",
        "name": "Direct Distributories of IGMN",
        "type": "ERM Works (Distribution System & Minors)",
    },
    {
        "value": "Rawatsar Branch System",
        "id": "Rawatsar Branch System",
        "name": "Rawatsar Branch System",
        "type": "ERM Works (Distribution System & Minors)",
    },
    {
        "value": "Naurangdesar System",
        "id": "Naurangdesar System",
        "name": "Naurangdesar System",
        "type": "ERM Works (Distribution System & Minors)",
    },
    {
        "value": "Indira Gandhi Feeder",
        "id": "Indira Gandhi Feeder",
        "name": "Indira Gandhi Feeder",
        "type": "IGF & IGMN",
    },
    {
        "value": "Indira Gandhi Main Canal",
        "id": "Indira Gandhi Main Canal",
        "name": "Indira Gandhi Main Canal",
        "type": "IGF & IGMN",
    },
    {
        "value": "North Ghaggar Canal System",
        "id": "North Ghaggar Canal System",
        "name": "North Ghaggar Canal System",
        "type": "ERM Works (Distribution System & Minors)",
    },
]

const styles = (theme) => ({
    paper: {
        // maxWidth: 1200,
        margin: 'auto',
        overflow: 'hidden',
    },
    searchBar: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    searchInput: {
        fontSize: theme.typography.fontSize,
    },
    block: {
        display: 'block',
    },
    addUser: {
        marginRight: theme.spacing(1),
    },
    contentWrapper: {
        margin: '20px 16px',
    },
    title: {
        padding: '20px',
    },
    search: {
        borderRight: "1px solid lightgray",
    }
});

function a11yProps(index) {
    return {
        id: `wrapped-tab-${index}`,
        'aria-controls': `wrapped-tabpanel-${index}`,
    };
}

const fetchMinors = (params, callback) => {
    let minorRepository = new MinorRepository();
    minorRepository.getMinors(params)
        .then(response => response.data)
        .then(data => {
            callback(data)
        })
        .catch(err => {
                console.log(err);
                callback([]);
            }
        )
}

const fetchDivisions = (callback) => {
    let divisionRepository = new DivisionRepository();
    divisionRepository.getDivisions()
        .then(response => response.data)
        .then(data => {
            callback(data)
        })
        .catch(err => console.log(err));
}


const useStyles = makeStyles((theme) => ({
    paper: {
        [theme.breakpoints.between("sm", "md")]: {
            marginTop: theme.spacing(3),
        },
        "@media (min-width: 1280px)": {
            width: 1000,
            top: `50%`,
            left: `50%`,
            transform: `translate(-50%, -50%)`,
            position: 'absolute',
        },

        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        maxHeight: "90vh",
        overflow: "scroll",
    },
}));


function MinorProgress(props) {

    const {classes, userRules, heading, redirectTo} = props;
    const [openAlert, setOpenAlert] = useState(false);
    const [toastMessage, setToast] = useState("");
    const [canals, setCanals] = useState([]);
    const [division, setDivision] = useState(null);
    const [branchSystem, setBranchSystem] = useState("");
    const [selectedVisit, setSelectedVisit] = useState(null);
    const [status, setStatus] = useState(SAMPLE_STATUS[0].value);
    const [divisions, setDivisions] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(3);
    const [loading, setLoading] = useState(false);
    const [addReportModalOpen, setAddReportModalOpen] = React.useState(false);
    const [packageNumber, setPackageNumber] = useState(null);
    const [contractorName, setContractorName] = useState("");
    const [inspectionDate, setInspectionDate] = useState(null);


    const handleAlertClose = () => {
        setOpenAlert(false);
    }

    const handleModalClose = () => {
        setSelectedVisit(null);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleBranchSystem = function (value) {
        setBranchSystem(value);
        setPage(0);
    }

    const handleSearchDivision = function (value) {
        setDivision(value);
        setPage(0);
    }

    const handleStatus = function (value) {
        setStatus(value);
        setPage(0);
    }

    const handleSearchInputPackageNumber = debounce(function (value) {
        setPackageNumber(value);
        setPage(0);
    }, 500)

    const handleSearchInputContractor = debounce(function (value) {
        setContractorName(value);
        setPage(0);
    }, 500)


    const handleSearchInspectionDate = function (value) {
        setInspectionDate(value);
        setPage(0);
    }

    const handleTabChange = (event, newValue) => {
        setStatus(newValue);
        setPage(0);
    };

    const getCollectionVisitParams = () => {
        let params = {
            // status: 2,
            limit: rowsPerPage,
            offset: (page * rowsPerPage),
            // includeSubmissionReports: true,
        }
        if (packageNumber) {
            params['packageNumber'] = packageNumber
        }

        if (contractorName) {
            params['contractorName'] = contractorName
        }
        //
        // if (branchSystem) {
        //     params['branchSystem'] = branchSystem
        // }
        //
        // if (division) {
        //     params['division'] = division
        // }
        //
        // if (status) {
        //     params['status'] = status
        // }
        //
        //
        // if (priority) {
        //     params['color'] = priority
        // }
        //
        // if (reportId) {
        //     params['reportId'] = reportId
        // }
        // if (division) {
        //     params['division'] = division
        // }
        if (inspectionDate && inspectionDate.isValid()) {
            params['inspectionDate'] = inspectionDate.format("DD/MM/YYYY")
        }
        return params;
    }

    const fetchVisits = () => {
        function handleInspectionReports(data) {
            setCanals(data);
            setLoading(false);
        }

        setLoading(true);
        fetchMinors(getCollectionVisitParams(), handleInspectionReports)
    }

    const updateStatus = ({collectionVisit, reqData}) => {
        let collectionVisitRepository = new CollectionVisitRepository();

        let data = {id: collectionVisit.id, ...reqData};

        collectionVisitRepository.updateCollectionVisit(collectionVisit.id, data).then(data => {
            setSelectedVisit(null)
            fetchVisits();
            setOpenAlert(true)
            setToast({type: "success", msg: "Collection Visit Updated!"});
        }).catch(e => {
            setOpenAlert(true)
            setToast({type: "error", msg: e.message});
        })
    }

    useEffect(() => {
        function handleDivisions(data) {
            setDivisions(data);
        }

        fetchDivisions(handleDivisions)
    }, []);

    useEffect(() => {
        fetchVisits()
    }, [status, packageNumber,contractorName,  division, branchSystem, status, page, rowsPerPage]);


    return (
        <Paper className={classes.paper}>
            <div className={classes.contentWrapper}>
                <Typography variant={"h5"} color="textPrimary" align="center" className={classes.title}>
                    {heading}
                </Typography>
            </div>
            <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
                <Toolbar>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            <SearchIcon className={classes.block} color="inherit"/>
                        </Grid>
                        <Grid item xs className={classes.search}>
                            <TextField
                                fullWidth
                                placeholder="Package Number"
                                onChange={(e) => {
                                    const {value} = e.target;
                                    handleSearchInputPackageNumber(value);
                                }}
                                InputProps={{
                                    disableUnderline: true,
                                    className: classes.searchInput,
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <SearchIcon className={classes.block} color="inherit"/>
                        </Grid>
                        <Grid item xs className={classes.search}>
                            <TextField
                                fullWidth
                                placeholder="Contractor Name"
                                onChange={(e) => {
                                    const {value} = e.target;
                                    handleSearchInputContractor(value);
                                }}
                                InputProps={{
                                    disableUnderline: true,
                                    className: classes.searchInput,
                                }}
                            />
                        </Grid>
                        {/*<Grid item xs>*/}
                        {/*    <Controls.Select*/}
                        {/*        name="divisionIds"*/}
                        {/*        label="Division"*/}
                        {/*        value={division}*/}
                        {/*        style={{width: "100%"}}*/}
                        {/*        onChange={(e) => {*/}
                        {/*            handleSearchDivision(e.target.value)*/}
                        {/*        }}*/}
                        {/*        options={divisions}*/}
                        {/*    />*/}
                        {/*</Grid>*/}
                        {/*<Grid item xs>*/}
                        {/*    <Controls.Select*/}
                        {/*        name="branch_system"*/}
                        {/*        label="Branch System"*/}
                        {/*        value={branchSystem}*/}
                        {/*        style={{width: "100%"}}*/}
                        {/*        onChange={(e) => {*/}
                        {/*            handleBranchSystem(e.target.value)*/}
                        {/*        }}*/}
                        {/*        options={BRANCH_SYSTEMS}*/}
                        {/*    />*/}
                        {/*</Grid>*/}
                        {/*<Grid item xs>*/}
                        {/*    <Controls.Select*/}
                        {/*        name="status"*/}
                        {/*        label="Status"*/}
                        {/*        value={status}*/}
                        {/*        style={{width: "100%"}}*/}
                        {/*        onChange={(e) => {*/}
                        {/*            handleStatus(e.target.value)*/}
                        {/*        }}*/}
                        {/*        options={Object.values(STATUSES)}*/}
                        {/*    />*/}
                        {/*</Grid>*/}
                        <Grid item>
                            <Tooltip title="Reload">
                                <IconButton>
                                    <RefreshIcon className={classes.block} color="inherit" onClick={fetchVisits}/>
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <GridContainer>
                <GridItem>
                    {
                        loading ?
                            <Box
                                display="flex"
                                width={"100%"} height={100}
                                alignItems="center"
                                justifyContent="center"
                            >
                                <CircularProgress/>
                            </Box> :
                            canals && canals.rows && canals.rows.length ?
                                <>
                                    <TableContainer component={Paper}>
                                        <Table style={{fontSize: "18px"}} className={classes.table}
                                               aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    {/*<TableCell width={"5%"}>Minor Id</TableCell>*/}
                                                    <TableCell width={"30%"}>Package Name</TableCell>
                                                    <TableCell width={"20%"}>Contractor's Name</TableCell>
                                                    <TableCell width={"15%"}>Package No.</TableCell>
                                                    <TableCell width={"25%"}>Contract No.</TableCell>
                                                    {/*<TableCell width={"15%"}>Dates</TableCell>*/}
                                                    {/*<TableCell width={"10%"}>Status</TableCell>*/}
                                                    <TableCell width={"10%"}></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    canals.rows.map((pack, index) =>
                                                        <Package
                                                            STATUSES={STATUSES}
                                                            pack={pack}
                                                            divisions={divisions}
                                                            userRules={userRules}
                                                            redirectTo={redirectTo}
                                                            setSelectedVisit={setSelectedVisit}
                                                            key={"visit" + index}
                                                        />)
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        rowsPerPageOptions={[3, 5, 10, 25]}
                                        component="div"
                                        count={canals.count || 0}
                                        rowsPerPage={rowsPerPage}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                        page={page}
                                    />
                                </> :
                                <Box
                                    display="flex"
                                    width={"100%"} height={100}
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    No Canals
                                </Box>
                    }
                </GridItem>
            </GridContainer>
            <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleAlertClose}>
                <Alert onClose={handleAlertClose} severity={toastMessage.type}>
                    {toastMessage.msg}
                </Alert>
            </Snackbar>
        </Paper>
    );
}

MinorProgress.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MinorProgress);
