import Input from "./Input";
import RadioGroup from "./RadioGroup";
import Select from "./Select";
import Checkbox from "./Checkbox";
import DatePicker from "./DatePicker";
import Button from "./Button";
import UploadFile from "./UploadFile";
import MultipleSelect from "./MultipleSelect";
import AutoComplete from "./AutoComplete";

const Controls = {
    Input,
    RadioGroup,
    Select,
    Checkbox,
    DatePicker,
    Button,
    UploadFile,
    MultipleSelect,
    AutoComplete,
}

export default Controls;