export const COMPLIANCE_STATUS =
    [
        {value: "0,1,2,3,4", description: "Shows All Inspection Reports", label: "All", id: 0},
        {
            value: "0",
            description: "Shows Inspection Reports whose compliances are Pending by the concerned division",
            label: "Compliance Pending",
            id: 1
        },
        {
            value: "3",
            description: "Shows Inspection Reports whose compliances are Partially Rejected by EMC/TPQC",
            label: "Compliance Partially Rejected",
            id: 5
        },
        {
            value: "4",
            description: "Shows Inspection Reports whose compliances are Rejected by EMC/TPQC",
            label: "Compliance Rejected",
            id: 4
        },
        {
            value: "1",
            description: "Shows Inspection Reports whose compliances are Submitted by the concerned division",
            label: "Compliance Submitted",
            id: 2
        },
        {
            value: "2",
            description: "Shows Inspection Reports whose compliances are Accepted by EMC/TPQC",
            label: "Compliance Accepted",
            id: 3
        },
    ];

export const COMPLIANCE_STATUS_KEY_VALUE = {
    "0": "Pending",
    "1": "Submitted",
    "2": "Accepted",
    "3": "Partially Rejected",
    "4": "Rejected",
}
