import React, {useEffect, useState} from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-react/views/profilePage.js";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import {Link} from "react-router-dom";
import WorkRepository from "../../repository/work-repository";

const useStyles = makeStyles(styles);

const fetchProjects = (callback) => {
    let workRepository = new WorkRepository();
    workRepository.getWorks()
        .then(response => response.data)
        .then(data => {
            callback(data)
        })
        .catch(err => console.log(err));
}

export default function ProjectsPage(props) {
    const classes = useStyles();
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        function handleData(data) {
            setProjects(data);
        }

        fetchProjects(handleData)
    }, []);


    const {...rest} = props;
    return (
        <div>
            <Header
                color="transparent"
                brand="RWSRPD"
                rightLinks={<HeaderLinks/>}
                fixed
                changeColorOnScroll={{
                    height: 200,
                    color: "white"
                }}
                {...rest}
            />
            <Parallax small filter image={require("assets/img/RWSRPD/Landing_Page.png")}/>
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div>
                    <div className={classes.container}>
                        <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={6}>
                                <div className={classes.profile}>
                                    <div className={classes.name}>
                                        <h1 className={classes.title}>Projects</h1>
                                    </div>
                                </div>
                            </GridItem>
                        </GridContainer>
                        {
                            projects.length ?
                                <GridContainer justify={"center"}>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <TableContainer component={Paper}>
                                            <Table className={classes.table} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>S No.</TableCell>
                                                        <TableCell>Project Name</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {projects.map((row, index) => (
                                                        <TableRow hover key={row.name}>
                                                            <TableCell>{(index + 1) + "."}</TableCell>
                                                            <TableCell component="th" scope="row">
                                                                <Link to={"project/" + row.id} className={classes.link}>
                                                                    {row.name}
                                                                </Link>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </GridItem>
                                </GridContainer> : null
                        }
                        <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>

                                {/*<NavPills*/}
                                {/*  alignCenter*/}
                                {/*  color="primary"*/}
                                {/*  tabs={[*/}
                                {/*    {*/}
                                {/*      tabButton: "Studio",*/}
                                {/*      tabIcon: Camera,*/}
                                {/*      tabContent: (*/}
                                {/*        <GridContainer justify="center">*/}
                                {/*          <GridItem xs={12} sm={12} md={4}>*/}
                                {/*            <img*/}
                                {/*              alt="..."*/}
                                {/*              src={studio1}*/}
                                {/*              className={navImageClasses}*/}
                                {/*            />*/}
                                {/*            <img*/}
                                {/*              alt="..."*/}
                                {/*              src={studio2}*/}
                                {/*              className={navImageClasses}*/}
                                {/*            />*/}
                                {/*          </GridItem>*/}
                                {/*          <GridItem xs={12} sm={12} md={4}>*/}
                                {/*            <img*/}
                                {/*              alt="..."*/}
                                {/*              src={studio5}*/}
                                {/*              className={navImageClasses}*/}
                                {/*            />*/}
                                {/*            <img*/}
                                {/*              alt="..."*/}
                                {/*              src={studio4}*/}
                                {/*              className={navImageClasses}*/}
                                {/*            />*/}
                                {/*          </GridItem>*/}
                                {/*        </GridContainer>*/}
                                {/*      )*/}
                                {/*    },*/}
                                {/*    {*/}
                                {/*      tabButton: "Work",*/}
                                {/*      tabIcon: Palette,*/}
                                {/*      tabContent: (*/}
                                {/*        <GridContainer justify="center">*/}
                                {/*          <GridItem xs={12} sm={12} md={4}>*/}
                                {/*            <img*/}
                                {/*              alt="..."*/}
                                {/*              src={work1}*/}
                                {/*              className={navImageClasses}*/}
                                {/*            />*/}
                                {/*            <img*/}
                                {/*              alt="..."*/}
                                {/*              src={work2}*/}
                                {/*              className={navImageClasses}*/}
                                {/*            />*/}
                                {/*            <img*/}
                                {/*              alt="..."*/}
                                {/*              src={work3}*/}
                                {/*              className={navImageClasses}*/}
                                {/*            />*/}
                                {/*          </GridItem>*/}
                                {/*          <GridItem xs={12} sm={12} md={4}>*/}
                                {/*            <img*/}
                                {/*              alt="..."*/}
                                {/*              src={work4}*/}
                                {/*              className={navImageClasses}*/}
                                {/*            />*/}
                                {/*            <img*/}
                                {/*              alt="..."*/}
                                {/*              src={work5}*/}
                                {/*              className={navImageClasses}*/}
                                {/*            />*/}
                                {/*          </GridItem>*/}
                                {/*        </GridContainer>*/}
                                {/*      )*/}
                                {/*    },*/}
                                {/*    {*/}
                                {/*      tabButton: "Favorite",*/}
                                {/*      tabIcon: Favorite,*/}
                                {/*      tabContent: (*/}
                                {/*        <GridContainer justify="center">*/}
                                {/*          <GridItem xs={12} sm={12} md={4}>*/}
                                {/*            <img*/}
                                {/*              alt="..."*/}
                                {/*              src={work4}*/}
                                {/*              className={navImageClasses}*/}
                                {/*            />*/}
                                {/*            <img*/}
                                {/*              alt="..."*/}
                                {/*              src={studio3}*/}
                                {/*              className={navImageClasses}*/}
                                {/*            />*/}
                                {/*          </GridItem>*/}
                                {/*          <GridItem xs={12} sm={12} md={4}>*/}
                                {/*            <img*/}
                                {/*              alt="..."*/}
                                {/*              src={work2}*/}
                                {/*              className={navImageClasses}*/}
                                {/*            />*/}
                                {/*            <img*/}
                                {/*              alt="..."*/}
                                {/*              src={work1}*/}
                                {/*              className={navImageClasses}*/}
                                {/*            />*/}
                                {/*            <img*/}
                                {/*              alt="..."*/}
                                {/*              src={studio1}*/}
                                {/*              className={navImageClasses}*/}
                                {/*            />*/}
                                {/*          </GridItem>*/}
                                {/*        </GridContainer>*/}
                                {/*      )*/}
                                {/*    }*/}
                                {/*  ]}*/}
                                {/*/>*/}
                            </GridItem>
                        </GridContainer>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}
