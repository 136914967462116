import CustomAPI from "../lib/custom-api";

class RuleController {
    constructor(){
        this.customApi = new CustomAPI();
    }
    getRules(parameters) {
        parameters.endPoint = 'api/rules';
        return this.customApi.get(parameters);
    }
}

export default RuleController;
