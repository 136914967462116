import CustomAPI from "../lib/custom-api";

class TrainingController {
    constructor(){
        this.customApi = new CustomAPI();
    }
  
    postTrainingLogin(parameters) {
        parameters.endPoint = 'api/training/loginUser';
        // parameters.headers = {'content-type': 'multipart/form-data'}
        return this.customApi.post(parameters);
    }

    postTrainingFormData(parameters) {
        parameters.endPoint = 'api/training/createTraining';
        parameters.headers = {'content-type': 'multipart/form-data'}
        return this.customApi.post(parameters);
    }
}

export default TrainingController;
