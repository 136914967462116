import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import {withStyles} from '@material-ui/core/styles';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import _ from 'lodash';

import {Link} from "react-router-dom";
import ComplianceReportRepository from "../../../repository/compliance-report-repository";
import {COMPLIANCE_STATUS} from "../../../models/complianceReport";
import Button from "../../../components/CustomButtons/Button";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import {INSPECTION_TYPE} from "../../../models/user";
import {Grid} from "@material-ui/core";
import Paperbase from "../../../components/PaperBase";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";

const styles = (theme) => ({
    paper: {
        // maxWidth: 1200,
        margin: 'auto',
        overflow: 'hidden',
    },
    searchBar: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    searchInput: {
        fontSize: theme.typography.fontSize,
    },
    block: {
        display: 'block',
    },
    addUser: {
        marginRight: theme.spacing(1),
    },
    contentWrapper: {
        margin: '20px 16px',
    },
    title: {
        padding: '20px',
    }
});

const fetchCount = (callback) => {
    let complianceReportRepository = new ComplianceReportRepository();
    complianceReportRepository.getCount()
        .then(response => response.data)
        .then(data => {
            callback(data)
        })
        .catch(err => console.log(err));

}

const cookDataForGraph = (count) => {
    let group = _.groupBy(count, "status");
    let categories = _.uniqBy(count, 'divisionId');
    let divisions = categories.map((count) => count.division);
    let xAxis = divisions.map(division => division.name);
    let yAxis = [];
    COMPLIANCE_STATUS.forEach(status => {
        let counts = group[status.value] || [];
        let EMCReports = counts.filter((ct) => {
            return ct.inspectionReport.type === INSPECTION_TYPE.EMC
        })
        let TPQCReports = counts.filter((ct) => {
            return ct.inspectionReport.type === INSPECTION_TYPE.TPQC
        })
        let TestingReports = counts.filter((ct) => {
            return ct.inspectionReport.type === "3"
        })

        if (EMCReports.length) {
            let EMCData = divisions.map(division => {
                let obj = _.find(EMCReports, {"divisionId": division.id})
                if (obj) {
                    return obj.ComplianceCount;
                }
                return 0;
            });
            yAxis.push({
                name: status.label,
                data: EMCData,
                stack: INSPECTION_TYPE.EMC,
            })
        }
        if (TPQCReports.length) {
            let TPQCData = divisions.map(division => {
                let obj = _.find(TPQCReports, {"divisionId": division.id})
                if (obj) {
                    return obj.ComplianceCount;
                }
                return 0;
            });
            yAxis.push({
                name: status.label,
                data: TPQCData,
                stack: INSPECTION_TYPE.TPQC,
            })
        }

        if (TestingReports.length) {
            let TestingData = divisions.map(division => {
                let obj = _.find(TestingReports, {"divisionId": division.id})
                if (obj) {
                    return obj.ComplianceCount;
                }
                return 0;
            });
            yAxis.push({
                name: status.label,
                data: TestingData,
                stack: "Quality Testing",
            })
        }

        return;
    })

    return {xAxis, yAxis};

}


const getOptions = (xAxis = [], yAxis = []) => {
    return {
        chart: {
            type: 'column'
        },
        title: {
            text: 'EMC & TPQC Inspection Module Statistics'
        },
        xAxis: {
            categories: xAxis,
            title: {
                text: 'Divisions'
            }
        },
        yAxis: {
            min: 0,
            title: {
                text: 'Number of Compliance Reports',
            },
            labels: {
                overflow: 'justify'
            }
        },
        tooltip: {
            valueSuffix: ' ',
            style: {
                fontSize: "18px",
                padding: "5px",
            },
            formatter: function () {
                return 'The number of compliance reports for <b>' + this.x + '</b> is <b>' + this.y + '</b>, with status ' + this.series.name + ' submitted by ' + this.series.userOptions.stack;
            }
        },
        plotOptions: {
            column: {
                stacking: 'normal'
            }
        },
        legend: {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'top',
            x: -10,
            y: -10,
            floating: true,
            borderWidth: 1,
            backgroundColor:
                Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
            shadow: true,
            labelFormatter: function () {
                return this.name + ' ' + this.userOptions.stack;
            }
        },
        credits: {
            enabled: false
        },
        series: yAxis,
    }
}

function Content(props) {
    const {classes, pack} = props;
    const [count, setCount] = useState(null);
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState(null);
    const [axis, setAxis] = useState(null);

    useEffect(() => {

        function handleCount(data) {
            setCount(data);
            let {xAxis, yAxis} = cookDataForGraph(data);
            let options = getOptions(xAxis, yAxis);
            setOptions(options);
            setAxis({xAxis, yAxis})
            setLoading(false);
        }

        setLoading(true);
        fetchCount(handleCount)
    }, []);

    return (
        <Paper className={classes.paper}>
            <div className={classes.contentWrapper}>
                <Typography variant={"h5"} color="textPrimary" align="center">
                    Project Overview for RWSRPD
                </Typography>
                {

                    <Box alignItems={"center"} width={"100%"}>
                        {
                            loading ?
                                <CircularProgress/> :
                                options ?
                                    <>
                                        {
                                            count && count.length ?
                                                <>
                                                <HighchartsReact
                                                    highcharts={Highcharts}
                                                    options={options}
                                                />
                                                    <GridContainer justify={"center"}>
                                                        <h2>Compliance Reports Count / Division</h2>
                                                        <GridItem xs={12} sm={12} md={12}>
                                                            <TableContainer component={Paper}>
                                                                
                                                                <Table stickyHeader style={{fontSize: "18px"}} className={classes.table} aria-label="simple table">
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <TableCell/>
                                                                            {
                                                                                axis.xAxis.map(
                                                                                   
                                                                                    (loc) =>{
                                                                                        return(
                                                                                            <TableCell key={loc}>{loc}</TableCell>
                                                                                        )
                                                                                    }
                                                                                       
                                                                                )
                                                                            }
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {axis.yAxis.map((loc, index) => (
                                                                            <TableRow hover key={loc.name+ loc.stack}>
                                                                              
                                                                                <TableCell variant={"head"} component="th" scope="row">
                                                                                    {loc.name + ` (${loc.stack})`}
                                                                                    </TableCell>
                                                                                {
                                                                                    loc.data.map((data, index) => <TableCell key={loc.name+ loc.stack+index}>{  axis?.xAxis[index] !== "PMU" ? data : 0}</TableCell>)
                                                                                }
                                                                            </TableRow>
                                                                        ))}
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </GridItem>
                                                    </GridContainer>
                                                </>: null
                                        }
                                        <Grid style={{
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "space-around",
                                            marginTop: "20px",
                                        }}>
                                            <Button
                                                color={"info"}
                                                href={"/dashboard/inspection/emc/"}
                                            >
                                                Show EMC Inspection Reports
                                            </Button>
                                            <Button
                                                color={"info"}
                                                href={"/dashboard/inspection/tpqc/"}
                                            >
                                                Show TPQC Inspection Reports
                                            </Button>
                                        </Grid>
                                    </> : null
                        }
                    </Box>
                }
                {/*<GridContainer justify={"center"}>*/}
                {/*    <GridItem xs={12} sm={12} md={12}>*/}
                {/*        <TableContainer component={Paper}>*/}
                {/*            <Table className={classes.table} aria-label="simple table">*/}
                {/*                <TableHead>*/}
                {/*                    <TableRow>*/}
                {/*                        <TableCell>Submitted Reports</TableCell>*/}
                {/*                        <TableCell>Pending Reports</TableCell>*/}
                {/*                        <TableCell>Accepted Reports</TableCell>*/}
                {/*                    </TableRow>*/}
                {/*                </TableHead>*/}
                {/*                <TableBody>*/}

                {/*                    <TableRow hover>*/}
                {/*                        <TableCell>10</TableCell>*/}
                {/*                        <TableCell component="th" scope="row">5</TableCell>*/}
                {/*                        <TableCell component="th" scope="row">5</TableCell>*/}
                {/*                    </TableRow>*/}
                {/*                </TableBody>*/}
                {/*            </Table>*/}
                {/*        </TableContainer>*/}
                {/*    </GridItem>*/}
                {/*</GridContainer>*/}
            </div>
        </Paper>
    );
}

Content.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Content);