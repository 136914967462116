import React, {useState} from "react";
import {Redirect} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";

const Login = () => {

    const {isAuthenticated, getAccessTokenSilently, user} = useAuth0();
    const [loading, setLoading] = useState(true);
    if (isAuthenticated) {
        if(!localStorage.getItem("token")) {
            getAccessTokenSilently().then((token) => {
                localStorage.setItem("token", token);
                setLoading(false);
            });
            if (loading) {
                return "Loading....."
            } else {
                return <Redirect to={"/dashboard"}/>
            }
        } else {
            return <Redirect to={"/dashboard"}/>
        }
    } else {
        return <Redirect to={"/"}/>
    }
}

export default Login;
