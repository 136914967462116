import React from "react";
import {Router, Route, Switch, Redirect} from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";
import LandingPage from "./views/LandingPage/LandingPage";
import ProjectsPage from "./views/ProjectsPage/ProjectsPage";
import PackagesPage from "./views/PackagesPage/PackagesPage";
import PackagePage from "./views/PackagePage/PackagePage";
import LoginPage from "./views/LoginPage/LoginPage";
import {createBrowserHistory} from "history";
import EMCDashboard from "./views/Dashboards/EMCDashboard";
import PrivateRoute from "./views/Components/PrivateRoute";
import ContactUsPage from "./views/ContactUsPage/ContactUs";
import GalleryPage from "./views/LandingPage/GalleryPage";
import NewsHeighlights from "./views/LandingPage/NewsHeighlights";
import ComplaintsPage from "./views/LandingPage/ComplaintsPage";
import TrainingPage from "views/LandingPage/TrainingPage";


const App = () => {
    const { isLoading, error, isAuthenticated, user, getAccessTokenSilently } = useAuth0();
    if (error) {
        return <div>Oops... {error.message}</div>;
    }

    if (isLoading) {
        return <div>Loading...</div>;
    }

    var hist = createBrowserHistory();
    window.staticParams = {};

    return (
        <Router history={hist}>
            <Switch>
                <PrivateRoute path="/projects" component={ProjectsPage}/>
                <PrivateRoute path="/project/:projectId" component={PackagesPage}/>
                <PrivateRoute path="/package/:packageId" component={PackagePage}/>
                <PrivateRoute path="/dashboard" component={EMCDashboard}/>
                <Route path="/login" component={LoginPage}/>
                {/*<Route path="/contact" component={ContactUsPage}/>*/}
                <Route path="/gallery/:id" component={GalleryPage}/>
                <Route path="/news" component={NewsHeighlights}/>

                {/* <Route path="/gallery/:id" component={GalleryPage}/> */}
                <Route path="/complaint" component={ComplaintsPage}/>
                <Route path="/training" component={TrainingPage}/>

                <Route path="/" component={LandingPage}/>
            </Switch>
        </Router>
    );
};

export default App;
