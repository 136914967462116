import React, {Suspense} from "react";
import PropTypes from "prop-types";
import {
    Route,
    Redirect,
} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";

const PrivateRoute = ({component: Component, ...rest}) => {
    const { isAuthenticated } = useAuth0();
    console.log("isAuthenticated",isAuthenticated)
    return (
        <Route
            {...rest}
            render={props => {
                return (isAuthenticated ? (
                    <Suspense fallback={<div>Loading...</div>}>
                        <Component {...props} />
                    </Suspense>
                ) : (
                    <Redirect
                        to={{
                            pathname: "/",
                            state: {from: props.location}
                        }}
                    />
                ));
            }}
        />
    )
};

PrivateRoute.propTypes = {
    location: PropTypes.object,
    component: PropTypes.func,
};

export default PrivateRoute;
