import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import './customstyle.css';
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link,useParams } from "react-router-dom";
import LeftLinks from "../../components/Header/LeftLinks";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import ImageModal from "models/imageModal";
const dashboardRoutes = [];
const useStyles = makeStyles(styles);
export default function GalleryPage(props) {
    let { id } = useParams();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const [showModal, setShowModal] = React.useState(false);
    const [selectedImageUrl, setSelectedImageUrl] = React.useState("");
    const classes = useStyles();
    const { ...rest } = props;
    const showImageModel = (imageurl) => {
        setSelectedImageUrl(imageurl?.img)
        setShowModal(true)
    }
    const closeImageModal = () => {
        setSelectedImageUrl("");
        setShowModal(false);
      };
    const itemData =[
        {
            img: 'https://res.cloudinary.com/dz0axplub/image/upload/v1695034975/imagesrwsrpd/New_Doc_09-18-2023_16.09_page556456465_kkxz3f.jpg',
           
            year: '2023',
        },
        
    {
        img: 'https://res.cloudinary.com/dz0axplub/image/upload/v1695026724/imagesrwsrpd/epapr_page-0001_zu8coz.jpg',
       
        year: '2023',
    },


    {
        img: 'https://res.cloudinary.com/dz0axplub/image/upload/v1694428173/imagesrwsrpd/PPT_-_CS_meeting_16.06.2023_1__page-0006_y8hwmr.jpg',
       
        year: '2023',
    }, {
        img: 'https://res.cloudinary.com/dz0axplub/image/upload/v1694428169/imagesrwsrpd/PPT_-_CS_meeting_16.06.2023_1__page-0010_pmjcu5.jpg',
       
        year: '2023',
    },
    {
        img: 'https://res.cloudinary.com/dz0axplub/image/upload/v1694428174/imagesrwsrpd/PPT_-_CS_meeting_16.06.2023_1__page-0007_ervoaw.jpg',
       
        year: '2023',
    },
    {
        img: 'https://res.cloudinary.com/dz0axplub/image/upload/v1694428171/imagesrwsrpd/PPT_-_CS_meeting_16.06.2023_1__page-0009_uac1so.jpg',
       
        year: '2023',
    },
    {
        img: 'https://res.cloudinary.com/dz0axplub/image/upload/v1694428179/imagesrwsrpd/PPT_-_CS_meeting_16.06.2023_1__page-0005_crn0rp.jpg',
       
        year: '2023',
    },
    {
        img: 'https://res.cloudinary.com/dz0axplub/image/upload/v1694428175/imagesrwsrpd/PPT_-_CS_meeting_16.06.2023_1__page-0008_mynw5m.jpg',
       
        year: '2023',
    },
   
   
];
    return (
        <>
         {showModal && (
            <ImageModal imageUrl={selectedImageUrl} onClose={closeImageModal} />
          )}
      
        <div>
            <Header
                color="dark"
                brand={"Water Resource Department - Govt of Rajasthan"}
                rightLinks={<HeaderLinks />}
                sticky
            />
            <Header
                color="dark"
                routes={dashboardRoutes}
                sticky
                top={"70px"}
                leftLinks={<LeftLinks />}
                mobileOpen={mobileOpen}
                onDrawerToggle={handleDrawerToggle}
                {...rest}
            />
            {/* <Parallax filter image={require("assets/img/RWSRPD/Landing_Page.png")}>
                <div className={classes.container}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <h1 className={classes.title}>
                                Rajasthan Water Sector Restructuring Project for Desert Area (RWSRPD)
                            </h1>
                            <h2 style={{ textAlign: "center" }}>
                                Funded by New Development Bank
                            </h2>
                        </GridItem>

                    </GridContainer>
                </div>
            </Parallax> */}
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container}>
                    <h2 className={classes.title} style={{ color: "black" }}>News Heighlights</h2>
                    {/* <GridContainer justify="center"> */}
                        
                        <Carousel
    style={{
        width: '100%',
        margin: '0',
        padding: '0',
    }}
    showThumbs={true}
    autoPlay={false} 
    onClickItem={(event)=>showImageModel(itemData[event]) }
    className="custom-carousel"
  
   
    showArrows= {true}
   
>
    {itemData.map((item, index) => (
        <div key={index} style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <img
                src={`${item.img}?w=248&fit=crop&auto=format`}
                alt={item.title}
                loading="lazy"
                style={{
                    width: '60%',
                    height: '60%',
                    maxWidth: '60%',
                }}
            />
           
        </div>
    ))}
</Carousel>

                </div>
            </div>
            <Footer />
        </div>
        </>
    );
}
