import SampleController from '../controllers/sample-controller';

class SampleRepository {
    getSamples(data) {
        let packsApi = new SampleController();
        return packsApi.getSamples({
            data: data
        })
    }
}

export default SampleRepository;
