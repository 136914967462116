import React, { useState, useEffect } from 'react'
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Button from "../../../../components/CustomButtons/Button";
import { CloudDownload } from "@material-ui/icons";
import Collapse from "@material-ui/core/Collapse";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Grid } from "@material-ui/core";
import ComplianceReportRepository from "../../../../repository/compliance-report-repository";
import { hasRule, isTypeCompatible, RULES_CONSTANTS } from "../../../../models/user";
import yellow from "@material-ui/core/colors/yellow";
import { COMPLIANCE_STATUS, COMPLIANCE_STATUS_KEY_VALUE } from "../../../../models/complianceReport";

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    color_green: {
        color: "green",
    },
    color_red: {
        color: "red",
    },
    color_yellow: {
        color: yellow["700"],
    }
});

const handleAcceptData = () => {

}

const ActionButtons = (props) => {
    const {
        userRules,
        inspectionType,
        compliance,
        fetchReports,
        handleAddCompReportModalOpen,
        handleRejectReportModalOpen,
        setOpenAlert,
        setToast,
    } = props;
    const status = parseInt(compliance.status);

    const setStatus = (status) => {
        let complianceReportRepository = new ComplianceReportRepository();
        const formData = new FormData();
        formData.append('reqType', status);
        formData.append('complianceId', compliance.id);

        complianceReportRepository.submitComplianceReport(compliance.id, formData).then(data => {
            fetchReports();
            setOpenAlert(true)
            if (status == 1) {
                setToast({ type: "success", msg: "Compliance Report Accepted" })
            }
            if (status == 2) {
                setToast({ type: "error", msg: "Compliance Report Rejected" })
            }

            if (status == 3) {
                setToast({ type: "error", msg: "Compliance Report Partially Rejected" })
            }
        }).catch(e => {
            console.log("Error");
            setOpenAlert(true)
            setToast({ type: "error", msg: e.message });
        })
    }



    if ([0, 3, 4].indexOf(status) !== -1 && hasRule(userRules, RULES_CONSTANTS.CAN_CREATE_COMPLIANCE_REPORTS)) {
        return <Button
            size={"sm"}
            color={"success"}
            fullWidth
            onClick={e => {
                e.stopPropagation();
                handleAddCompReportModalOpen(compliance);
            }}
        >
            Submit Compliance
        </Button>
    } else if (status === 1 
 && isTypeCompatible(inspectionType, userRules)) {
        return <Grid>
            <Button
                size={"sm"}
                color={"success"}
                fullWidth
                onClick={e => {
                    e.stopPropagation();
                    setStatus(1)
                }}
            >
                Accept
            </Button>
            <Button
                size={"sm"}
                color={"danger"}
                fullWidth
                onClick={e => {
                    e.stopPropagation();
                    handleRejectReportModalOpen({ compliance, type: 2 })
                }}
            >
                Reject
            </Button>
            <Button
                size={"sm"}
                color={"rose"}
                fullWidth
                onClick={e => {
                    e.stopPropagation();
                    handleRejectReportModalOpen({ compliance, type: 3 })
                }}
            >
                Partially Reject
            </Button>
        </Grid>
    }
    else if (status === 0 && compliance.divisionId == 15 && hasRule(userRules, RULES_CONSTANTS.CAN_ACTION_MISCELLANEOUS_REPORTS)) {
        return <Grid>
            <Button
                size={"sm"}
                color={"success"}
                fullWidth
                onClick={e => {
                    e.stopPropagation();
                    setStatus(1)
                }}
            >
                Accept
            </Button>
            <Button
                size={"sm"}
                color={"danger"}
                fullWidth
                onClick={e => {
                    e.stopPropagation();
                    setStatus(2)
                }}
            >
                Reject
            </Button>
            <Button
                size={"sm"}
                color={"rose"}
                fullWidth
                onClick={e => {
                    e.stopPropagation();
                    setStatus(3)
                }}
            >
                Partially Reject
            </Button>
        </Grid>
    }
    else if (status === 1 && hasRule(userRules, RULES_CONSTANTS.CAN_ACCEPT_TEST_REPORTS)) {
        return <Grid>
            <Button
                size={"sm"}
                color={"success"}
                fullWidth
                onClick={e => {
                    e.stopPropagation();
                    setStatus(1)
                }}
            >
                Accept
            </Button>
            <Button
                size={"sm"}
                color={"danger"}
                fullWidth
                onClick={e => {
                    e.stopPropagation();
                    setStatus(2)
                }}
            >
                Reject
            </Button>
            <Button
                size={"sm"}
                color={"rose"}
                fullWidth
                onClick={e => {
                    e.stopPropagation();
                    setStatus(3)
                }}
            >
                Partially Reject
            </Button>
        </Grid>
    }
    else {
        return null
    }

}

export default function InspectionReport(props) {
    const {
        userRules,
        inspectionType,
        report,
        divisions,
        fetchReports,
        handleShowImagesModalOpen,
        handleAddCompReportModalOpen,
        handleHistoryModalOpen,
        handleRejectReportModalOpen,
        setOpenAlert,
        setToast,
    } = props;
    const classes = useRowStyles();
    const [open, setOpen] = React.useState(false);
    const pack = report.pack;
    const inspectionDate = (new Date(report.inspected_on)).toDateString();
    const compliance = report.complianceReports;
    const divReqd = compliance.map(comp => {
        return divisions.find((division) => {
            return division.id === comp.divisionId;
        })
    })

    return (
        <React.Fragment key={report.id + "_report"}>
            <TableRow className={classes.root} hover onClick={() => setOpen(!open)}>
                <TableCell className={classes["color-" + (report.color || "green")]} width={"5%"}>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell className={classes["color_" + (report.color || "green")]} align={"right"} width={"5%"}>
                    <strong>{report.id}.</strong>
                </TableCell>
                <TableCell className={classes["color_" + (report.color || "green")]} align={"right"} width={"5%"}>
                    <strong>{pack.packageId}</strong>
                </TableCell>
                <TableCell className={classes["color_" + (report.color || "green")]} width={"25%"}>{report.site}</TableCell>
                <TableCell className={classes["color_" + (report.color || "green")]} width={"10%"}>{inspectionDate}</TableCell>
                <TableCell className={classes["color_" + (report.color || "green")]} width={"10%"}>{report.created_by}</TableCell>
                <TableCell className={classes["color_" + (report.color || "green")]} width={"25%"}>{report.remarks}</TableCell>
                <TableCell width={"15%"}>
                    <Button
                        onClick={(e) => {
                            e.stopPropagation();
                            window.location.href = report.inspection_report;
                        }}
                        size={"sm"}
                        fullWidth
                        color={"info"}
                        download
                    >
                        <CloudDownload /> Download
                    </Button>
                    <Button size={"sm"} fullWidth color={"info"} onClick={(e) => {
                        e.stopPropagation();
                        handleShowImagesModalOpen(report.inspection_photos)
                    }
                    }> Show Photos </Button></TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <h4>Compliances</h4>
                            <Table size="small" aria-label="compliance report">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Division</TableCell>
                                        <TableCell>Compliance Report</TableCell>
                                        <TableCell>Remarks</TableCell>
                                        <TableCell>Created By</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {compliance.map((compRep, index) => {
                                        return (
                                            <TableRow key={compRep.id + "_compliance"}>
                                                <TableCell
                                                    style={{ borderBottom: "none" }}
                                                    component="th"
                                                    scope="row"
                                                >
                                                    {divReqd[index] && divReqd[index].name || "-"}
                                                </TableCell>
                                                <TableCell
                                                    style={{ borderBottom: "none" }}>
                                                    {
                                                        compRep.compliance_report ?
                                                            <Button
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    window.location.href = compRep.compliance_report;
                                                                }}
                                                                size={"sm"}
                                                                color={"info"}
                                                                download
                                                            >
                                                                <CloudDownload /> Download
                                                            </Button> : "-"
                                                    }
                                                </TableCell>
                                                <TableCell
                                                    style={{ borderBottom: "none" }}
                                                >
                                                    {compRep.remarks || "-"}
                                                </TableCell>
                                                <TableCell
                                                    style={{ borderBottom: "none" }}
                                                >
                                                    {compRep.created_by || "-"}
                                                </TableCell>
                                                <TableCell
                                                    style={{ borderBottom: "none" }}
                                                >
                                                    {COMPLIANCE_STATUS_KEY_VALUE[compRep.status]}
                                                </TableCell>
                                                <TableCell
                                                    style={{ borderBottom: "none", width: "15%" }}
                                                >
                                                    <Grid>
                                                        <Button
                                                            size={"sm"}
                                                            color={"warning"}
                                                            fullWidth
                                                            onClick={e => {
                                                                e.stopPropagation();
                                                                handleHistoryModalOpen(compRep)
                                                            }}
                                                        >
                                                            Show History
                                                        </Button>
                                                        <ActionButtons
                                                            compliance={compRep}
                                                            inspectionType={inspectionType}
                                                            userRules={userRules}
                                                            fetchReports={fetchReports}
                                                            handleAddCompReportModalOpen={handleAddCompReportModalOpen}
                                                            handleRejectReportModalOpen={handleRejectReportModalOpen}
                                                            setOpenAlert={setOpenAlert}
                                                            setToast={setToast}
                                                        />
                                                    </Grid>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}
